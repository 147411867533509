import { openDB } from 'idb';
import Utility from './Utility';

let IndexedDb = {
    logMessage: null,
    databaseName: 'counterDb20',
    version: 1,

    setLogRef: (logRef) => {
        IndexedDb.logMessage = logRef
    },

    open: async () => {
        console.log('open db: ', IndexedDb.databaseName, ' version: ', IndexedDb.version)
        IndexedDb.logMessage('opening database: ' + IndexedDb.databaseName + ' version: ' + IndexedDb.version)
        // no solution for Firefox for getting db names.
        // if (Utility.detectBrowser === 'Google Chrome or Chromium') {
        //     const promise = indexedDB.databases()
        //     promise.then(databases => {
        //         databases.forEach(db => {
        //             if (db.name !== IndexedDb.databaseName) {
        //                 indexedDB.deleteDatabase(db.name);
        //             }
        //         })
        //     })
        // }


        if (!('indexedDB' in window)) {
            console.warn('IndexedDB not supported')
            return
        }

        const dbName = IndexedDb.databaseName
        let storeNames = ['counter', 'sheet', 'layer', 'svg', 'misc1', 'misc2', 'misc3']
        const version = IndexedDb.version //versions start at 1

        try {
            await openDB(dbName, version, {
                upgrade(db, oldVersion, newVersion, transaction) {
                    storeNames.forEach(storeName => db.createObjectStore(storeName, { autoIncrement: true }))
                    IndexedDb.version = newVersion
                },
                blocked() {
                    console.log('blocked')
                    IndexedDb.logMessage('database blocked')
                },
                blocking() {
                    console.log('blocking')
                    IndexedDb.logMessage('database blocking')
                }
            })
        } catch (e) {
            console.log('error: ', e)
            console.log(e.message)
            console.log(JSON.stringify(e))
            if (e) {
                try {
                    IndexedDb.logMessage('database error: ' + JSON.stringify(e))
                }
                catch (ee) {
                    IndexedDb.logMessage('database error: ' + ee)
                }
            }
            else {
                IndexedDb.logMessage('database error, no reported reason')
            }
        } finally {
            console.log('database open finished')
        }


    },

    put: async (storeName, val, key = null) => {
        if (!val) {
            return
        }
        if (key === undefined) {
            key = null
        }
        if (key) {
            key = parseInt(key);
        }
        const dbName = IndexedDb.databaseName
        const version = IndexedDb.version //versions start at 1
        const db = await openDB(dbName, version)
        const tx = db.transaction(storeName, 'readwrite')
        const store = await tx.objectStore(storeName)
        let result = key
        if (key) {
            if (Utility.isNumeric(key) && key > 0) {
                await store.put(val, key)
            }
        }
        else {
            result = await store.put(val)
        }
        await tx.done
        return result;
    },

    get: async (storeName, key) => {
        if (key) {
            key = parseInt(key);
        }
        const dbName = IndexedDb.databaseName
        const version = IndexedDb.version //versions start at 1
        const db = await openDB(dbName, version)
        const item = await db.transaction(storeName).objectStore(storeName).get(key)

        return item;
    },


    getAllKeys: async (storeName) => {
        const dbName = IndexedDb.databaseName
        const version = IndexedDb.version //versions start at 1
        const db = await openDB(dbName, version)
        let keys = null



        try {
            keys = await db.transaction(storeName).objectStore(storeName).getAllKeys();
        } catch (e) {
            console.error(e);
            setTimeout(async () => { keys = await db.transaction(storeName).objectStore(storeName).getAllKeys(); }, 2000)
        } finally {
            
        }


        return keys;
    },

    getAllData: async (storeName) => {
        let dataArray = []
        let keys = null

        try {
            keys = await IndexedDb.getAllKeys(storeName)
        } catch (e) {
            console.error(e);
            setTimeout(async () => { keys = await IndexedDb.getAllKeys(storeName); }, 2000)
        } finally {
            
        }

        if (keys) {

            for (var i = 0; i < keys.length; i++) {
                dataArray.push(await IndexedDb.get(storeName, keys[i]))
            }

            return dataArray
        }
        return []
    },

    getKeyForName: async (storeName, name) => {
        const dbName = IndexedDb.databaseName
        const version = IndexedDb.version //versions start at 1
        const db = await openDB(dbName, version)
        const keys = await db.transaction(storeName).objectStore(storeName).getAllKeys()
        for (var i = 0; i < keys.length; i++) {
            let data = await IndexedDb.get(storeName, keys[i]);
            if (data && data.hasOwnProperty('name')) {
                if (data.name === name) {
                    return keys[i];
                }
            }
        }
        return -1;
    },

    getNameForKey: async (storeName, key) => {
        if (key) {
            key = parseInt(key);
        }
        const dbName = IndexedDb.databaseName
        const version = IndexedDb.version //versions start at 1
        const db = await openDB(dbName, version)
        const value = await db.transaction(storeName).objectStore(storeName).get(key)
        if (value && value.hasOwnProperty('name')) {
            return value.name;
        }
        return -1;
    },

    getAllNames: async (storeName) => {
        let allNames = []
        let keys = await IndexedDb.getAllKeys(storeName)
        if( keys ) {
        for (var i = 0; i < keys.length; i++) {
            allNames.push(await IndexedDb.getNameForKey(storeName, keys[i]))
        }
        allNames = allNames.filter(name => typeof name === 'string')
        return allNames
    }
    return []
    },

    getByName: async (storeName, name) => {
        let keys = await IndexedDb.getAllKeys(storeName)
        for (var i = 0; i < keys.length; i++) {
            let obj = await IndexedDb.get(storeName, keys[i])
            if (obj.name === name) {
                return obj.data
            }
        }

        return -1
    },

    deleteKey: async (storeName, key) => {
        if (key) {
            key = parseInt(key);
        }
        const dbName = IndexedDb.databaseName
        const version = IndexedDb.version //versions start at 1
        const db = await openDB(dbName, version)
        const tx = await db.transaction(storeName, 'readwrite')
        const store = await tx.objectStore(storeName)
        await store.delete(key)
        await tx.done
    },

    storageUsed: (callBack) => {

        (function showIndexedDbSize() {

            var db;
            var storesizes = [];

            function openDatabase() {
                return new Promise(function (resolve, reject) {
                    //prompt for DB name
                    var dbname = IndexedDb.databaseName;

                    if (dbname !== null) {
                        var request = window.indexedDB.open(dbname);
                        request.onsuccess = function (event) {
                            db = event.target.result;
                            resolve(db.objectStoreNames);
                        };
                    }

                });
            }

            function getObjectStoreData(storename) {
                return new Promise(function (resolve, reject) {
                    var trans = db.transaction(storename, IDBTransaction.READ_ONLY);
                    var store = trans.objectStore(storename);
                    var items = [];
                    trans.oncomplete = function (evt) {
                        var szBytes = toSize(items);
                        var szMBytes = (szBytes / 1024 / 1024).toFixed(2);
                        storesizes.push({ 'Store Name': storename, 'Items': items.length, 'MSize': szMBytes + 'MB', 'BSize': szBytes + ' bytes' });
                        resolve();
                    };
                    var cursorRequest = store.openCursor();
                    cursorRequest.onerror = function (error) {
                        reject(error);
                    };
                    cursorRequest.onsuccess = function (evt) {
                        var cursor = evt.target.result;
                        if (cursor) {
                            items.push(cursor.value);
                            cursor.continue();
                        }
                    }
                });
            }

            function toSize(items) {
                var size = 0;
                for (var i = 0; i < items.length; i++) {
                    var objectSize = JSON.stringify(items[i]).length;
                    size += objectSize * 2;
                }
                return size;
            }

            openDatabase().then(function (stores) {
                var PromiseArray = [];
                for (var i = 0; i < stores.length; i++) {
                    PromiseArray.push(getObjectStoreData(stores[i]));
                }
                Promise.all(PromiseArray).then(function () {
                    callBack(storesizes);
                });
            });
        }());

    }

}

export default IndexedDb;
