import React, { Component } from 'react';
import { Redirect } from 'react-router-dom'
import { connect } from 'react-redux';
import { store } from "../../Redux/store";
import colorPicker from "../../images/colorpicker.png";
import joggleExample from "../../images/joggle_example.png";
import Utility from '../../Utility';
import { setHexMapSetup } from "../../Redux/actions";
import ExistingSetups from "../ExistingSetups/ExistingSetups";
import './Setup.scss';

class Setup extends Component {

    state = {
        loadIndex: -1,
        mapMargin: 10,
        hexDiameter: 100,
        hexesAcross: 20,
        hexesDown: 12,
        joggle: 0,
        hexNumberGrain: 0,
        hexNumberFontColor: '#000000',
        hexNumberFontSize: 12,
        hexNumberOrientation: 'angle0',
        hexNumberPosition: 10,
        hexCenterDotSize: 1,
        mapMarginInvalid: false,
        hexDiameterInvalid: false,
        hexesAcrossInvalid: false,
        hexesDownInvalid: false,
        mapMarginColor: '#dddddd',
        mapHexColor: '#ffffff',
        hexLineColor: '#000000',
        mapMarginColorInvalid: false,
        mapHexColorInvalid: false,
        hexNumberFontColorInvalid: false,
        hexLineColorInvalid: false,
        hexLineWidth: 1,
        formAccepted: false,
        hexMapId: 0,
        colorPickerContext: null,
        colorHexDisplayContext: null,
        focusOn: '',
        formProcessed: false,
        mapMarginOpacity: 100,
        mapHexOpacity: 100,
        hexNumberFontOpacity: 100,
        hexLineOpacity: 100,
    }

    setupColorPicker = () => {
        var canvas = document.getElementById('colorPickerCanvas');
        const context = canvas.getContext('2d');
        var image = new Image();
        image.src = colorPicker;
        image.onload = function () {
            context.drawImage(image, 0, 0, 244, 200);
            context.strokeRect(0, 0, 244, 200);
        };
        this.setState({ colorPickerContext: context });

        canvas.addEventListener("click", function (event) {
            var eventLocation = Utility.getEventLocation(this, event);
            var context = this.state.colorPickerContext;
            var pixelData = context.getImageData(eventLocation.x, eventLocation.y, 1, 1).data;

            // Convert it to HEX if you want using the rgbToHex method.
            var hex = "#" + ("000000" + Utility.rgbToHex(pixelData[0], pixelData[1], pixelData[2])).slice(-6);

            if (this.state.focusOn === 'mapMarginColor') {
                this.setState({ mapMarginColor: hex }, () => {
                    this.redrawColorHexDisplay();
                });
            }
            if (this.state.focusOn === 'mapHexColor') {
                this.setState({ mapHexColor: hex }, () => {
                    this.redrawColorHexDisplay();
                });
            }
            if (this.state.focusOn === 'hexNumberFontColor') {
                this.setState({ hexNumberFontColor: hex }, () => {
                    this.redrawColorHexDisplay();
                });
            }
            if (this.state.focusOn === 'hexLineColor') {
                this.setState({ hexLineColor: hex }, () => {
                    this.redrawColorHexDisplay();
                });
            }


        }.bind(this), false);
    }



    setupColorHexDisplay = () => {
        var canvas = document.getElementById('colorHexDisplay');
        var context = canvas.getContext('2d');
        this.setState({ colorHexDisplayContext: context }, () => this.redrawColorHexDisplay());
    }

    redrawColorHexDisplay = () => {
        let context = this.state.colorHexDisplayContext;
        context.clearRect(0, 0, 150, 132);
       
        var poly = [42.5, 122.58, 
                    107.5, 122.58, 
                    140, 66.29, 
                    107.5, 10, 
                    42.5, 10, 
                    10, 66.29];
        var centerX = poly[10] + ((poly[4] - poly[10]) / 2);
        var centerY = poly[10] + ((poly[1] - poly[9]) / 2);

        if( this.state.mapMarginOpacity < 100 ) {
            context.save();
            context.globalAlpha = Utility.roundFloat(this.state.mapMarginOpacity / 100, 2);
        }
        context.fillStyle = this.state.mapMarginColor;
        context.strokeStyle = this.state.mapMarginColor;
        context.fillRect(0, 0, 150, 132);
         if( this.state.mapMarginOpacity < 100 ) {
             context.restore();
         }
    
        context.save();
        context.globalCompositeOperation = 'destination-out';
        context.fillStyle = '#000';
        context.beginPath();
        context.moveTo(poly[0], poly[1]);
        let item = 0;
        for (item = 2; item < poly.length - 1; item += 2) {
            context.lineTo(poly[item], poly[item + 1]);
        }
        context.closePath();
        context.fill();
        context.restore();

        //////////////////////////////////////////////////////////////////////////////////
        // HEX COLOR
        //////////////////////////////////////////////////////////////////////////////////
        if (this.state.mapHexOpacity < 100) {
            context.save();
            context.globalAlpha = Utility.roundFloat(this.state.mapHexOpacity / 100, 2);
        }
        context.fillStyle = this.state.mapHexColor;
        context.beginPath();
        context.moveTo(poly[0], poly[1]);
        for (item = 2; item < poly.length - 1; item += 2) {
            context.lineTo(poly[item], poly[item + 1]);
        }
        context.closePath();
        context.fill();
        if (this.state.mapHexOpacity < 100) {
            context.restore();
        }


        //////////////////////////////////////////////////////////////////////////////////
        // HEX CENTER DOT
        //////////////////////////////////////////////////////////////////////////////////
        if (this.state.hexCenterDotSize > 0) {
            var radius = this.state.hexCenterDotSize;
            context.beginPath();
            context.arc(centerX, centerY, radius, 0, 2 * Math.PI, false);
            context.fillStyle = '#000000';
            context.fill();
        }
        context.strokeStyle = '#000000';

        //////////////////////////////////////////////////////////////////////////////////
        // HEX NUMBER TEXT
        //////////////////////////////////////////////////////////////////////////////////
        if (this.state.hexNumberFontSize > 1) {
            let textOrientation = parseInt(this.state.hexNumberOrientation.replace('angle', ''));
            if (textOrientation !== 0 || this.state.hexNumberFontOpacity < 100) {
                context.save();
            }
            if (this.state.hexNumberFontOpacity < 100) {
                context.globalAlpha = Utility.roundFloat(this.state.hexNumberFontOpacity / 100, 2);
            }
            if (textOrientation === 30) {
                context.translate(42, -29);
                context.rotate(Utility.dr(30));
            }
            if (textOrientation === 60) {
                context.translate(95, -33);
                context.rotate(Utility.dr(60));
            }
            if (textOrientation === 90) {
                context.translate(141, -10);
                context.rotate(Utility.dr(90));
            }
            if (textOrientation === 120) {
                context.translate(170, 35);
                context.rotate(Utility.dr(120));
            }
            if (textOrientation === 150) {
                context.translate(175, 87);
                context.rotate(Utility.dr(150));
            }
            if (textOrientation === 180) {
                context.translate(150, 133);
                context.rotate(Utility.dr(180));
            }

            if (textOrientation === 210) {
                context.translate(108, 163);
                context.rotate(Utility.dr(210));
            }
            if (textOrientation === 240) {
                context.translate(55, 164.5);
                context.rotate(Utility.dr(240));
            }

            if (textOrientation === 270) {
                context.translate(9, 142);
                context.rotate(Utility.dr(270));
            }
            if (textOrientation === 300) {
                context.translate(-20, 98);
                context.rotate(Utility.dr(300));
            }
            if (textOrientation === 330) {
                context.translate(-23, 47);
                context.rotate(Utility.dr(330));
            }

            let calculatedFontSize = this.state.hexNumberFontSize * (120 / this.state.hexDiameter) + 2;
            context.font = calculatedFontSize + "px Arial";
            context.textAlign = "center";
            context.fillStyle = this.state.hexNumberFontColor;
            let topY = (7 - (calculatedFontSize / 20)) + calculatedFontSize
            topY += this.state.hexDiameter / 100
            let adjustY = this.state.hexNumberFontSize / 10
            topY -= adjustY
      
            let bottomY = 122 

            let positionRange = bottomY - topY
            let posY = topY + ( positionRange * (this.state.hexNumberPosition / 100))

            context.fillText("1234", centerX, posY);

            if (textOrientation !== 0 || this.state.hexNumberFontOpacity < 100) {
                context.restore();
            }
        }


        //////////////////////////////////////////////////////////////////////////////////
        // HEX LINES
        //////////////////////////////////////////////////////////////////////////////////
        if( this.state.hexLineOpacity < 100 ) {
            context.save();
            context.globalAlpha = Utility.roundFloat(this.state.hexLineOpacity / 100, 2);
        }
        context.beginPath();
        context.moveTo(poly[0], poly[1]);
        context.strokeStyle = this.state.hexLineColor;
        context.lineWidth = (this.state.hexLineWidth * (100 / this.state.hexDiameter));
        for (item = 2; item < poly.length - 1; item += 2) {
            context.lineTo(poly[item], poly[item + 1]);
            context.stroke();
        }
        context.lineTo(poly[0], poly[1]);
        context.stroke();
        if( this.state.hexLineOpacity < 100 ) {
            context.restore();
        }

    }

    componentDidMount = () => {
        let lastIndex = localStorage.getItem('lastIndex');
        if (lastIndex > -1) {
            let savedHexMapSetups = localStorage.getItem('hexMapSetups');
            if (savedHexMapSetups && lastIndex < savedHexMapSetups.length) {
                let hexMapSetups = JSON.parse(localStorage.getItem('hexMapSetups'));
                let hexMapSetup = hexMapSetups[lastIndex];
                if (hexMapSetup) {
                    if( hexMapSetup.hexNumberPosition === undefined ) {
                        hexMapSetup.hexNumberPosition = 10
                    }
                    if( hexMapSetup.hexNumberGrain === undefined ) {
                        hexMapSetup.hexNumberGrain = 0
                    }
                    this.setState({
                        mapMargin: hexMapSetup.mapMargin,
                        hexDiameter: hexMapSetup.hexDiameter,
                        hexesAcross: hexMapSetup.hexesAcross,
                        hexesDown: hexMapSetup.hexesDown,
                        joggle: hexMapSetup.joggle,
                        hexNumberGrain: hexMapSetup.hexNumberGrain,
                        hexNumberFontColor: hexMapSetup.hexNumberFontColor,
                        hexNumberFontSize: hexMapSetup.hexNumberFontSize,
                        hexNumberOrientation: hexMapSetup.hexNumberOrientation,
                        hexNumberPosition: hexMapSetup.hexNumberPosition,
                        hexCenterDotSize: hexMapSetup.hexCenterDotSize,
                        mapMarginColor: hexMapSetup.mapMarginColor,
                        mapHexColor: hexMapSetup.mapHexColor,
                        hexLineColor: hexMapSetup.hexLineColor,
                        hexLineWidth: hexMapSetup.hexLineWidth,
                        mapMarginOpacity: hexMapSetup.mapMarginOpacity,
                        mapHexOpacity: hexMapSetup.mapHexOpacity,
                        hexNumberFontOpacity: hexMapSetup.hexNumberFontOpacity,
                        hexLineOpacity: hexMapSetup.hexLineOpacity
                    }, () => {
                        this.drawCanvases();
                    });
                }
                else {
                    this.drawCanvases();
                }
            }
            else {
                this.drawCanvases();
            }
        }
        else {
            this.drawCanvases();
        }
    }

    drawCanvases = () => {
        this.setupColorPicker();
        this.setupColorHexDisplay();
    }

    componentDidUpdate = () => {
        if (this.props.hexMapSetup.mapMargin > 0 && this.state.formProcessed) {
            // this.setState({ formAccepted: true });
            this.props.history.push("/hexmap");

        }
    }

    onChange = (evt) => {
        let formName = evt.target.name;
        let value = evt.target.value;
        let numericFields = ['mapMargin', 'hexDiameter', 'hexesAcross', 'hexesDown'];
        if (numericFields.indexOf(formName) > -1) {
            value = value.replace(/([^0-9])+/g, '');
            value = value.replace('.', '');
        }
        let floatFields = ['hexLineWidth', 'hexNumberFontSize', 'hexCenterDotSize'];
        if (floatFields.indexOf(formName) > -1) {
            value = value.replace(/([^0-9.])+/g, '');
        }
        if (formName === 'hexLineWidth' && value < 0) {
            value = 0;
        }
        if (formName === 'hexCenterDotSize' && value < 0) {
            value = 0;
        }
        if (formName === 'hexDiameter' && value < 0) {
            value = 0;
        }
        if (formName === 'hexNumberFontSize' && value < 0) {
            value = 0;
        }
        let maxFontSize = Utility.roundFloat((this.state.hexDiameter / 5), 0);
        if (formName === 'hexNumberFontSize' && value > maxFontSize) {
            value = maxFontSize;
        }
        if (formName === 'hexDiameter') {
            maxFontSize = Utility.roundFloat((value / 5), 0);
            if (this.state.hexNumberFontSize > maxFontSize) {
                this.setState({ hexNumberFontSize: maxFontSize });
            }
        }

        let formNameInvalidName = formName + 'Invalid';
        this.setState({ [formName]: value, [formNameInvalidName]: false }, () => {
            if (formName === 'hexLineWidth' ||
                formName === 'hexCenterDotSize' ||
                formName === 'hexNumberFontSize' ||
                formName === 'hexNumberFontColor' ||
                formName === 'hexDiameter' ||
                formName === 'hexLineColor' ||
                formName === 'hexNumberOrientation') {
                this.redrawColorHexDisplay();
            }

            if (formName === 'mapMarginColor' ||
                formName === 'mapHexColor' ||
                formName === 'hexNumberFontColor' ||
                formName === 'hexLineColor'
            ) {
                if (Utility.isHexaColor(value)) {
                    this.redrawColorHexDisplay();
                }
            }

            if( formName === 'mapMarginOpacity' ||
                formName === 'mapHexOpacity' ||
                formName === 'hexNumberFontOpacity' ||
                formName === 'hexLineOpacity' || 
                formName === 'hexNumberPosition') {
                if( parseInt(value) >= 0 && parseInt(value) <= 100 ) {
                    this.redrawColorHexDisplay();
                }
            }


        });

    }

    onFocus = (evt) => {
        let formName = evt.target.name;
        this.setState({ focusOn: formName });
    }

    submit = () => {
        var invalid = false;
        if (!Utility.isNumeric(this.state.mapMargin) || parseInt(this.state.mapMargin) < 1 || parseInt(this.state.mapMargin) > 10000) {
            invalid = true;
            this.setState({ mapMarginInvalid: true });
        }
        if (!Utility.isNumeric(this.state.hexDiameter) || parseInt(this.state.hexDiameter) < 1 || parseInt(this.state.hexDiameter) > 10000) {
            invalid = true;
            this.setState({ hexDiameterInvalid: true });
        }
        if (!Utility.isNumeric(this.state.hexesAcross) || parseInt(this.state.hexesAcross) < 1 || parseInt(this.state.hexesAcross) > 10000) {
            invalid = true;
            this.setState({ hexesAcrossInvalid: true });
        }
        if (!Utility.isNumeric(this.state.hexesDown) || parseInt(this.state.hexesDown) < 1 || parseInt(this.state.hexesDown) > 10000) {
            invalid = true;
            this.setState({ hexesDownInvalid: true });
        }
        if (!Utility.isNumeric(this.state.hexNumberFontSize) || parseInt(this.state.hexNumberFontSize) < 0 || parseInt(this.state.hexNumberFontSize) > 10000) {
            invalid = true;
            this.setState({ hexNumberFontSizeInvalid: true });
        }
        if (!Utility.isNumeric(this.state.hexCenterDotSize) || parseInt(this.state.hexCenterDotSize) < 0 || parseInt(this.state.hexCenterDotSize) > 10000) {
            invalid = true;
            this.setState({ hexCenterDotSizeInvalid: true });
        }
        if (!Utility.isNumeric(this.state.hexLineWidth) || parseInt(this.state.hexLineWidth) < 0 || parseInt(this.state.hexLineWidth) > 10000) {
            invalid = true;
            this.setState({ hexLineWidtheInvalid: true });
        }
        if (Utility.isHexaColor(this.state.mapMarginColor) === false) {
            invalid = true;
            this.setState({ mapMarginColorInvalid: true });
        }
        if (Utility.isHexaColor(this.state.mapHexColor) === false) {
            invalid = true;
            this.setState({ mapHexColorInvalid: true });
        }
        if (Utility.isHexaColor(this.state.hexNumberFontColor) === false) {
            invalid = true;
            this.setState({ hexNumberFontColorInvalid: true });
        }
        if (Utility.isHexaColor(this.state.hexLineColor) === false) {
            invalid = true;
            this.setState({ hexLineColorInvalid: true });
        }

        if (invalid === false) {
            store.dispatch(setHexMapSetup(
                Utility.roundFloat(this.state.mapMargin, 2),
                Utility.roundFloat(this.state.hexDiameter, 2),
                Utility.roundFloat(this.state.hexesAcross, 0),
                Utility.roundFloat(this.state.hexesDown, 0),
                Utility.roundFloat(this.state.joggle, 0),
                Utility.roundFloat(this.state.hexNumberGrain, 0),
                this.state.hexNumberFontColor,
                Utility.roundFloat(this.state.hexNumberFontSize, 2),
                this.state.hexNumberOrientation,
                this.state.hexNumberPosition,
                Utility.roundFloat(this.state.hexCenterDotSize, 2),
                this.state.mapMarginColor,
                this.state.mapHexColor,
                this.state.hexLineColor,
                this.state.hexLineWidth,
                this.state.mapMarginOpacity,
                this.state.mapHexOpacity,
                this.state.hexNumberFontOpacity,
                this.state.hexLineOpacity
            ));

            // ok lets get this serialized and into local storage ... uh .. yea
            let hexMapSetup = {
                mapMargin: Utility.roundFloat(this.state.mapMargin, 2),
                hexDiameter: Utility.roundFloat(this.state.hexDiameter, 2),
                hexesAcross: Utility.roundFloat(this.state.hexesAcross, 0),
                hexesDown: Utility.roundFloat(this.state.hexesDown, 0),
                joggle: Utility.roundFloat(this.state.joggle, 0),
                hexNumberGrain: Utility.roundFloat(this.state.hexNumberGrain, 0),
                hexNumberFontColor: this.state.hexNumberFontColor,
                hexNumberFontSize: Utility.roundFloat(this.state.hexNumberFontSize, 2),
                hexNumberOrientation: this.state.hexNumberOrientation,
                hexNumberPosition: this.state.hexNumberPosition,
                hexCenterDotSize: Utility.roundFloat(this.state.hexCenterDotSize, 2),
                mapMarginColor: this.state.mapMarginColor,
                mapHexColor: this.state.mapHexColor,
                hexLineColor: this.state.hexLineColor,
                hexLineWidth: this.state.hexLineWidth,
                mapMarginOpacity: this.state.mapMarginOpacity,
                mapHexOpacity: this.state.mapHexOpacity,
                hexNumberFontOpacity: this.state.hexNumberFontOpacity,
                hexLineOpacity: this.state.hexLineOpacity
            };

            let hexMapSetups = [];
            if (localStorage.getItem('hexMapSetups') === null) {
                hexMapSetups.push(hexMapSetup);
                localStorage.setItem('hexMapSetups', JSON.stringify(hexMapSetups));
                localStorage.setItem('lastIndex', 0);
            }
            else {
                hexMapSetups = JSON.parse(localStorage.getItem('hexMapSetups'));
                // check for duplication
                let foundIndex = hexMapSetups.findIndex(function (item) {
                    return item.mapMargin === hexMapSetup.mapMargin &&
                        item.hexDiameter === hexMapSetup.hexDiameter &&
                        item.hexesAcross === hexMapSetup.hexesAcross &&
                        item.hexesDown === hexMapSetup.hexesDown &&
                        item.joggle === hexMapSetup.joggle &&
                        item.hexNumberGrain === hexMapSetup.hexNumberGrain &&
                        item.hexNumberFontColor === hexMapSetup.hexNumberFontColor &&
                        item.hexNumberFontSize === hexMapSetup.hexNumberFontSize &&
                        item.hexNumberOrientation === hexMapSetup.hexNumberOrientation &&
                        item.hexNumberPosition === hexMapSetup.hexNumberPosition &&
                        item.hexCenterDotSize === hexMapSetup.hexCenterDotSize &&
                        item.mapMarginColor === hexMapSetup.mapMarginColor &&
                        item.mapHexColor === hexMapSetup.mapHexColor &&
                        item.hexLineColor === hexMapSetup.hexLineColor &&
                        item.hexLineWidth === hexMapSetup.hexLineWidth &&
                        item.mapMarginOpacity === hexMapSetup.mapMarginOpacity &&
                        item.mapHexOpacity === hexMapSetup.mapHexOpacity &&
                        item.hexNumberFontOpacity === hexMapSetup.hexNumberFontOpacity &&
                        item.hexLineOpacity === hexMapSetup.hexLineOpacity

                });
                if (foundIndex > -1) {
                    localStorage.setItem('lastIndex', foundIndex);
                }
                else {
                    hexMapSetups.push(hexMapSetup);
                    localStorage.setItem('hexMapSetups', JSON.stringify(hexMapSetups));
                    localStorage.setItem('lastIndex', hexMapSetups.length - 1);
                }
            }
            this.setState({ formProcessed: true });
        }
    }

    reset = () => {
        this.setState({
            loadIndex: -1,
            mapMargin: 10,
            hexDiameter: 100,
            hexesAcross: 20,
            hexesDown: 12,
            joggle: 0,
            hexNumberGrain: 0,
            hexNumberFontColor: "#000000",
            hexNumberFontSize: 12,
            hexNumberOrientation: 'angle0',
            hexNumberPosition: 10,
            hexCenterDotSize: 1,
            mapMarginInvalid: false,
            hexDiameterInvalid: false,
            hexesAcrossInvalid: false,
            hexesDownInvalid: false,
            mapMarginColor: "#dddddd",
            mapHexColor: "#ffffff",
            hexLineColor: "#000000",
            hexLineWidth: 1,
            mapMarginColorInvalid: false,
            mapHexColorInvalid: false,
            hexNumberFontColorInvalid: false,
            hexLineColorInvalid: false,
            mapMarginOpacity: 100,
            mapHexOpacity: 100,
            hexNumberFontOpacity: 100,
            hexLineOpacity: 100
        }, () => this.redrawColorHexDisplay());
    }

    loadSetup = (loadIndex, hexMapSetup) => {
        this.setState({
            loadIndex: loadIndex,
            mapMargin: hexMapSetup.mapMargin,
            hexDiameter: hexMapSetup.hexDiameter,
            hexesAcross: hexMapSetup.hexesAcross,
            hexesDown: hexMapSetup.hexesDown,
            joggle: hexMapSetup.joggle,
            hexNumberGrain: hexMapSetup.hexNumberGrain,
            hexNumberFontColor: hexMapSetup.hexNumberFontColor,
            hexNumberFontSize: hexMapSetup.hexNumberFontSize,
            hexNumberOrientation: hexMapSetup.hexNumberOrientation,
            hexNumberPosition: hexMapSetup.hexNumberPosition,
            hexCenterDotSize: hexMapSetup.hexCenterDotSize,
            mapMarginInvalid: false,
            hexDiameterInvalid: false,
            hexesAcrossInvalid: false,
            hexesDownInvalid: false,
            mapMarginColor: hexMapSetup.mapMarginColor,
            mapHexColor: hexMapSetup.mapHexColor,
            hexLineColor: hexMapSetup.hexLineColor,
            hexLineWidth: hexMapSetup.hexLineWidth,
            mapMarginOpacity: hexMapSetup.mapMarginOpacity,
            mapHexOpacity: hexMapSetup.mapHexOpacity,
            hexNumberFontOpacity: hexMapSetup.hexNumberFontOpacity,
            hexLineOpacity: hexMapSetup.hexLineOpacity,
            mapMarginColorInvalid: false,
            mapHexColorInvalid: false,
            hexNumberFontColorInvalid: false,
            hexLineColorInvalid: false
        }, () => this.redrawColorHexDisplay());
    }

    render = () => {

        if (this.state.formAccepted) {
            return <Redirect to={'/hexmap'} />
        }

        return (


            <div className="setup-container">
                <div className="setup">
                    <div className="setup-form">
                        <div className="title">Please supply your hex map parameters</div>
                        <div className="joggle-example">
                            <div><span>JOGGLE Left</span><span>JOGGLE Right</span></div>
                            <img src={joggleExample} alt="joggle example" />
                        </div>
                        <div className="form-area">
                            <div className={this.state.mapMarginInvalid ? 'map-margin invalid' : 'map-margin'}>map margin: <input type="text" name="mapMargin" value={this.state.mapMargin} placeholder="0" onChange={this.onChange} /> <span>distance in pixels from the edge of the hexes to map edge.</span></div>
                            <div className={this.state.hexDiameterInvalid ? 'hex-diameter invalid' : 'hex-diameter'}>hex diameter: <input type="text" name="hexDiameter" value={this.state.hexDiameter} placeholder="0" onChange={this.onChange} /> <span>distance in pixels from one hex point to the opposite.</span></div>
                            <div className={this.state.hexesAcrossInvalid ? 'hexes-across invalid' : 'hexes-across'}>hexes across: <input type="text" name="hexesAcross" value={this.state.hexesAcross} placeholder="0" onChange={this.onChange} /></div>
                            <div className={this.state.hexesDownInvalid ? 'hexes-down invalid' : 'hexes-down'}>hexes down: <input type="text" name="hexesDown" value={this.state.hexesDown} placeholder="0" onChange={this.onChange} /></div>

                            <div className={this.state.hexLineWidthInvalid ? 'hex-line-width invalid' : 'hex-line-width'}>hex line width: <input type="text" name="hexLineWidth" value={this.state.hexLineWidth} placeholder="0" onChange={this.onChange} /></div>

                            <div className={this.state.joggle ? 'joggle invalid' : 'joggle'}>joggle:
                               <label htmlFor="joggle0"><input type="radio" id="joggle0" name="joggle" value="0" checked={parseInt(this.state.joggle) === 0} onChange={this.onChange} />left</label>
                                <label htmlFor="joggle1"><input type="radio" id="joggle1" name="joggle" value="1" checked={parseInt(this.state.joggle) === 1} onChange={this.onChange} />right</label>
                            </div>

                            <div className="hexNumberFontSize">hex number font size:   <input type="text" name="hexNumberFontSize" value={this.state.hexNumberFontSize} placeholder="0" onChange={this.onChange} /><span>Enter 0 for no hex number</span></div>

                            <div className="orientation">
                                <div>
                                    <div className="hexNumberOrientation">hex number orientation:</div>
                                </div>
                                <div>
                                    <div>
                                        <label htmlFor="angle0"><input type="radio" id="angle0" name="hexNumberOrientation" value="angle0" checked={this.state.hexNumberOrientation === 'angle0'} onChange={this.onChange} />0 degrees</label>
                                        <label htmlFor="angle30"><input type="radio" id="angle30" name="hexNumberOrientation" value="angle30" checked={this.state.hexNumberOrientation === 'angle30'} onChange={this.onChange} />30 degrees</label>
                                        <label htmlFor="angle60"><input type="radio" id="angle60" name="hexNumberOrientation" value="angle60" checked={this.state.hexNumberOrientation === 'angle60'} onChange={this.onChange} />60 degrees</label>
                                    </div>
                                    <div>
                                        <label htmlFor="angle90"><input type="radio" id="angle90" name="hexNumberOrientation" value="angle90" checked={this.state.hexNumberOrientation === 'angle90'} onChange={this.onChange} />90 degrees</label>
                                        <label htmlFor="angle120"><input type="radio" id="angle120" name="hexNumberOrientation" value="angle120" checked={this.state.hexNumberOrientation === 'angle120'} onChange={this.onChange} />120 degrees</label>
                                        <label htmlFor="angle150"><input type="radio" id="angle150" name="hexNumberOrientation" value="angle150" checked={this.state.hexNumberOrientation === 'angle150'} onChange={this.onChange} />150 degrees</label>
                                    </div>
                                    <div>
                                        <label htmlFor="angle180"  ><input type="radio" id="angle180" name="hexNumberOrientation" value="angle180" checked={this.state.hexNumberOrientation === 'angle180'} onChange={this.onChange} />180 degrees</label>
                                        <label htmlFor="angle210" ><input type="radio" id="angle210" name="hexNumberOrientation" value="angle210" checked={this.state.hexNumberOrientation === 'angle210'} onChange={this.onChange} />210 degrees</label>
                                        <label htmlFor="angle240"><input type="radio" id="angle240" name="hexNumberOrientation" value="angle240" checked={this.state.hexNumberOrientation === 'angle240'} onChange={this.onChange} />240 degrees</label>
                                    </div>
                                    <div>
                                        <label htmlFor="angle270"><input type="radio" id="angle270" name="hexNumberOrientation" value="angle270" checked={this.state.hexNumberOrientation === 'angle270'} onChange={this.onChange} />270 degrees</label>
                                        <label htmlFor="angle300"><input type="radio" id="angle300" name="hexNumberOrientation" value="angle300" checked={this.state.hexNumberOrientation === 'angle300'} onChange={this.onChange} />300 degrees</label>
                                        <label htmlFor="angle330"><input type="radio" id="angle330" name="hexNumberOrientation" value="angle330" checked={this.state.hexNumberOrientation === 'angle330'} onChange={this.onChange} />330 degrees</label>
                                    </div>
                                </div>
                            </div>

                            <div className="joggle">start hex numbering grain:
                                <label htmlFor="hexNumberGrain0"><input type="radio" id="hexNumberGrain0" name="hexNumberGrain" value="0" checked={parseInt(this.state.hexNumberGrain) === 0} onChange={this.onChange} />horizontal</label>
                                <label htmlFor="hexNumberGrain1"><input type="radio" id="hexNumberGrain1" name="hexNumberGrain" value="1" checked={parseInt(this.state.hexNumberGrain) === 1} onChange={this.onChange} />vertical</label>
                            </div>

                            <div className="position">
                              
                                    <div className="hexNumberPosition">hex number position from top:</div>
                                    <div className="control-position">
                                        <input type="range" min="0" max="100" 
                                        name="hexNumberPosition" 
                                        value={this.state.hexNumberPosition} 
                                        onChange={this.onChange} onFocus={this.onFocus} />
                                        <span>{this.state.hexNumberPosition}%</span>
                                    </div>

                                
                            </div>    


                            <div className="hexCenterDotSize">hex center dot size:   <input type="text" name="hexCenterDotSize" value={this.state.hexCenterDotSize} placeholder="0" onChange={this.onChange} /><span>Enter 0 for no center dot.</span></div>

                            <div className="colors-title"><span>C</span><span>O</span><span>L</span><span>O</span><span>R</span><span>S</span><span className="underline"></span></div>
                            <div className="colors">
                                <div>
                                    <canvas id="colorPickerCanvas" width="244" height="200" style={{ "cursor": "crosshair" }} />
                                </div>
                                <div className="color-selections">

                                    <div className="color-selection">
                                        <div className={this.state.focusOn === 'mapMarginColor' ? 'display-arrow' : 'arrow-hidden'}><span></span></div>
                                        <div className="color-title">Margin:</div>
                                        <div><input type="text" className={this.state.mapMarginColorInvalid ? 'invalid' : ''} name="mapMarginColor" value={this.state.mapMarginColor} onChange={this.onChange} onFocus={this.onFocus} /></div>
                                    </div>
                                    <div className="color-selection">
                                        <div className={this.state.focusOn === 'mapHexColor' ? 'display-arrow' : 'arrow-hidden'}><span></span></div>
                                        <div className="color-title">Hex:</div>
                                        <div><input type="text" className={this.state.mapHexColorInvalid ? 'invalid' : ''} name="mapHexColor" value={this.state.mapHexColor} onChange={this.onChange} onFocus={this.onFocus} /></div>
                                    </div>
                                    <div className="color-selection">
                                        <div className={this.state.focusOn === 'hexNumberFontColor' ? 'display-arrow' : 'arrow-hidden'}><span></span></div>
                                        <div className="color-title">Number:</div>
                                        <div><input type="text" className={this.state.hexNumberFontColorInvalid ? 'invalid' : ''} name="hexNumberFontColor" value={this.state.hexNumberFontColor} onChange={this.onChange} onFocus={this.onFocus} /></div>
                                    </div>
                                    <div className="color-selection">
                                        <div className={this.state.focusOn === 'hexLineColor' ? 'display-arrow' : 'arrow-hidden'}><span></span></div>
                                        <div className="color-title">Hexline:</div>
                                        <div><input type="text" className={this.state.hexLineColorInvalid ? 'invalid' : ''} name="hexLineColor" value={this.state.hexLineColor} onChange={this.onChange} onFocus={this.onFocus} /></div>
                                    </div>

                                </div>
                                <div className="opacity-settings">
                                    <div className="opacity"><input type="range" min="0" max="100" name="mapMarginOpacity" value={this.state.mapMarginOpacity} onChange={this.onChange} onFocus={this.onFocus} /><span>{this.state.mapMarginOpacity}%</span></div>
                                    <div className="opacity"><input type="range" min="0" max="100" name="mapHexOpacity" value={this.state.mapHexOpacity} onChange={this.onChange} onFocus={this.onFocus} /><span>{this.state.mapHexOpacity}%</span></div>
                                    <div className="opacity"><input type="range" min="0" max="100" name="hexNumberFontOpacity" value={this.state.hexNumberFontOpacity} onChange={this.onChange} onFocus={this.onFocus} /><span>{this.state.hexNumberFontOpacity}%</span></div>
                                    <div className="opacity"><input type="range" min="0" max="100" name="hexLineOpacity" value={this.state.hexLineOpacity} onChange={this.onChange} onFocus={this.onFocus} /><span>{this.state.hexLineOpacity}%</span></div>
                                </div>
                                <div className="color-hex-display-container">
                                    <canvas id="colorHexDisplay" width="150" height="132" />
                                </div>
                                <div className="opacity-title">opacity</div>
                            </div>
                            <div className="action-buttons">
                                <button className="reset" onClick={this.reset}>Reset</button>
                                <button className="submit" onClick={this.submit}>Submit</button>
                            </div>
                        </div>
                    </div>
                    <ExistingSetups loadIndex={this.state.loadIndex} loadSetup={this.loadSetup} />
                </div>
            </div>
        );

    }

}

const mapStateToProps = (state /*, ownProps*/) => {
    return {
        hexMapSetup: state.hexMapSetup
    }
}

const mapDispatchToProps = { setHexMapSetup }

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Setup)