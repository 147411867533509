export function setHexMapSetup(
    mapMargin,
    hexDiameter,
    hexesAcross,
    hexesDown,
    joggle,
    hexNumberGrain,
    hexNumberFontColor,
    hexNumberFontSize,
    hexNumberOrientation,
    hexNumberPosition,
    hexCenterDotSize,
    mapMarginColor,
    mapHexColor,
    hexLineColor,
    hexLineWidth,
    mapMarginOpacity,
    mapHexOpacity,
    hexNumberFontOpacity,
    hexLineOpacity) {
    return {
        type: "SET_HEXMAP_SETUP",
        hexMapSetup: {
            mapMargin: mapMargin,
            hexDiameter: hexDiameter,
            hexesAcross: hexesAcross,
            hexesDown: hexesDown,
            joggle: joggle,
            hexNumberGrain: hexNumberGrain,
            hexNumberFontColor: hexNumberFontColor,
            hexNumberFontSize: hexNumberFontSize,
            hexNumberOrientation,
            hexNumberPosition,
            hexCenterDotSize,
            mapMarginColor,
            mapHexColor,
            hexLineColor,
            hexLineWidth,
            mapMarginOpacity,
            mapHexOpacity,
            hexNumberFontOpacity,
            hexLineOpacity,
        }
    }
}

export function setHexMap(hexMap) {
    return {
        type: "SET_HEXMAP",
        hexMap: hexMap
    }
}  

export function svgLoaded( svgs )
    {
        return {
            type: "SVG_LOADED",
            svgs: []
        }
    }