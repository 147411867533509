import React, { Component } from 'react';
import Utility from '../../Utility';
import './PathReducer.scss';

export default class PathReducer extends Component {

    state = {
        pathReduced: 'nothing here yet'
    }

    componentDidMount = () => {

    }

    reducePath = (evt) => {
        evt.preventDefault()
        let d = evt.target.fatPath.value
        let precision = evt.target.precision.value
        let multiplier = evt.target.multiplier.value
        if (d) {
            d = Utility.replaceAll(d, ',', ' ')
            d = d.replace(/\s\s+/g, ' ');
            d = d.trim()
            let arr = d.split(' ')
            let marr = Utility.roundOffPathArrayNumbersWithScale(arr, precision, multiplier)
            let pathReduced = marr.join(' ')
            this.setState({ pathReduced })
        }
    }

    clear = () => {
        this.setState({ pathReduced: '' })
        document.getElementById('fatPath').value = ''
    }

    render = () => {

        return (
            <div className="path-reducer">
                <div className="title">PATH REDUCER</div>
                <div className="centered">
                    <form onSubmit={this.reducePath}>
                        <div className="enter-path">
                            <div className="text-title">Enter path:</div>
                            <textarea id="fatPath" name="fatPath"></textarea>
                        </div>
                        <div className="action-button">
                            <div>precision: <input type="text" name="precision" defaultValue="1"  /></div>
                            <div>multiplier: <input type="text" name="multiplier" defaultValue="1" /></div>
                            <input type="submit" value="submit" />
                            <input type="button" value="clear" onClick={this.clear} />
                        </div>
                    </form>
                    <div className="reduced-path">
                        <div className="text-title">Reduced path:</div>
                        <div className="path-area">
                            {this.state.pathReduced}
                        </div>
                    </div>
                </div>
            </div>
        )
    }

}