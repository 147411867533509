import React, { Component } from 'react';
import './CounterLoadedSavedInfo.scss';

export default class CounterLoadedSavedInfo extends Component {

    state = {
        dateString: ''
    }

    componentDidUpdate = () => {
        var time = new Date(this.props.counterLoadedSavedInfo.date);

        let dateString = time.toLocaleDateString([], { hour: '2-digit', minute: '2-digit' })
        if (dateString !== this.state.dateString) {
            this.setState({ dateString })
        }

    }

    dragEnd = (evt) => {

    }

    layerClick = (layerKey) => {

    }

    render = () => {
        if (this.props.counterLoadedSavedInfo.type === 'none') {
            return ''
        }
        return <div className="counter-loaded-saved-info">
                   <span className="loaded-saved">{this.props.counterLoadedSavedInfo.type}: 
                       <span className="data-part"> {this.props.counterLoadedSavedInfo.name}</span>
                    </span> 
                    <span className="data-part date">{this.state.dateString}</span>
                </div>
    }

}