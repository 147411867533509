import React, { Component } from 'react';
import ButtonRadioSvg from "../../ButtonRadioSvg/ButtonRadioSvg";
import Utility from '../../../Utility';
import IndexedDb from '../../../IndexedDb';
import './Layer.scss';

export default class Layer extends Component {

    state = {
        layerName: '',
        displayRows: [],
        editingLayerName: false,
        previousProps: [],
        focusOn: null,
        hilightSelf: false,
        layerContentsVisible: true
    }

    vars = { events: [], lastColorFromColorPicker: '', textInputId: null, startCursor: 0, endCursor: 0 }

    componentDidMount = () => {
        if (this.props.layer && this.props.layer.inputs && this.props.layer.inputs.length > 0) {
            this.setupDisplayRows();
        }
        this.refEditLayerName = React.createRef();
    }

    componentDidUpdate = () => {
        if (JSON.stringify(this.props.layer) !== JSON.stringify(this.state.previousProps.layer)) {
            if (this.props.layer && this.props.layer.inputs && this.props.layer.inputs.length > 0) {
                this.setupDisplayRows();
            }
        }
        let eventsHistory = this.props.eventsHistoryGet()
        if (eventsHistory.length > 1) {
            if (eventsHistory[0] === 'colorPicker') {

                if (this.props.colorPicked) {
                    if (this.props.layer.key === this.props.colorPicked.layerKey) {
                        let currentValue = this.getCurrentInputValue(this.props.colorPicked.formName)
                        if (currentValue !== this.props.colorPicked.color) {
                            this.setInputField(this.props.colorPicked.formName, this.props.colorPicked.color, true)
                        }
                    }
                }
            }
        }
    }

    toggleLayerContentsVisible = () => {
        this.setInputField('minimized', !this.props.layer.minimized, true)
    }

    getCurrentInputValue = (name) => {
        let inputs = this.props.layer.inputs;
        for (var i = 0; i < inputs.length; i++) {
            if (inputs[i].name === name) {
                return inputs[i].value
            }
        }
    }

    setupDisplayRows = () => {
        if (JSON.stringify(this.props) !== JSON.stringify(this.state.previousProps)) {
            let inputs = this.props.layer.inputs;
            let displayRows = [];

            for (var i = 0; i < inputs.length; i++) {
                let input = inputs[i];

                let displayRow = input.displayRow;
                if (displayRow < 1) {
                    continue // we may have hidden input fields, that are only used for computations like for line dragging
                }
                while (displayRows.length < displayRow) {
                    displayRows.push([])
                }
                if (displayRows[displayRow - 1]) {
                    displayRows[displayRow - 1].push(i);
                }

            }
            let layerName = this.props.layer.name
            this.setState({ layer: this.props.layer, layerName, displayRows, previousProps: this.props });

        }
    }

    callUpdateLayerName = (key) => {
        this.props.updateLayerName(key)
        this.setState({ editingLayerName: false })
    }

    onFocus = (evt) => {
        let formName = evt.target.name;
        this.props.focusOn(this.props.layer.key, formName)
        if (formName.toLowerCase().includes('color')) {
            this.props.eventsHistoryPush('focusColor')
            evt.target.classList.add('color-focused');
        }
        else {
            this.props.eventsHistoryPush('focus')
        }

    }

    onChangeButtonRadioSvg = (name, value) => {
        this.setInputField(name, value, true)
    }

    onChange = (evt) => {
        let formName = evt.target.name;
        let formType = evt.target.type;
        let value = evt.target.value;
        let id = ''
        if (formType === 'text') {
            this.vars.cursorStart = evt.target.selectionStart
            this.vars.cursorEnd = evt.target.selectionEnd;
            id = evt.target.id
            this.vars.textInputId = id
        }
        if (formName === 'layerName') {
            this.setState({ layerName: value });
        }

        if (evt.target.hasOwnProperty('checked') && formType === 'checkbox') {
            value = evt.target.checked;
        }

        this.setInputField(formName, value, true)
    }

    setInputField = (name, value, updateDb = false) => {
        let layer = this.state.layer;

        if (layer.layerType === 'radio' ||
            (layer.layerType === 'unitSymbol' && name === 'unitSymbolSvg') ||
            (layer.layerType === 'unitSize' && name === 'unitSizeSvg') ||
            (layer.layerType === 'symbol' && name === 'symbolSvg') ||
            (layer.layerType === 'vehicle' && name === 'vehicleSvg') ||
            (layer.layerType === 'images' && name === 'imagesSvg') ||
            (layer.layerType === 'aircraft' && name === 'aircraftSvg')
        ) {
            layer.checkedValue = value;
        }
        else {
            if (name === 'minimized' || name === 'indicate') {
                layer[name] = value
            }
            else {
                let inputs = layer.inputs;
                for (var i = 0; i < inputs.length; i++) {
                    if (inputs[i].name === name) {
                        if (inputs[i].value === value) {
                            return; // nothing to change.
                        }
                        inputs[i].value = value
                    }
                }
                layer.inputs = inputs
            }
        }

        if (updateDb) {
            let layer = this.state.layer;
            IndexedDb.put('layer', layer, layer.key).then(() => {
                this.props.dbLayerUpdated()
                this.resetCursorPositionForTextInput()
            })
        }
        else {
            this.setState({ layer }, this.resetCursorPositionForTextInput())
        }
    }

    resetCursorPositionForTextInput = () => {
        if (this.vars.textInputId) {
            setTimeout(() => {
                let ele = document.getElementById(this.vars.textInputId)
                if (ele) {
                    ele.setSelectionRange(this.vars.cursorStart, this.vars.cursorEnd)
                }
            }, 10);
            setTimeout(() => {
                let ele = document.getElementById(this.vars.textInputId)
                if (ele) {
                    ele.setSelectionRange(this.vars.cursorStart, this.vars.cursorEnd)
                }
            }, 25);
            setTimeout(() => {
                let ele = document.getElementById(this.vars.textInputId)
                if (ele) {
                    ele.setSelectionRange(this.vars.cursorStart, this.vars.cursorEnd)
                }
            }, 50);
            setTimeout(() => {
                let ele = document.getElementById(this.vars.textInputId)
                if (ele) {
                    ele.setSelectionRange(this.vars.cursorStart, this.vars.cursorEnd)
                }
            }, 90);

            setTimeout(() => {
                this.vars.textInputElement = null
                this.vars.cursorStart = 0
                this.vars.cursorEnd = 0
            }, 100)

        }



    }

    cancelUpdateLayerName = () => {
        let layerName = this.props.layer.name;
        this.setState({ layerName, editingLayerName: false })
    }

    getOtherExistingLayerNames = async (exceptKey) => {
        let layerNames = [];
        let keys = await IndexedDb.getAllKeys('layer');
        for (var i = 0; i < keys.length; i++) {
            if (keys[i] === exceptKey) {
                continue;
            }
            let layerData = await IndexedDb.get('layer', keys[i]);
            let layerName = layerData.name;
            layerNames.push(layerName);
        }
        return layerNames;
    }

    updateLayerName = async () => {
        this.props.logMessage('updating layer name')
        let changingNameTo = this.state.layerName
        if (changingNameTo === '') {
            return
        }

        let existingNames = await this.getOtherExistingLayerNames(this.state.layer.key)
        let find = existingNames.findIndex(item => changingNameTo.toLowerCase().trim() === item.toLowerCase().trim())
        if (find > -1) {
            this.props.logMessage('layer name ' + changingNameTo + ' already exists')
            this.setState({ layerName: this.state.layer.name })
        }
        else {
            let layerData = this.state.layer;
            layerData.name = changingNameTo.trim();
            IndexedDb.put('layer', layerData, layerData.key).then(() => {
                this.setState({ editingLayerName: false })
                this.props.dbLayerUpdated()
                this.props.logMessage('layer name updated')
            })
        }
    }

    updateLayerNameClick = () => {
        this.setState({ editingLayerName: true }, () => {
            setTimeout(() => this.refEditLayerName.current.focus())
        })
    }

    deleteLayer = () => {
        this.props.deleteLayerByName(this.state.layerName)
    }

    duplicateLayer = (layerKey) => {
        this.props.duplicateLayer(layerKey)
    }

    render = () => {

        if (!this.state.layer) {
            return (<div />)
        }

        return (
            <div className={this.props.layer.indicate ? 'indicate-layer layer' : 'layer'}>

                <div className={this.state.editingLayerName ? 'display-none' : 'layer-header'}>
                    <div>
                        <span className={this.props.layer.minimized ? 'display-none' : 'contents-toggle toggle1'} onClick={this.toggleLayerContentsVisible}>-</span>
                        <span className={this.props.layer.minimized ? 'contents-toggle toggle2' : 'display-none'} onClick={this.toggleLayerContentsVisible}>+</span>
                        <span className={this.props.layer.minimized ? 'layer-contents-hidden' : ''}>{this.state.layerName}
                        </span>

                    </div>
                    <span>
                        <span className="layer-type-text">type: {this.props.layer.layerType}</span>
                        <button className={this.state.layerName === 'Counter background color' ? 'display-none' : 'momentary-button smaller'} onClick={() => this.duplicateLayer(this.state.layer.key)}>duplicate</button>
                        <button className={this.state.layerName === 'Counter background color' || this.state.layerName === 'Unit symbol' ? 'display-none' : 'momentary-button smaller'} onClick={this.updateLayerNameClick}>edit name</button>
                        <button className={this.state.layerName === 'Counter background color' ? 'display-none' : 'momentary-button smaller'} onClick={this.deleteLayer}>delete</button>
                    </span>
                </div>

                <div className={this.state.editingLayerName ? 'layer-header edit' : 'display-none'}>
                    <input type="text" value={this.state.layerName}
                        ref={this.refEditLayerName}
                        name="layerName"
                        onFocus={this.onFocus}
                        onChange={this.onChange}
                        autoComplete="off" autoCorrect="off" autoCapitalize="off" spellCheck="false" />
                    <span>
                        <button className="momentary-button smaller" onClick={this.updateLayerName}>submit</button>
                        <button className="momentary-button smaller" onClick={this.cancelUpdateLayerName}>cancel</button>
                    </span>
                </div>

                <div className={this.props.layer.minimized ? 'display-none' : 'inputs-flex'}>
                    {this.state.displayRows.map((displayRow, index) => {
                        return (
                            <div key={'row_' + index} className="">
                                {displayRow.map((inputIndex, index2) => {
                                    let input = this.state.layer.inputs[inputIndex]
                                    if (input.type === 'ButtonRadioSvg') {
                                        return <div key={'input_' + index2} className={input.hasOwnProperty('hidden') && input.hidden ? 'display-none' : 'input-container'}>
                                            <ButtonRadioSvg
                                                label={input.label}
                                                value={input.value}
                                                svg={input.name}
                                                onFocus={this.onFocus}
                                                onChangeButtonRadioSvg={this.onChangeButtonRadioSvg}
                                                checked={input.value === this.state.layer.checkedValue}
                                            />
                                        </div>
                                    }

                                    if (input.type === 'text') {
                                        return <div key={'input_' + index2} className={input.hasOwnProperty('hidden') && input.hidden ? 'display-none' : 'input-container'}>
                                            <span>{input.label}: </span>
                                            <span>
                                                <input className={input.className}
                                                    type="text"
                                                    id={'id_' + this.props.layer.key + '_' + input.name}
                                                    name={input.name}
                                                    value={input.value}
                                                    onFocus={this.onFocus}
                                                    onChange={this.onChange}
                                                    onPaste={this.onChange}
                                                    autoComplete="off" autoCorrect="off" autoCapitalize="off" spellCheck="false" />
                                            </span>
                                        </div>
                                    }



                                    if (input.type === 'select' && input.label === 'font') {
                                        return <div key={'input_' + index2} className="input-container">
                                            <span>{input.label}: </span>

                                            <span>
                                                <select className="select-css"
                                                    name={input.name}
                                                    value={input.value}
                                                    onFocus={this.onFocus}
                                                    onChange={this.onChange} >
                                                    {Utility.fontFamilies.map((option, indexSelect) => {
                                                        return <option key={indexSelect}
                                                            value={option.value}>{option.label}</option>
                                                    })}
                                                </select>
                                            </span>
                                        </div>
                                    }

                                    if (input.type === 'select' && input.label === 'line cap') {
                                        return <div key={'input_' + index2} className="input-container">
                                            <span>{input.label}: </span>

                                            <span>
                                                <select className="select-css"
                                                    name={input.name}
                                                    value={input.value}
                                                    onFocus={this.onFocus}
                                                    onChange={this.onChange} >
                                                    {Utility.lineCaps.map((option, indexSelect) => {
                                                        return <option key={indexSelect}
                                                            value={option.value}>{option.label}</option>
                                                    })}
                                                </select>
                                            </span>
                                        </div>
                                    }



                                    if (input.type === 'checkbox') {
                                        return <div key={'input_' + index2} className="input-container">
                                            <span>{input.label}: </span>
                                            <span><input
                                                className="checkbox"
                                                type="checkbox"
                                                name={input.name}
                                                checked={input.value}
                                                onFocus={this.onFocus}
                                                onChange={this.onChange}
                                            /></span>
                                        </div>
                                    }

                                    if (input.type === 'radio') {
                                        return <div key={'input_' + index2} className="input-container">
                                            <label >{input.label}:
                                            <span><input className=""
                                                    type="radio"
                                                    id={input.value}
                                                    name={input.name}
                                                    value={input.value}
                                                    checked={input.value === this.state.layer.checkedValue}
                                                    onFocus={this.onFocus}
                                                    onChange={this.onChange}

                                                /></span>
                                            </label>
                                        </div>
                                    }

                                    return <div />



                                }, this)}


                            </div>)
                    }, this)}
                </div>



            </div>
        )
    }
}





