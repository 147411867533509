import React, { Component } from 'react';
import './TabManager.scss';

export default class TabManager extends Component {

    state = {
        activeTabIndex: 0
    }

    componentDidMount = () => {
       
    }

    setActiveTabIndex = (index) => {
        if (index !== this.state.activeTabIndex) {
            this.setState({ activeTabIndex: index });
        }
    }

    render = () => {

        return (
            <div className="tab-manager">
                <div className="tabName-tabs">
                    {this.props.tabs.map((tabName, index) => {
                        return (
                            <div className={this.state.activeTabIndex === index ? 'tabName-tab active' : 'tabName-tab'} key={'tabIndex_' + index} onClick={() => this.setActiveTabIndex(index)}><span>{tabName}</span></div>
                        )
                    })}
                </div>

                {this.props.tabs.map((tabName, index) => {
                    return (
                        <div className={this.state.activeTabIndex === index ? '' : 'display-none'} key={index}>
                            {this.props.children[index]}
                        </div>)
                })}

            </div>
        )
    }

}