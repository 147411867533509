import React, { Component } from 'react';
import IndexedDb from '../../IndexedDb';
import Utility from '../../Utility';
import Layer from "./Layer/Layer";
//import JSZip from 'jszip'
import './Layers.scss';

export default class Layers extends Component {

    state = {
        colorPicked: { color: '', layerKey: -1, formName: '' },
        indicateLayerKey: null,
        actions: null
    }

    vars = { focusOn: { layerKey: -1, formName: '' }, blockLoadDefaultLayers: false, layerDuplicatedKey: null }

    componentDidMount = () => {

        if (this.props.layers && Array.isArray(this.props.layers) && this.props.layers.length > 0) {
            this.vars.blockLoadDefaultLayers = true
        }

        //console.log('calling this.fetchList')
        this.fetchList()

        //this.loadNewLayers()
    }

    fetchJson = (name) => {
        return new Promise(async (resolve, reject) => {
            const res = await fetch('./' + name + '.zip');
            res
                .json()
                .then(res => { resolve(res) })
                .catch(err => { console.log('error: ', err); });
        })
    }

    fetchZip = (name) => {
        return new Promise(async (resolve, reject) => {
            fetch('./' + name + '.zip')
                .then(response => {
                    console.log('response: ', response)
                    const reader = response.body.getReader();
                    reader.read().then(({ done, value }) => resolve(value))
                })
        })
    }

    fetchList = async () => {
        let fetchList = ['churchill','medieval_knight','air_strike_soviet','stugIII_g','sig_33','uboot_type_viii', 'bismarck', 'konigsburg']
        setTimeout(async () => {
            for (var i = 0; i < fetchList.length; i++) {
                let name = fetchList[i]

                let obj = {}
                let key = await IndexedDb.getKeyForName('svg', name)
                if (key === -1) {
                    // key === -1 means we don't have the svg in IndexedDb, so we need to pull it from the server.
                    key = await IndexedDb.put('svg', obj)
                    console.log('fetching zip: ', name)
                    let data = await this.fetchZip(name)
                    obj = { name, key, data }
                    IndexedDb.put('svg', obj, key)
                }
                //let jsonData = await Utility.unZip(data)
                //console.log('got jsonData: ', jsonData)
            }
        }, 1000)
    }

    // unZip = (blob) => {
    //     JSZip.loadAsync(blob).then(async function (zipped) {
    //         let jsonName = Object.keys(zipped.files)[0]
    //         var jsonFile = await zipped.file(jsonName).async("text");
    //         //console.log('jsonFile: ', jsonFile)
    //     })
    // }

    componentDidUpdate = () => {
        if (this.props.layers && Array.isArray(this.props.layers) && this.props.layers.length > 0) {
            this.vars.blockLoadDefaultLayers = true
        }

        if (this.vars.layerDuplicatedKey !== null) {
            let toIndex = -1
            let fromIndex = -1

            for (var i = 0; i < this.props.layers.length; i++) {
                if (this.props.layers[i] && this.props.layers[i].hasOwnProperty('key')) {
                    if (this.props.layers[i].key === this.vars.layerDuplicatedKey) {
                        toIndex = i
                    }
                    if (i === this.props.layers.length - 1) { // the new layer will be the last layer
                        fromIndex = i
                    }
                }
            }
            if (fromIndex === toIndex) {
                return
            }
            if (fromIndex === -1 || toIndex === -1) {
                return
            }
            this.vars.layerDuplicatedKey = null
            this.props.layerReorder(fromIndex, toIndex);
        }
    }

    focusOn = (layerKey, formName) => {
        this.vars.focusOn = { layerKey, formName }
        let inputs = document.getElementsByTagName('input');
        for (let i = 0; i < inputs.length; ++i) {
            // deal with inputs[index] element.
            inputs[i].classList.remove('color-focused');
        }
    }

    colorPicked = (color) => {
        if (Utility.isHexaColor(color) && this.vars.focusOn.layerKey > -1 && this.vars.focusOn.formName.toLowerCase().endsWith('color')) {
            this.setState({ colorPicked: { color: color, layerKey: this.vars.focusOn.layerKey, formName: this.vars.focusOn.formName } })
        }
    }

    resetLayers = async () => {
        let keys = await IndexedDb.getAllKeys('layer')
        if (keys && Array.isArray(keys) && keys.length > 0) {
            for (let i = 0; i < keys.length; i++) {
                let key = keys[i]
                await IndexedDb.deleteKey('layer', key)
            }
        }
        this.props.dbLayerUpdated()
        this.vars.blockLoadDefaultLayers = false
        this.loadDefaultLayers()
    }

    loadDefaultLayers = async () => {
        var this1 = this
        if (this.vars.blockLoadDefaultLayers === false) {
            this.vars.blockLoadDefaultLayers = true;
            await IndexedDb.getAllNames('layer').then(async (allLayerNames) => {
                if (allLayerNames.indexOf('Counter background color') === -1) {
                    this.props.logMessage('installing default layer Counter Background Color')
                    await this1.upsertLayer('Counter background color', 'counterBackgroundColor');
                }
                if (allLayerNames.indexOf('Unit symbol') === -1) {
                    this.props.logMessage('installing default layer Unit Symbol')
                    await this1.upsertLayer('Unit symbol', 'unitSymbol');
                }
                if (allLayerNames.indexOf('Symbol') === -1) {
                    this.props.logMessage('installing default layer Symbol')
                    await this1.upsertLayer('Symbol', 'symbol');
                }
                if (allLayerNames.indexOf('Vehicle') === -1) {
                    this.props.logMessage('installing default layer Vehicle')
                    await this1.upsertLayer('Vehicle', 'vehicle');
                }
                if (allLayerNames.indexOf('Images') === -1) {
                    this.props.logMessage('installing default layer Images')
                    await this1.upsertLayer('Images', 'images');
                }
                if (allLayerNames.indexOf('Identifier') === -1) {
                    this.props.logMessage('installing default layer Identifier')
                    await this1.upsertLayer('Identifier', 'text');
                }
                if (allLayerNames.indexOf('Unit size') === -1) {
                    this.props.logMessage('installing default layer Unit Size')
                    await this1.upsertLayer('Unit size', 'unitSize');
                }
                if (allLayerNames.indexOf('Combat') === -1) {
                    this.props.logMessage('installing default layer Combat')
                    await this1.upsertLayer('Combat', 'text');
                }
                if (allLayerNames.indexOf('Defense') === -1) {
                    this.props.logMessage('installing default layer Defense')
                    await this1.upsertLayer('Defense', 'text');
                }
                if (allLayerNames.indexOf('Movement') === -1) {
                    this.props.logMessage('installing default layer Movement')
                    await this1.upsertLayer('Movement', 'text');
                }
                if (allLayerNames.indexOf('Rating') === -1) {
                    this.props.logMessage('installing default layer Rating')
                    await this1.upsertLayer('Rating', 'text');
                }
            })
        }
    }

    loadNewLayers = async () => {
        var this1 = this
        await IndexedDb.getAllNames('layer').then(allLayerNames => {
            if (allLayerNames.indexOf('Counter background color') === -1) {
                this.props.logMessage('installing layer Counter Background Color')
                this1.upsertLayer('Counter background color', 'counterBackgroundColor');
            }
            if (allLayerNames.indexOf('Unit symbol') === -1) {
                this.props.logMessage('installing layer Unit Symbol')
                this1.upsertLayer('Unit symbol', 'unitSymbol');
            }
            if (allLayerNames.indexOf('Symbol') === -1) {
                this.props.logMessage('installing layer Symbol')
                this1.upsertLayer('Symbol', 'symbol');
            }
            if (allLayerNames.indexOf('Vehicle') === -1) {
                this.props.logMessage('installing layer Vehicle')
                this1.upsertLayer('Vehicle', 'vehicle');
            }
            if (allLayerNames.indexOf('Images') === -1) {
                this.props.logMessage('installing layer Image')
                this1.upsertLayer('Images', 'images');
            }
            if (allLayerNames.indexOf('Identifier') === -1) {
                this.props.logMessage('installing layer Identifier')
                this1.upsertLayer('Identifier', 'text');
            }
            if (allLayerNames.indexOf('Unit size') === -1) {
                this.props.logMessage('installing layer Unit Size')
                this1.upsertLayer('Unit size', 'unitSize');
            }
            if (allLayerNames.indexOf('Combat') === -1) {
                this.props.logMessage('installing layer Combat')
                this1.upsertLayer('Combat', 'text');
            }
            if (allLayerNames.indexOf('Defense') === -1) {
                this.props.logMessage('installing layer Defense')
                this1.upsertLayer('Defense', 'text');
            }
            if (allLayerNames.indexOf('Movement') === -1) {
                this.props.logMessage('installing layer Movement')
                this1.upsertLayer('Movement', 'text');
            }
            if (allLayerNames.indexOf('Rating') === -1) {
                this.props.logMessage('installing layer Rating')
                this1.upsertLayer('Rating', 'text');
            }
        })
    }

    addLayer = async (layerName, layerType, settings) => {
        await this.upsertLayer(layerName, layerType, settings);
    }

    duplicateLayer = async (layerKey) => {
        let layer = await IndexedDb.get('layer', layerKey)
        if (layerKey === null) {
            return
        }
        let layerName = layer.name
        let existingLayerNames = await IndexedDb.getAllNames('layer')
        existingLayerNames = existingLayerNames.filter(name => typeof name === 'string')
        // with original layerName, remove any _dupeNNN
        let rnPos = layerName.indexOf('_dupe')
        if (rnPos > 0) {
            layerName = layerName.substring(0, rnPos)
        }
        let renameIndex = 2
        let extention = '_dupe' + renameIndex

        let tryNewName = layerName + extention
        // eslint-disable-next-line
        while (existingLayerNames.find(existingName => existingName.toLowerCase() === tryNewName.toLowerCase())) {
            renameIndex++
            extention = '_dupe' + renameIndex
            tryNewName = layerName + extention
        }
        this.vars.layerDuplicatedKey = layerKey
        let settings = { inputs: layer.inputs }
        if (layer.hasOwnProperty('checkedValue')) {
            settings.checkedValue = layer.checkedValue
        }
        await this.insertLayer(tryNewName, layer.layerType, settings);
    }

    createLayerObject = (layerName, layerType) => {
        var layer = null
        switch (layerType) {

            case 'unitSymbol': layer = {
                name: layerName,
                key: -1,
                layerType: layerType,
                checkedValue: 'none',
                visible: 1,
                minimized: false,
                indicate: false,
                inputs: [
                    {
                        label: 'color',
                        type: 'text',
                        className: 'input-text-7',
                        name: 'color',
                        value: '#000000',
                        displayRow: 1
                    },
                    {
                        label: 'background color',
                        type: 'text',
                        className: 'input-text-7',
                        name: 'backgroundColor',
                        value: 'none',
                        displayRow: 1
                    },
                    {
                        label: 'symbol width',
                        type: 'text',
                        className: 'input-text-4',
                        name: 'symbolWidth',
                        value: 100,
                        displayRow: 2
                    },
                    {
                        label: 'symbol height',
                        type: 'text',
                        className: 'input-text-4',
                        name: 'symbolHeight',
                        value: 75,
                        displayRow: 2
                    },
                    {
                        label: 'line thickness',
                        type: 'text',
                        className: 'input-text-4',
                        name: 'lineThickness',
                        value: 2,
                        displayRow: 2
                    },

                    {
                        label: 'center x',
                        type: 'text',
                        className: 'input-text-4',
                        name: 'centerX',
                        value: 0,
                        displayRow: 3
                    },
                    {
                        label: 'center y',
                        type: 'text',
                        className: 'input-text-4',
                        name: 'centerY',
                        value: -10,
                        displayRow: 3
                    },
                    {
                        label: 'outline width',
                        type: 'text',
                        className: 'input-text-4',
                        name: 'outlineWidth',
                        value: 0,
                        displayRow: 4
                    },
                    {
                        label: 'outline color',
                        type: 'text',
                        className: 'input-text-7',
                        name: 'outlineColor',
                        value: '#FFFFFF',
                        displayRow: 4
                    },

                    {
                        label: 'opacity',
                        type: 'text',
                        className: 'input-text-3',
                        name: 'opacity',
                        value: '100',
                        displayRow: 4
                    },

                    {
                        label: 'none',
                        type: 'ButtonRadioSvg',
                        className: '',
                        name: 'unitSymbolSvg',
                        value: 'none',
                        displayRow: 5,
                    },
                    {
                        label: 'empty',
                        type: 'ButtonRadioSvg',
                        className: '',
                        name: 'unitSymbolSvg',
                        value: 'empty',
                        displayRow: 5,
                    },
                    {
                        label: 'HQ',
                        type: 'ButtonRadioSvg',
                        className: '',
                        name: 'unitSymbolSvg',
                        value: 'hq',
                        displayRow: 5,
                    },
                    {
                        label: "infantry",
                        type: 'ButtonRadioSvg',
                        className: '',
                        name: 'unitSymbolSvg',
                        value: "infantry",
                        displayRow: 5,
                    },
                    {
                        label: "motorized infantry",
                        type: 'ButtonRadioSvg',
                        className: '',
                        name: 'unitSymbolSvg',
                        value: "motorized_infantry",
                        displayRow: 5,
                    },
                    {
                        label: "motorcycle infantry",
                        type: 'ButtonRadioSvg',
                        className: '',
                        name: 'unitSymbolSvg',
                        value: "motorcycle_infantry",
                        displayRow: 5,
                    },
                    {
                        label: "mountain infantry",
                        type: 'ButtonRadioSvg',
                        className: '',
                        name: 'unitSymbolSvg',
                        value: "mountain_infantry",
                        displayRow: 6,
                    },
                    {
                        label: "fortress infantry",
                        type: 'ButtonRadioSvg',
                        className: '',
                        name: 'unitSymbolSvg',
                        value: "fortress_infantry",
                        displayRow: 6,
                    },
                    {
                        label: "armor",
                        type: 'ButtonRadioSvg',
                        className: '',
                        name: 'unitSymbolSvg',
                        value: "armor",
                        displayRow: 6,
                    },
                    {
                        label: "mechanized",
                        type: 'ButtonRadioSvg',
                        className: '',
                        name: 'unitSymbolSvg',
                        value: "mechanized",
                        displayRow: 6,
                    },
                    {
                        label: "cavalry",
                        type: 'ButtonRadioSvg',
                        className: '',
                        name: 'unitSymbolSvg',
                        value: "cavalry",
                        displayRow: 6,
                    },
                    {
                        label: "cavalry artillery",
                        type: 'ButtonRadioSvg',
                        className: '',
                        name: 'unitSymbolSvg',
                        value: "cavalry_artillery",
                        displayRow: 6,
                    },
                    {
                        label: "artillery",
                        type: 'ButtonRadioSvg',
                        className: '',
                        name: 'unitSymbolSvg',
                        value: "artillery",
                        displayRow: 6,
                    },
                    {
                        label: "motar artillery",
                        type: 'ButtonRadioSvg',
                        className: '',
                        name: 'unitSymbolSvg',
                        value: "motar_artillery",
                        displayRow: 6,
                    },
                    {
                        label: "heavy artillery",
                        type: 'ButtonRadioSvg',
                        className: '',
                        name: 'unitSymbolSvg',
                        value: "heavy_artillery",
                        displayRow: 7,
                    },
                    {
                        label: "coastal artillery",
                        type: 'ButtonRadioSvg',
                        className: '',
                        name: 'unitSymbolSvg',
                        value: "coastal_artillery",
                        displayRow: 7,
                    },
                    {
                        label: "railroad artillery",
                        type: 'ButtonRadioSvg',
                        className: '',
                        name: 'unitSymbolSvg',
                        value: "railroad_artillery",
                        displayRow: 7,
                    },

                    {
                        label: "rocket",
                        type: 'ButtonRadioSvg',
                        className: '',
                        name: 'unitSymbolSvg',
                        value: "rocket",
                        displayRow: 7,
                    },
                    {
                        label: "assault gun",
                        type: 'ButtonRadioSvg',
                        className: '',
                        name: 'unitSymbolSvg',
                        value: "assault_gun",
                        displayRow: 7,
                    },
                    {
                        label: "recon",
                        type: 'ButtonRadioSvg',
                        className: '',
                        name: 'unitSymbolSvg',
                        value: "recon",
                        displayRow: 7,
                    },
                    {
                        label: "anti tank",
                        type: 'ButtonRadioSvg',
                        className: '',
                        name: 'unitSymbolSvg',
                        value: "anti_tank",
                        displayRow: 8,
                    },
                    {
                        label: "motorized anti tank",
                        type: 'ButtonRadioSvg',
                        className: '',
                        name: 'unitSymbolSvg',
                        value: "motorized_anti_tank",
                        displayRow: 8,
                    },
                    {
                        label: "anti aircraft",
                        type: 'ButtonRadioSvg',
                        className: '',
                        name: 'unitSymbolSvg',
                        value: "anti_aircraft",
                        displayRow: 8,
                    },
                    {
                        label: "motorized anti aircraft",
                        type: 'ButtonRadioSvg',
                        className: '',
                        name: 'unitSymbolSvg',
                        value: "motorized_anti_aircraft",
                        displayRow: 8,
                    },
                    {
                        label: "engineer",
                        type: 'ButtonRadioSvg',
                        className: '',
                        name: 'unitSymbolSvg',
                        value: "engineer",
                        displayRow: 8,
                    },
                    {
                        label: "motorized engineer",
                        type: 'ButtonRadioSvg',
                        className: '',
                        name: 'unitSymbolSvg',
                        value: "motorized_engineer",
                        displayRow: 8,
                    },
                    {
                        label: "security",
                        type: 'ButtonRadioSvg',
                        className: '',
                        name: 'unitSymbolSvg',
                        value: "security",
                        displayRow: 9,
                    },
                    {
                        label: "militia",
                        type: 'ButtonRadioSvg',
                        className: '',
                        name: 'unitSymbolSvg',
                        value: "militia",
                        displayRow: 9,
                    },
                    {
                        label: "partisan",
                        type: 'ButtonRadioSvg',
                        className: '',
                        name: 'unitSymbolSvg',
                        value: "partisan",
                        displayRow: 9,
                    },
                    {
                        label: "paratroop",
                        type: 'ButtonRadioSvg',
                        className: '',
                        name: 'unitSymbolSvg',
                        value: "paratroop",
                        displayRow: 9,
                    },
                    {
                        label: "paradrop",
                        type: 'ButtonRadioSvg',
                        className: '',
                        name: 'unitSymbolSvg',
                        value: "paradrop",
                        displayRow: 9,
                    },
                    {
                        label: "glider",
                        type: 'ButtonRadioSvg',
                        className: '',
                        name: 'unitSymbolSvg',
                        value: "glider",
                        displayRow: 9,
                    },
                    {
                        label: "ranger",
                        type: 'ButtonRadioSvg',
                        className: '',
                        name: 'unitSymbolSvg',
                        value: "ranger",
                        displayRow: 10,
                    },
                    {
                        label: "ski",
                        type: 'ButtonRadioSvg',
                        className: '',
                        name: 'unitSymbolSvg',
                        value: "ski",
                        displayRow: 10,
                    },
                    {
                        label: "stryker",
                        type: 'ButtonRadioSvg',
                        className: '',
                        name: 'unitSymbolSvg',
                        value: "stryker",
                        displayRow: 10,
                    },
                ]
            }
                // assign row numbers programmatically
                let start = false
                let rowNumber = 5
                let columnCount = 0
                layer.inputs.forEach(input => {
                    if (input.label === 'none') {
                        start = true
                    }
                    if (start) {
                        input.displayRow = rowNumber
                        columnCount++
                        if (columnCount > 5) {
                            rowNumber++
                            columnCount = 0
                        }
                    }
                })
                break;

            case 'vehicle': layer = {
                name: layerName,
                key: -1,
                layerType: layerType,
                checkedValue: 'none',
                visible: 1,
                minimized: false,
                indicate: false,
                inputs: [
                    {
                        label: 'color',
                        type: 'text',
                        className: 'input-text-7',
                        name: 'color',
                        value: '#000000',
                        displayRow: 1
                    },
                    {
                        label: 'symbol width',
                        type: 'text',
                        className: 'input-text-4',
                        name: 'symbolWidth',
                        value: 100,
                        displayRow: 1
                    },
                    {
                        label: 'center x',
                        type: 'text',
                        className: 'input-text-4',
                        name: 'centerX',
                        value: 0,
                        displayRow: 1
                    },
                    {
                        label: 'center y',
                        type: 'text',
                        className: 'input-text-4',
                        name: 'centerY',
                        value: 0,
                        displayRow: 1
                    },
                    {
                        label: 'outline width',
                        type: 'text',
                        className: 'input-text-4',
                        name: 'outlineWidth',
                        value: 0,
                        displayRow: 2
                    },
                    {
                        label: 'outline color',
                        type: 'text',
                        className: 'input-text-7',
                        name: 'outlineColor',
                        value: '#FFFFFF',
                        displayRow: 2
                    },
                    {
                        label: 'reverse',
                        type: 'checkbox',
                        className: 'checkbox',
                        name: 'reverse',
                        value: false,
                        displayRow: 2
                    },
                    {
                        label: 'opacity',
                        type: 'text',
                        className: 'input-text-3',
                        name: 'opacity',
                        value: '100',
                        displayRow: 2
                    },
                    {
                        label: 'none',
                        type: 'ButtonRadioSvg',
                        className: '',
                        name: 'vehicleSvg',
                        value: 'none',
                        displayRow: 3,
                    },
                    {
                        label: "Matilda II",
                        type: 'ButtonRadioSvg',
                        className: '',
                        name: 'vehicleSvg',
                        value: "matildaII",
                        displayRow: 3,
                    },
                    {
                        label: "Valentine III",
                        type: 'ButtonRadioSvg',
                        className: '',
                        name: 'vehicleSvg',
                        value: "valentineIII",
                        displayRow: 3,
                    },
                    {
                        label: "Sherman 1944",
                        type: 'ButtonRadioSvg',
                        className: '',
                        name: 'vehicleSvg',
                        value: "sherman1944",
                        displayRow: 3,
                    },
                    {
                        label: "T34/76 1940",
                        type: 'ButtonRadioSvg',
                        className: '',
                        name: 'vehicleSvg',
                        value: "t34_76",
                        displayRow: 3,
                    },
                    {
                        label: "KV-1",
                        type: 'ButtonRadioSvg',
                        className: '',
                        name: 'vehicleSvg',
                        value: "kv1",
                        displayRow: 3,
                    },
                    {
                        label: "PanzerIII AusfB/C",
                        type: 'ButtonRadioSvg',
                        className: '',
                        name: 'vehicleSvg',
                        value: "pIII_BC",
                        displayRow: 4,
                    },
                    {
                        label: "PanzerIII AusfD",
                        type: 'ButtonRadioSvg',
                        className: '',
                        name: 'vehicleSvg',
                        value: "pIII_D",
                        displayRow: 4,
                    },
                    {
                        label: "PanzerIV AusfD",
                        type: 'ButtonRadioSvg',
                        className: '',
                        name: 'vehicleSvg',
                        value: "pIV",
                        displayRow: 4,
                    },
                    {
                        label: "Tiger Tank",
                        type: 'ButtonRadioSvg',
                        className: '',
                        name: 'vehicleSvg',
                        value: "tiger_tank",
                        displayRow: 4,
                    },



                    {
                        label: "Marder II",
                        type: 'ButtonRadioSvg',
                        className: '',
                        name: 'vehicleSvg',
                        value: "marderII",
                        displayRow: 4,
                    },
                    {
                        label: "sIG 33",
                        type: 'ButtonRadioSvg',
                        className: '',
                        name: 'vehicleSvg',
                        value: "sig_33",
                        displayRow: 4,
                    },
                    {
                        label: "Stug III AusfA",
                        type: 'ButtonRadioSvg',
                        className: '',
                        name: 'vehicleSvg',
                        value: "stugIII_a",
                        displayRow: 5,
                    },
                    {
                        label: "Stug III AusfF",
                        type: 'ButtonRadioSvg',
                        className: '',
                        name: 'vehicleSvg',
                        value: "stugIII_f",
                        displayRow: 5,
                    },
                    {
                        label: "Stug III AusfG",
                        type: 'ButtonRadioSvg',
                        className: '',
                        name: 'vehicleSvg',
                        value: "stugIII_g",
                        displayRow: 5,
                    },
                    {
                        label: "Truck",
                        type: 'ButtonRadioSvg',
                        className: '',
                        name: 'vehicleSvg',
                        value: "truck",
                        displayRow: 5,
                    },
                ]
            }
                break;

            case 'aircraft': layer = {
                name: layerName,
                key: -1,
                layerType: layerType,
                checkedValue: 'none',
                visible: 1,
                minimized: false,
                indicate: false,
                inputs: [
                    {
                        label: 'color',
                        type: 'text',
                        className: 'input-text-7',
                        name: 'color',
                        value: '#000000',
                        displayRow: 1
                    },
                    {
                        label: 'symbol width',
                        type: 'text',
                        className: 'input-text-4',
                        name: 'symbolWidth',
                        value: 100,
                        displayRow: 1
                    },
                    {
                        label: 'center x',
                        type: 'text',
                        className: 'input-text-4',
                        name: 'centerX',
                        value: 0,
                        displayRow: 1
                    },
                    {
                        label: 'center y',
                        type: 'text',
                        className: 'input-text-4',
                        name: 'centerY',
                        value: 0,
                        displayRow: 1
                    },
                    {
                        label: 'outline width',
                        type: 'text',
                        className: 'input-text-4',
                        name: 'outlineWidth',
                        value: 0,
                        displayRow: 2
                    },
                    {
                        label: 'outline color',
                        type: 'text',
                        className: 'input-text-7',
                        name: 'outlineColor',
                        value: '#FFFFFF',
                        displayRow: 2
                    },
                    {
                        label: 'reverse',
                        type: 'checkbox',
                        className: 'checkbox',
                        name: 'reverse',
                        value: false,
                        displayRow: 2
                    },
                    {
                        label: 'none',
                        type: 'ButtonRadioSvg',
                        className: '',
                        name: 'aircraftSvg',
                        value: 'none',
                        displayRow: 3,
                    },
                    {
                        label: "ME109",
                        type: 'ButtonRadioSvg',
                        className: '',
                        name: 'aircraftSvg',
                        value: "ME109",
                        displayRow: 3,
                    },

                ]
            }
                break;

            // unit size
            case 'unitSize':
                layer = {
                    name: layerName,
                    key: -1,
                    layerType: layerType,
                    checkedValue: 'none',
                    visible: 1,
                    minimized: false,
                    indicate: false,
                    inputs: [
                        {
                            label: 'font',
                            type: 'select',
                            className: 'select-css',
                            name: 'fontFamily',
                            value: 'sans-serif',
                            displayRow: 1
                        },
                        {
                            label: 'font size',
                            type: 'text',
                            className: 'input-text-3',
                            name: 'fontSize',
                            value: 8,
                            displayRow: 2
                        },
                        {
                            label: 'color',
                            type: 'text',
                            className: 'input-text-7',
                            name: 'fontColor',
                            value: '#000000',
                            displayRow: 2
                        },
                        {
                            label: 'opacity',
                            type: 'text',
                            className: 'input-text-3',
                            name: 'opacity',
                            value: '100',
                            displayRow: 2
                        },
                        {
                            label: 'center x',
                            type: 'text',
                            className: 'input-text-4',
                            name: 'centerX',
                            value: 0,
                            displayRow: 3
                        },
                        {
                            label: 'center y',
                            type: 'text',
                            className: 'input-text-4',
                            name: 'centerY',
                            value: -60,
                            displayRow: 3
                        },
                        {
                            label: 'bold',
                            type: 'checkbox',
                            className: 'checkbox',
                            name: 'bold',
                            value: true,
                            displayRow: 3
                        },
                        {
                            label: 'italic',
                            type: 'checkbox',
                            className: 'checkbox',
                            name: 'italic',
                            value: false,
                            displayRow: 3
                        },
                        {
                            label: 'outline width',
                            type: 'text',
                            className: 'input-text-3',
                            name: 'outlineWidth',
                            value: 0,
                            displayRow: 4
                        },
                        {
                            label: 'outline color',
                            type: 'text',
                            className: 'input-text-7',
                            name: 'outlineColor',
                            value: '#FFFFFF',
                            displayRow: 4
                        },
                        {
                            label: 'none',
                            type: 'ButtonRadioSvg',
                            className: '',
                            name: 'unitSizeSvg',
                            value: 'unitSize_none',
                            displayRow: 5,
                        },
                        {
                            label: "installation",
                            type: 'ButtonRadioSvg',
                            className: '',
                            name: 'unitSizeSvg',
                            value: "unitSize_installation",
                            displayRow: 5,
                        },
                        {
                            label: "team/crew",
                            type: 'ButtonRadioSvg',
                            className: '',
                            name: 'unitSizeSvg',
                            value: "unitSize_team/crew",
                            displayRow: 5,
                        },
                        {
                            label: "squad",
                            type: 'ButtonRadioSvg',
                            className: '',
                            name: 'unitSizeSvg',
                            value: "unitSize_squad",
                            displayRow: 5,
                        },
                        {
                            label: "section",
                            type: 'ButtonRadioSvg',
                            className: '',
                            name: 'unitSizeSvg',
                            value: "unitSize_section",
                            displayRow: 5,
                        },
                        {
                            label: "platoon",
                            type: 'ButtonRadioSvg',
                            className: '',
                            name: 'unitSizeSvg',
                            value: "unitSize_platoon",
                            displayRow: 5,
                        },

                        {
                            label: "company",
                            type: 'ButtonRadioSvg',
                            className: '',
                            name: 'unitSizeSvg',
                            value: "unitSize_company",
                            displayRow: 6,
                        },
                        {
                            label: "battalion",
                            type: 'ButtonRadioSvg',
                            className: '',
                            name: 'unitSizeSvg',
                            value: "unitSize_battalion",
                            displayRow: 6,
                        },
                        {
                            label: "regiment",
                            type: 'ButtonRadioSvg',
                            className: '',
                            name: 'unitSizeSvg',
                            value: "unitSize_regiment",
                            displayRow: 6,
                        },
                        {
                            label: "brigade",
                            type: 'ButtonRadioSvg',
                            className: '',
                            name: 'unitSizeSvg',
                            value: "unitSize_brigade",
                            displayRow: 6,
                        },
                        {
                            label: "division",
                            type: 'ButtonRadioSvg',
                            className: '',
                            name: 'unitSizeSvg',
                            value: "unitSize_division",
                            displayRow: 6,
                        },
                        {
                            label: "corps",
                            type: 'ButtonRadioSvg',
                            className: '',
                            name: 'unitSizeSvg',
                            value: "unitSize_corps",
                            displayRow: 6,
                        },
                        {
                            label: "army",
                            type: 'ButtonRadioSvg',
                            className: '',
                            name: 'unitSizeSvg',
                            value: "unitSize_army",
                            displayRow: 7,
                        },
                        {
                            label: "army group",
                            type: 'ButtonRadioSvg',
                            className: '',
                            name: 'unitSizeSvg',
                            value: "unitSize_armyGroup",
                            displayRow: 7,
                        },
                    ]
                }
                break;

            case 'images': layer = {
                name: layerName,
                key: -1,
                layerType: layerType,
                checkedValue: 'none',
                visible: 1,
                minimized: false,
                indicate: false,
                inputs: [
                    {
                        label: 'symbol width',
                        type: 'text',
                        className: 'input-text-4',
                        name: 'symbolWidth',
                        value: 100,
                        displayRow: 1
                    },
                    {
                        label: 'center x',
                        type: 'text',
                        className: 'input-text-4',
                        name: 'centerX',
                        value: 0,
                        displayRow: 1
                    },
                    {
                        label: 'center y',
                        type: 'text',
                        className: 'input-text-4',
                        name: 'centerY',
                        value: 0,
                        displayRow: 1
                    },
                    {
                        label: 'rotation (deg)',
                        type: 'text',
                        className: 'input-text-4',
                        name: 'rotation',
                        value: 0,
                        displayRow: 2
                    },
                    {
                        label: 'reverse',
                        type: 'checkbox',
                        className: 'checkbox',
                        name: 'reverse',
                        value: false,
                        displayRow: 2
                    },
                    {
                        label: 'opacity',
                        type: 'text',
                        className: 'input-text-3',
                        name: 'opacity',
                        value: '100',
                        displayRow: 2
                    },
                    {
                        label: 'none',
                        type: 'ButtonRadioSvg',
                        className: '',
                        name: 'imagesSvg',
                        value: 'none',
                        displayRow: 3,
                    },
                    {
                        label: "Air Strike German",
                        type: 'ButtonRadioSvg',
                        className: '',
                        name: 'imagesSvg',
                        value: "airStrike",
                        displayRow: 3,
                    },
                    {
                        label: "Air Strike Soviet",
                        type: 'ButtonRadioSvg',
                        className: '',
                        name: 'imagesSvg',
                        value: "airStrikeSoviet",
                        displayRow: 3,
                    },
                    {
                        label: "Axis CAP",
                        type: 'ButtonRadioSvg',
                        className: '',
                        name: 'imagesSvg',
                        value: "capAxis",
                        displayRow: 3,
                    },
                    {
                        label: "Soviet CAP",
                        type: 'ButtonRadioSvg',
                        className: '',
                        name: 'imagesSvg',
                        value: "capSoviet",
                        displayRow: 3,
                    },
                    {
                        label: "US CAP",
                        type: 'ButtonRadioSvg',
                        className: '',
                        name: 'imagesSvg',
                        value: "capUS",
                        displayRow: 3,
                    },
                    {
                        label: "Churchill",
                        type: 'ButtonRadioSvg',
                        className: '',
                        name: 'imagesSvg',
                        value: "churchillImage",
                        displayRow: 4,
                    },
                    {
                        label: "Medieval Knight",
                        type: 'ButtonRadioSvg',
                        className: '',
                        name: 'imagesSvg',
                        value: "medievalKnightImage",
                        displayRow: 4,
                    },
                    {
                        label: "Uboot Type VIII",
                        type: 'ButtonRadioSvg',
                        className: '',
                        name: 'imagesSvg',
                        value: "ubootTypeVIII",
                        displayRow: 4,
                    },
                    {
                        label: "Bismarck",
                        type: 'ButtonRadioSvg',
                        className: '',
                        name: 'imagesSvg',
                        value: "bismarck",
                        displayRow: 4,
                    },
                    {
                        label: "Konigsburg",
                        type: 'ButtonRadioSvg',
                        className: '',
                        name: 'imagesSvg',
                        value: "konigsburg",
                        displayRow: 4,
                    },
                ]
            }
                break;

            case 'symbol': layer = {
                name: layerName,
                key: -1,
                layerType: layerType,
                checkedValue: 'none',
                visible: 1,
                minimized: false,
                indicate: false,
                inputs: [
                    {
                        label: 'color',
                        type: 'text',
                        className: 'input-text-7',
                        name: 'color',
                        value: '#000000',
                        displayRow: 1
                    },
                    {
                        label: 'symbol width',
                        type: 'text',
                        className: 'input-text-4',
                        name: 'symbolWidth',
                        value: 100,
                        displayRow: 1
                    },
                    {
                        label: 'line thickness',
                        type: 'text',
                        className: 'input-text-4',
                        name: 'lineThickness',
                        value: 1,
                        displayRow: 1
                    },
                    {
                        label: 'center x',
                        type: 'text',
                        className: 'input-text-4',
                        name: 'centerX',
                        value: 0,
                        displayRow: 2
                    },
                    {
                        label: 'center y',
                        type: 'text',
                        className: 'input-text-4',
                        name: 'centerY',
                        value: 0,
                        displayRow: 2
                    },
                    {
                        label: 'rotation (deg)',
                        type: 'text',
                        className: 'input-text-4',
                        name: 'rotation',
                        value: 0,
                        displayRow: 2
                    },
                    {
                        label: 'opacity',
                        type: 'text',
                        className: 'input-text-3',
                        name: 'opacity',
                        value: '100',
                        displayRow: 2
                    },
                    {
                        label: '2nd color',
                        type: 'text',
                        className: 'input-text-7',
                        name: 'secondColor',
                        value: '',
                        displayRow: 3
                    },
                    {
                        label: 'outline width',
                        type: 'text',
                        className: 'input-text-4',
                        name: 'outlineWidth',
                        value: 0,
                        displayRow: 3
                    },
                    {
                        label: 'outline color',
                        type: 'text',
                        className: 'input-text-7',
                        name: 'outlineColor',
                        value: '#FFFFFF',
                        displayRow: 3
                    },
                    {
                        label: 'none',
                        type: 'ButtonRadioSvg',
                        className: '',
                        name: 'symbolSvg',
                        value: 'none',
                        displayRow: 4,
                    },
                    {
                        label: "iron cross",
                        type: 'ButtonRadioSvg',
                        className: '',
                        name: 'symbolSvg',
                        value: "ironCross",
                        displayRow: 4,
                    },
                    {
                        label: "star",
                        type: 'ButtonRadioSvg',
                        className: '',
                        name: 'symbolSvg',
                        value: "star",
                        displayRow: 4,
                    },
                    {
                        label: "naval",
                        type: 'ButtonRadioSvg',
                        className: '',
                        name: 'symbolSvg',
                        value: "naval",
                        displayRow: 4,
                    },
                    {
                        label: "entrench",
                        type: 'ButtonRadioSvg',
                        className: '',
                        name: 'symbolSvg',
                        value: "entrench",
                        displayRow: 4,
                    },
                    {
                        label: "paradrop",
                        type: 'ButtonRadioSvg',
                        className: '',
                        name: 'symbolSvg',
                        value: "paradropSymbol",
                        displayRow: 4,
                    },
                    {
                        label: "hexagon",
                        type: 'ButtonRadioSvg',
                        className: '',
                        name: 'symbolSvg',
                        value: "hexagonSymbol",
                        displayRow: 5,
                    },
                    {
                        label: "skull bones",
                        type: 'ButtonRadioSvg',
                        className: '',
                        name: 'symbolSvg',
                        value: "skullBonesSymbol",
                        displayRow: 5,
                    },
                    {
                        label: "Wehrmacht cross",
                        type: 'ButtonRadioSvg',
                        className: '',
                        name: 'symbolSvg',
                        value: "wehrmachtSymbol",
                        displayRow: 5,
                    },
                    {
                        label: "Barrel",
                        type: 'ButtonRadioSvg',
                        className: '',
                        name: 'symbolSvg',
                        value: "barrelSymbol",
                        displayRow: 5,
                    },
                    {
                        label: "Explosion",
                        type: 'ButtonRadioSvg',
                        className: '',
                        name: 'symbolSvg',
                        value: "explosionSymbol",
                        displayRow: 5,
                    },
                    {
                        label: "Construction",
                        type: 'ButtonRadioSvg',
                        className: '',
                        name: 'symbolSvg',
                        value: "constructionSymbol",
                        displayRow: 5,
                    },
                    {
                        label: "Bridge Out",
                        type: 'ButtonRadioSvg',
                        className: '',
                        name: 'symbolSvg',
                        value: "bridgeOutSymbol",
                        displayRow: 6,
                    },
                    {
                        label: "Bridgehead",
                        type: 'ButtonRadioSvg',
                        className: '',
                        name: 'symbolSvg',
                        value: "bridgeheadSymbol",
                        displayRow: 6,
                    },
                    {
                        label: "Hedgehog",
                        type: 'ButtonRadioSvg',
                        className: '',
                        name: 'symbolSvg',
                        value: "hedgehogSymbol",
                        displayRow: 6,
                    },
                    {
                        label: "Rail End",
                        type: 'ButtonRadioSvg',
                        className: '',
                        name: 'symbolSvg',
                        value: "railEndSymbol",
                        displayRow: 6,
                    },
                    {
                        label: "Oil Rig",
                        type: 'ButtonRadioSvg',
                        className: '',
                        name: 'symbolSvg',
                        value: "oilRigSymbol",
                        displayRow: 6,
                    },
                    {
                        label: "Factory",
                        type: 'ButtonRadioSvg',
                        className: '',
                        name: 'symbolSvg',
                        value: "factorySymbol",
                        displayRow: 6,
                    },
                    {
                        label: "Police",
                        type: 'ButtonRadioSvg',
                        className: '',
                        name: 'symbolSvg',
                        value: "policeSymbol",
                        displayRow: 7,
                    },
                    {
                        label: "Cannon",
                        type: 'ButtonRadioSvg',
                        className: '',
                        name: 'symbolSvg',
                        value: "cannonSymbol",
                        displayRow: 7,
                    },
                    // {
                    //     label: "fort",
                    //     type: 'ButtonRadioSvg',
                    //     className: '',
                    //     name: 'symbolSvg',
                    //     value: "fort",
                    //     displayRow: 4,
                    // },
                    // {
                    //     label: "fort2",
                    //     type: 'ButtonRadioSvg',
                    //     className: '',
                    //     name: 'symbolSvg',
                    //     value: "fort2",
                    //     displayRow: 4,
                    // },

                ]
            }
                break;

            case 'radio': layer = {
                name: layerName,
                key: -1,
                layerType: layerType,
                checkedValue: '25.4mm',
                visible: 1,
                inputs: [
                    {
                        label: '12.7mm (1/2 inch)',
                        type: 'radio',
                        className: '',
                        name: 'counterSize',
                        value: '12.7mm',
                        displayRow: 1,
                    },
                    {
                        label: '15.875mm (5/8 inch)',
                        type: 'radio',
                        className: '',
                        name: 'counterSize',
                        value: '15.875mm',
                        displayRow: 1,
                    },

                    {
                        label: '19mm ( 3/4 inch )',
                        type: 'radio',
                        className: '',
                        name: 'counterSize',
                        value: '19mm',
                        displayRow: 2,
                    },

                    {
                        label: '25.4mm ( 1 inch )',
                        type: 'radio',
                        className: '',
                        name: 'counterSize',
                        value: '25.4mm',
                        displayRow: 2,
                    },

                ]
            }
                break;

            case 'counterBackgroundColor': layer = {
                name: layerName,
                key: -1,
                layerType: 'counterBackgroundColor',
                visible: 1,
                minimized: false,
                indicate: false,
                inputs: [
                    {
                        label: 'color',
                        type: 'text',
                        className: 'input-text-7',
                        name: 'color',
                        value: '#4455dd',
                        displayRow: 1
                    },
                    {
                        label: 'bleed outside counter',
                        type: 'checkbox',
                        className: 'checkbox',
                        name: 'bleed',
                        value: false,
                        displayRow: 1
                    },
                    {
                        label: 'bleed amount (1%-10%)',
                        type: 'text',
                        className: 'input-text-4',
                        name: 'bleedAmount',
                        value: 0,
                        displayRow: 1
                    },
                ]
            }
                break;

            case 'circle': layer = {
                name: layerName,
                key: -1,
                layerType: 'circle',
                visible: 1,
                minimized: false,
                indicate: false,
                inputs: [

                    {
                        label: 'center x',
                        type: 'text',
                        className: 'input-text-4',
                        name: 'centerX',
                        value: 0,
                        displayRow: 1
                    },
                    {
                        label: 'center y',
                        type: 'text',
                        className: 'input-text-4',
                        name: 'centerY',
                        value: 0,
                        displayRow: 1
                    },
                    {
                        label: 'fill color',
                        type: 'text',
                        className: 'input-text-7',
                        name: 'fillColor',
                        value: '#FFFF00',
                        displayRow: 1
                    },
                    {
                        label: 'radius',
                        type: 'text',
                        className: 'input-text-3',
                        name: 'radius',
                        value: 10,
                        displayRow: 1
                    },
                    {
                        label: 'opacity',
                        type: 'text',
                        className: 'input-text-3',
                        name: 'opacity',
                        value: '100',
                        displayRow: 2
                    },

                    {
                        label: 'outline width',
                        type: 'text',
                        className: 'input-text-3',
                        name: 'outlineWidth',
                        value: 0,
                        displayRow: 2
                    },
                    {
                        label: 'outline color',
                        type: 'text',
                        className: 'input-text-7',
                        name: 'outlineColor',
                        value: '#FFFFFF',
                        displayRow: 2
                    },
                ]
            }
                break;

            case 'ellipse': layer = {
                name: layerName,
                key: -1,
                layerType: 'ellipse',
                visible: 1,
                minimized: false,
                indicate: false,
                inputs: [
                    {
                        label: 'center x',
                        type: 'text',
                        className: 'input-text-4',
                        name: 'centerX',
                        value: 0,
                        displayRow: 1
                    },
                    {
                        label: 'center y',
                        type: 'text',
                        className: 'input-text-4',
                        name: 'centerY',
                        value: 0,
                        displayRow: 1
                    },
                    {
                        label: 'fill color',
                        type: 'text',
                        className: 'input-text-7',
                        name: 'fillColor',
                        value: '#00FF00',
                        displayRow: 1
                    },
                    {
                        label: 'opacity',
                        type: 'text',
                        className: 'input-text-3',
                        name: 'opacity',
                        value: '100',
                        displayRow: 1
                    },
                    {
                        label: 'horizontal radius',
                        type: 'text',
                        className: 'input-text-7',
                        name: 'horizontalRadius',
                        value: 50,
                        displayRow: 2
                    },
                    {
                        label: 'vertical radius',
                        type: 'text',
                        className: 'input-text-3',
                        name: 'verticalRadius',
                        value: 30,
                        displayRow: 2
                    },

                    {
                        label: 'outline width',
                        type: 'text',
                        className: 'input-text-3',
                        name: 'outlineWidth',
                        value: 0,
                        displayRow: 3
                    },
                    {
                        label: 'outline color',
                        type: 'text',
                        className: 'input-text-7',
                        name: 'outlineColor',
                        value: '#FFFFFF',
                        displayRow: 3
                    },
                    {
                        label: 'rotation (deg)',
                        type: 'text',
                        className: 'input-text-4',
                        name: 'rotation',
                        value: 0,
                        displayRow: 3
                    },
                ]
            }
                break;

            case 'rectangle': layer = {
                name: layerName,
                key: -1,
                layerType: 'rectangle',
                visible: 1,
                minimized: false,
                indicate: false,
                inputs: [
                    {
                        label: 'center x',
                        type: 'text',
                        className: 'input-text-4',
                        name: 'centerX',
                        value: 0,
                        displayRow: 1
                    },
                    {
                        label: 'center y',
                        type: 'text',
                        className: 'input-text-4',
                        name: 'centerY',
                        value: 0,
                        displayRow: 1
                    },
                    {
                        label: 'width',
                        type: 'text',
                        className: 'input-text-3',
                        name: 'width',
                        value: 100,
                        displayRow: 1
                    },
                    {
                        label: 'height',
                        type: 'text',
                        className: 'input-text-3',
                        name: 'height',
                        value: 30,
                        displayRow: 1
                    },

                    {
                        label: 'fill color',
                        type: 'text',
                        className: 'input-text-7',
                        name: 'fillColor',
                        value: '#0000FF',
                        displayRow: 2
                    },

                    {
                        label: 'opacity',
                        type: 'text',
                        className: 'input-text-3',
                        name: 'opacity',
                        value: '100',
                        displayRow: 2
                    },

                    {
                        label: 'round corner x',
                        type: 'text',
                        className: 'input-text-3',
                        name: 'roundCornerX',
                        value: 0,
                        displayRow: 3
                    },
                    {
                        label: 'round corner y',
                        type: 'text',
                        className: 'input-text-3',
                        name: 'roundCornerY',
                        value: 0,
                        displayRow: 3
                    },


                    {
                        label: 'outline width',
                        type: 'text',
                        className: 'input-text-3',
                        name: 'outlineWidth',
                        value: 0,
                        displayRow: 4
                    },
                    {
                        label: 'outline color',
                        type: 'text',
                        className: 'input-text-7',
                        name: 'outlineColor',
                        value: '#FFFFFF',
                        displayRow: 4
                    },
                    {
                        label: 'rotation (deg)',
                        type: 'text',
                        className: 'input-text-4',
                        name: 'rotation',
                        value: 0,
                        displayRow: 4
                    },
                ]
            }
                break;

            case 'scaleneTriangle': layer = {
                name: layerName,
                key: -1,
                layerType: 'scaleneTriangle',
                visible: 1,
                minimized: false,
                indicate: false,
                inputs: [
                    {
                        label: 'center x',
                        type: 'text',
                        className: 'input-text-4',
                        name: 'centerX',
                        value: 0,
                        displayRow: 1
                    },
                    {
                        label: 'center y',
                        type: 'text',
                        className: 'input-text-4',
                        name: 'centerY',
                        value: 0,
                        displayRow: 1
                    },
                    {
                        label: 'color',
                        type: 'text',
                        className: 'input-text-7',
                        name: 'color',
                        value: '#00FFFF',
                        displayRow: 1
                    },
                    {
                        label: 'radius',
                        type: 'text',
                        className: 'input-text-3',
                        name: 'radius',
                        value: 80,
                        displayRow: 2
                    },
                    {
                        label: 'round corners',
                        type: 'text',
                        className: 'input-text-3',
                        name: 'roundCorners',
                        value: 0,
                        displayRow: 2
                    },

                    {
                        label: 'opacity',
                        type: 'text',
                        className: 'input-text-3',
                        name: 'opacity',
                        value: '100',
                        displayRow: 2
                    },

                    {
                        label: 'outline width',
                        type: 'text',
                        className: 'input-text-3',
                        name: 'outlineWidth',
                        value: 0,
                        displayRow: 3
                    },
                    {
                        label: 'outline color',
                        type: 'text',
                        className: 'input-text-7',
                        name: 'outlineColor',
                        value: '#FFFFFF',
                        displayRow: 3
                    },
                    {
                        label: 'rotation (deg)',
                        type: 'text',
                        className: 'input-text-4',
                        name: 'rotation',
                        value: 0,
                        displayRow: 3
                    },
                ]
            }
                break;



            case 'isoTriangle': layer = {
                name: layerName,
                key: -1,
                layerType: 'isoTriangle',
                visible: 1,
                minimized: false,
                indicate: false,
                inputs: [
                    {
                        label: 'center x',
                        type: 'text',
                        className: 'input-text-4',
                        name: 'centerX',
                        value: 0,
                        displayRow: 1
                    },
                    {
                        label: 'center y',
                        type: 'text',
                        className: 'input-text-4',
                        name: 'centerY',
                        value: 0,
                        displayRow: 1
                    },
                    {
                        label: 'color',
                        type: 'text',
                        className: 'input-text-7',
                        name: 'color',
                        value: '#00FFFF',
                        displayRow: 1
                    },
                    {
                        label: 'length',
                        type: 'text',
                        className: 'input-text-4',
                        name: 'length',
                        value: 80,
                        displayRow: 2
                    },
                    {
                        label: 'round corners',
                        type: 'text',
                        className: 'input-text-3',
                        name: 'roundCorners',
                        value: 0,
                        displayRow: 2
                    },

                    {
                        label: 'opacity',
                        type: 'text',
                        className: 'input-text-3',
                        name: 'opacity',
                        value: '100',
                        displayRow: 2
                    },

                    {
                        label: 'outline width',
                        type: 'text',
                        className: 'input-text-3',
                        name: 'outlineWidth',
                        value: 0,
                        displayRow: 3
                    },
                    {
                        label: 'outline color',
                        type: 'text',
                        className: 'input-text-7',
                        name: 'outlineColor',
                        value: '#FFFFFF',
                        displayRow: 3
                    },
                    {
                        label: 'rotation (deg)',
                        type: 'text',
                        className: 'input-text-4',
                        name: 'rotation',
                        value: 0,
                        displayRow: 3
                    },
                ]
            }
                break;

            case 'line':
                layer = {
                    name: layerName,
                    key: -1,
                    layerType: 'line',
                    visible: 1,
                    minimized: false,
                    indicate: false,
                    inputs: [
                        {
                            label: 'start x',
                            type: 'text',
                            className: 'input-text-4',
                            name: 'startX',
                            value: -30,
                            displayRow: 1
                        },
                        {
                            label: 'start y',
                            type: 'text',
                            className: 'input-text-4',
                            name: 'startY',
                            value: 0,
                            displayRow: 1
                        },
                        {
                            label: 'end x',
                            type: 'text',
                            className: 'input-text-4',
                            name: 'endX',
                            value: 30,
                            displayRow: 1
                        },
                        {
                            label: 'end y',
                            type: 'text',
                            className: 'input-text-4',
                            name: 'endY',
                            value: 0,
                            displayRow: 1
                        },

                        {
                            label: 'center x',
                            type: 'text',
                            className: 'input-text-4',
                            name: 'centerX',
                            value: 0,
                            hidden: true,
                            displayRow: 4
                        },
                        {
                            label: 'center y',
                            type: 'text',
                            className: 'input-text-4',
                            name: 'centerY',
                            value: 0,
                            hidden: true,
                            displayRow: 4
                        },

                        {
                            label: 'width',
                            type: 'text',
                            className: 'input-text-3',
                            name: 'width',
                            value: 3,
                            displayRow: 2
                        },
                        {
                            label: 'line cap',
                            type: 'select',
                            className: 'select-css',
                            name: 'lineCap',
                            value: 'butt',
                            displayRow: 2
                        },

                        {
                            label: 'color',
                            type: 'text',
                            className: 'input-text-7',
                            name: 'lineColor',
                            value: '#000000',
                            displayRow: 2
                        },
                        {
                            label: 'opacity',
                            type: 'text',
                            className: 'input-text-3',
                            name: 'opacity',
                            value: '100',
                            displayRow: 2
                        },
                        {
                            label: 'outline width',
                            type: 'text',
                            className: 'input-text-3',
                            name: 'outlineWidth',
                            value: 0,
                            displayRow: 3
                        },
                        {
                            label: 'outline color',
                            type: 'text',
                            className: 'input-text-7',
                            name: 'outlineColor',
                            value: '#FFFFFF',
                            displayRow: 3
                        },
                    ]
                }
                break;

            case 'text':
                let fontFamilyValue = 'sans-serif'
                let fontSizeValue = 10
                let centerXvalue = 0
                let centerYvalue = 0
                let mainValueClassName = 'input-text-12'
                let outlineWidthValue = 0
                if (layerName === 'Combat') {
                    fontFamilyValue = 'Staatliches'
                    fontSizeValue = 24
                    centerXvalue = -60
                    centerYvalue = 66
                    mainValueClassName = 'input-text-3'
                    outlineWidthValue = 2
                }
                if (layerName === 'Defense') {
                    fontFamilyValue = 'Staatliches'
                    fontSizeValue = 24
                    centerXvalue = 0
                    centerYvalue = 66
                    mainValueClassName = 'input-text-3'
                    outlineWidthValue = 2
                }
                if (layerName === 'Movement') {
                    fontFamilyValue = 'Staatliches'
                    fontSizeValue = 24
                    centerXvalue = 60
                    centerYvalue = 66
                    mainValueClassName = 'input-text-3'
                    outlineWidthValue = 2
                }
                if (layerName === 'Movement') {
                    fontFamilyValue = 'Staatliches'
                    fontSizeValue = 24
                    centerXvalue = 60
                    centerYvalue = 66
                    mainValueClassName = 'input-text-3'
                    outlineWidthValue = 2
                }
                if (layerName === 'Rating') {
                    fontFamilyValue = 'sans-serif'
                    fontSizeValue = 12
                    centerXvalue = -73
                    centerYvalue = -11
                    mainValueClassName = 'input-text-3'
                    outlineWidthValue = 1
                }
                if (layerName === 'Identifier') {
                    fontFamilyValue = 'sans-serif'
                    fontSizeValue = 10
                    centerXvalue = 0
                    centerYvalue = -78
                    mainValueClassName = 'input-text-12'
                }

                layer = {
                    name: layerName,
                    key: -1,
                    layerType: 'text',
                    visible: 1,
                    minimized: false,
                    indicate: false,
                    inputs: [
                        {
                            label: layerName,
                            type: 'text',
                            className: mainValueClassName,
                            name: 'text',
                            value: '',
                            displayRow: 1
                        },
                        {
                            label: 'font',
                            type: 'select',
                            className: 'select-css',
                            name: 'fontFamily',
                            value: fontFamilyValue,
                            displayRow: 1
                        },
                        {
                            label: 'font size',
                            type: 'text',
                            className: 'input-text-3',
                            name: 'fontSize',
                            value: fontSizeValue,
                            displayRow: 2
                        },
                        {
                            label: 'color',
                            type: 'text',
                            className: 'input-text-7',
                            name: 'fontColor',
                            value: '#000000',
                            displayRow: 2
                        },
                        {
                            label: 'opacity',
                            type: 'text',
                            className: 'input-text-3',
                            name: 'opacity',
                            value: '100',
                            displayRow: 2
                        },
                        {
                            label: 'center x',
                            type: 'text',
                            className: 'input-text-4',
                            name: 'centerX',
                            value: centerXvalue,
                            displayRow: 3
                        },
                        {
                            label: 'center y',
                            type: 'text',
                            className: 'input-text-4',
                            name: 'centerY',
                            value: centerYvalue,
                            displayRow: 3
                        },
                        {
                            label: 'bold',
                            type: 'checkbox',
                            className: 'checkbox',
                            name: 'bold',
                            value: false,
                            displayRow: 3
                        },
                        {
                            label: 'italic',
                            type: 'checkbox',
                            className: 'checkbox',
                            name: 'italic',
                            value: false,
                            displayRow: 3
                        },
                        {
                            label: 'outline width',
                            type: 'text',
                            className: 'input-text-3',
                            name: 'outlineWidth',
                            value: outlineWidthValue,
                            displayRow: 4
                        },
                        {
                            label: 'outline color',
                            type: 'text',
                            className: 'input-text-7',
                            name: 'outlineColor',
                            value: '#FFFFFF',
                            displayRow: 4
                        },
                        {
                            label: 'rotation (deg)',
                            type: 'text',
                            className: 'input-text-4',
                            name: 'rotation',
                            value: 0,
                            displayRow: 4
                        },
                    ]
                }
                break;
            default: ;
        }

        return layer
    }

    upsertLayer = async (layerName, layerType, settings = { inputs: [] }) => {
        let existingLayer = this.props.layers.find(layer => layer.name === layerName && layer.layerType === layerType)
        if (existingLayer) {
            await this.updateLayer(existingLayer, settings)
        }
        else {
            await this.insertLayer(layerName, layerType, settings)
        }
    }

    insertLayer = (layerName, layerType, settings = { inputs: [] }) => {
        return new Promise((resolve, reject) => {
            let layer = this.createLayerObject(layerName, layerType)
            // set the settings
            if (settings.inputs.length > 0) {
                for (var i = 0; i < settings.inputs.length; i++) {
                    for (var n = 0; n < layer.inputs.length; n++) {
                        if (settings.inputs[i].name === layer.inputs[n].name &&
                            settings.inputs[i].type === layer.inputs[n].type) {
                            layer.inputs[i] = { ...layer.inputs[i], ...settings.inputs[i] }
                        }
                    }
                }
            }
            if (settings.hasOwnProperty('checkedValue')) {
                layer.checkedValue = settings.checkedValue
            }
            IndexedDb.put('layer', layer).then(async (key) => {
                layer.key = key
                await IndexedDb.put('layer', layer, layer.key).then(() => this.props.dbLayerUpdated());
                resolve()
            })

        })
    }

    updateLayer = async (layer, settings) => {
        return new Promise(async (resolve, reject) => {
            if (settings.inputs.length > 0) {
                //layer.inputs = [...layer.inputs, settings.inputs]
                for (var i = 0; i < settings.inputs.length; i++) {
                    for (var n = 0; n < layer.inputs.length; n++) {
                        if (settings.inputs[i].name === layer.inputs[n].name &&
                            settings.inputs[i].type === layer.inputs[n].type) {
                            layer.inputs[i] = { ...layer.inputs[i], ...settings.inputs[i] }
                        }
                    }
                }
            }
            if (settings.hasOwnProperty('checkedValue')) {
                layer.checkedValue = settings.checkedValue
            }

            await IndexedDb.put('layer', layer, layer.key).then(() => this.props.dbLayerUpdated());
            resolve()
        })
    }

    render = () => {
        return (
            <div className="layers">
                {this.props.layers.length > 0 ? this.props.layers.map((layer, index) => layer ?
                    <Layer
                        key={index}
                        layer={layer}
                        focusOn={this.focusOn}
                        dbLayerUpdated={this.props.dbLayerUpdated}
                        colorPicked={this.state.colorPicked}
                        eventsHistoryPush={this.props.eventsHistoryPush}
                        eventsHistoryGet={this.props.eventsHistoryGet}
                        duplicateLayer={this.duplicateLayer}
                        deleteLayerByName={this.props.deleteLayerByName}
                        layerReorder={this.props.layerReorder}
                        indicateLayer={this.state.indicateLayerKey === layer.key}
                        logMessage={this.props.logMessage}
                    /> : '') : ''}
            </div>
        )
    }
}