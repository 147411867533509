import React, { Component } from 'react';
//import FontMetrics from 'fontmetrics';
import Snap from 'snapsvg-cjs';
import Utility from '../../Utility';
import IndexedDb from '../../IndexedDb';

import './PageControl.scss';

export default class PageControl extends Component {

    state = {
        paper: null,
        gridSetRef: null,
        slots: [],
        defs: [],
        maxCountersOnSheet: 0,
        slotCurrentlySelected: 0,
    }

    vars = {
        loadingSheet: false,
        gridRefs: [],
        occupiedSlotsDrawn: [],
        slots: [],
        pageParams: { counterSize: 0, marginLeft: 4, marginRight: 4, marginTop: 3.3, marginBottom: 3.3, spacingX: 0, spacingY: 0 },
        counterMouseActivity: { slotId: '', clicked: false, dragging: false, dropped: false },
        selectedCounterElements: [],
        countersToDraw: null,

    };

    cancelCounterMouseActivity = () => {
        this.setCounterMouseActivity()
    }

    setCounterMouseActivity = (slotId = '', activity = null) => {
        this.vars.selectedCounterElements.forEach(ele => ele.remove())
        this.vars.selectedCounterElements = []
        if (slotId === '') {
            this.vars.counterMouseActivity = { slotId: '', clicked: false, dragging: false, dropped: false }
        }
        else {
            this.vars.counterMouseActivity.slotId = slotId
            this.vars.counterMouseActivity.clicked = false
            this.vars.counterMouseActivity.dragging = false
            this.vars.counterMouseActivity.dropped = false
            if (activity === 'clicked') {
                this.vars.counterMouseActivity.clicked = true
            }
            if (activity === 'dragging') {
                this.vars.counterMouseActivity.dragging = true
                this.vars.selectedCounterElements.forEach(ele => ele.remove())
                this.vars.selectedCounterElements = []
            }
            if (activity === 'dropped') {
                this.vars.counterMouseActivity.dropped = true
                this.vars.selectedCounterElements.forEach(ele => ele.remove())
                this.vars.selectedCounterElements = []
            }
        }
        this.props.counterMouseActivity(this.vars.counterMouseActivity)
        return this.vars.counterMouseActivity
    }

    getSelectedCounterLayers = async (slotId) => {
        // lets just start getting everything out of the db, cause something is screwing up when trying to copy stuff from vars and states.
        // get data for current sheet
        let sheetData = await this.getCurrentSheetData()
        let slots = sheetData.slots
        for (var i = 0; i < slots.length; i++) {
            if (slots[i].slotId === slotId) {
                let layers = slots[i].counterLayers
                return layers
            }
        }

        return []
    }

    deleteCounter = (slotId) => {
        let slot = this.vars.slots.find(slot => slot.slotId === slotId);
        if (slot) {
            let elementRef = slot.elementRef;
            if (elementRef) {
                elementRef.remove();
            }
            slot.counterLayers = null;
            slot.counterFragments = null;
            slot.counterDefs = null;
            slot.elementRef = null;
        }
        this.setCounterMouseActivity()
        this.saveSheetToDb()
    }

    componentDidMount = () => {
        var paper = Snap("#page_control_svg");
        paper.node.id = 'page_control_svg';
        //210.0mm (8.27Inch)height:297.0mm 2480 x 3508
        paper.attr({ id: "page_control_svg", viewBox: "0, 0, 2480, 3508" });

        this.setState({ paper: paper }, () => {
            //this.drawGrid();
        });
    }

    // When the component updates, check to see if counterSize is 0, and the incoming counter size is a valid value.
    // This means its the first draw. We need to draw the grid.
    // After we draw the grid, we check the database, to see if there is a current sheet in there (and make sure there are counters in it - there should be, otherwise it wouldn't get saved in the db)
    // if there is no current sheet, then we are done.
    // 
    componentDidUpdate = () => {
        if (
            (this.props.counterSize && (this.props.counterSize !== this.vars.pageParams.counterSize))
            ||
            (this.props.marginLeft && (this.props.marginLeft !== this.vars.pageParams.marginLeft))
            ||
            (this.props.marginRight && (this.props.marginRight !== this.vars.pageParams.marginRight))
            ||
            (this.props.marginTop && (this.props.marginTop !== this.vars.pageParams.marginTop))
            ||
            (this.props.marginBottom && (this.props.marginBottom !== this.vars.pageParams.marginBottom))
            ||
            (this.props.spacingX && (this.props.spacingX !== this.vars.pageParams.spacingX))
            ||
            (this.props.spacingY && (this.props.spacingY !== this.vars.pageParams.spacingY))
        ) {
            this.vars.pageParams = {
                counterSize: this.props.counterSize,
                marginLeft: this.props.marginLeft,
                marginRight: this.props.marginRight,
                marginTop: this.props.marginTop,
                marginBottom: this.props.marginBottom,
                spacingX: this.props.spacingX,
                spacingY: this.props.spacingY
            }
            this.drawGrid()
            // draw counters (if there is a current sheet)
            if (this.vars.countersToDraw !== null) {
                let copy = JSON.parse(JSON.stringify(this.vars.countersToDraw))
                this.vars.countersToDraw = null
                this.drawCounters(copy)
            }
            else {
                window.setTimeout( () => this.loadSheet('current'), 1000)
            }
        }
    }

    numberSlotsOccupied = () => {
        let count = 0;
        this.state.slots.forEach(function (slot) {
            if (slot.counterFragments && Array.isArray(slot.counterFragments) && slot.counterFragments.length > 0) {
                count++;
            }
        });
        return count;
    }

    printSlots = () => {
        console.log(this.state.slots);
    }

    getSlots = () => {
        return this.state.slots;
    }

    getDefs = () => {
        return this.state.defs;
    }

    loadSheet = async (sheetName = 'current') => {
        let key = await IndexedDb.getKeyForName('sheet', sheetName)

        if (key === -1) {
            return null
        }
        let sheetData = await IndexedDb.get('sheet', key)

        if (!sheetData) {
            return []
        }
        if (sheetData.slots.length === 0) {
            return [] // there should not be any saved sheets that do not have occupied slots
        }

        if (sheetData.hasOwnProperty('counterSize') && sheetData.counterSize !== '' &&
            sheetData.hasOwnProperty('date') && sheetData.date !== '' &&
            sheetData.hasOwnProperty('marginBottom') && sheetData.marginBottom >= 0 &&
            sheetData.hasOwnProperty('marginLeft') && sheetData.marginLeft >= 0 &&
            sheetData.hasOwnProperty('marginRight') && sheetData.marginRight >= 0 &&
            sheetData.hasOwnProperty('marginTop') && sheetData.marginTop >= 0 &&
            sheetData.hasOwnProperty('name') && sheetData.name !== '') {

            let counterSize = sheetData.counterSize
            let marginLeft = sheetData.marginLeft
            let marginRight = sheetData.marginRight
            let marginTop = sheetData.marginTop
            let marginBottom = sheetData.marginBottom
            let spacingX = sheetData.spacingX
            let spacingY = sheetData.spacingY

            if (sheetName !== 'current' &&
                (counterSize !== this.props.counterSize ||
                    marginLeft !== this.props.marginLeft ||
                    marginRight !== this.props.marginRight ||
                    marginTop !== this.props.marginTop ||
                    marginBottom !== this.props.marginBottom ||
                    spacingX !== this.props.spacingX ||
                    spacingY !== this.props.spacingY
                )) {
                this.vars.countersToDraw = sheetData.slots
                this.props.updatePageParams(counterSize, marginLeft, marginRight, marginTop, marginBottom, spacingX, spacingY)
            }
            else {
                this.drawCounters(sheetData.slots)
            }
        }

        return sheetData
    }

    getCurrentSheetData = async () => {
        let key = await IndexedDb.getKeyForName('sheet', 'current')

        if (key === -1) {
            console.log('no current sheet')
            return
        }
        let sheetData = await IndexedDb.get('sheet', key)

        if (!sheetData) {
            return null
        }

        return sheetData
    }

    drawCounters = (occupiedSlots) => {

        if (occupiedSlots.length === 0) {
            return
        }

        // clear any elements
        for (var i = 0; i < this.vars.slots.length; i++) {
            let slot = this.vars.slots[i]
            let elementRef = slot.elementRef
            if (elementRef !== null) {
                elementRef.remove()
                this.vars.slots[i].elementRef = null
                this.vars.slots[i].counterFragments = null
                this.vars.slots[i].counterDefs = null
            }
        }
        for (i = 0; i < occupiedSlots.length; i++) {
            let occupiedSlot = occupiedSlots[i]
            if (occupiedSlot.hasOwnProperty('counterFragments') &&
                occupiedSlot.hasOwnProperty('counterDefs')) {
                let deferDbUpdate = true
                if (i === occupiedSlots.length - 1) {
                    deferDbUpdate = false
                }
                this.drawCounter(occupiedSlot.counterLayers, occupiedSlot.counterFragments, occupiedSlot.counterDefs, occupiedSlot.number, deferDbUpdate)
            }
            else {
                this.props.logMessage('bad slot at index ' + i)
                this.vars.slots[i].elementRef = null
                this.vars.slots[i].counterFragments = null
                this.vars.slots[i].counterDefs = null
            }
        }


    }

    drawCounter = (counterLayers, counterFragments, counterDefs, number = -1, deferDbUpdate = false) => {
        if (!counterLayers || !counterFragments) {
            return
        }
        let paper = this.state.paper
        let index = number - 1
        let slot = null
        if (number === -1) { // number not specified, so find first available slot
            number = 1
            while (!slot && number < 247) {
                // eslint-disable-next-line
                slot = this.vars.slots.find(slot => slot.number === number && slot.counterFragments === null);
                if (slot) {
                    index = slot.number - 1
                }
                if (!slot) {
                    number++
                }
            }
        }
        else {
            slot = this.vars.slots[index]
        }
        if (!slot) {
            return
        }
        if (slot.elementRef !== null) {
            slot.elementRef.remove()
            this.vars.slots[index].elementRef = null
            this.vars.slots[index].counterLayers = null
            this.vars.slots[index].counterDefs = null
            this.vars.slots[index].counterFragments = null
        }
        this.vars.slots[index].counterLayers = JSON.parse(JSON.stringify(counterLayers))
        this.vars.slots[index].counterFragments = JSON.parse(JSON.stringify(counterFragments))
        if (counterDefs) {
            this.vars.slots[index].counterDefs = JSON.parse(JSON.stringify(counterDefs))
        }
        else {
            this.vars.slots[index].counterDefs = counterDefs
        }
        // check to make sure this counter isn't on a slot that is off screen
        if (this.vars.slots[index].x > -1) {

            let fragments = counterFragments
            let defs = counterDefs

            let x = Utility.roundFloat(parseFloat(slot.x), 2);
            let y = Utility.roundFloat(parseFloat(slot.y), 2);

            let width = slot.width;
            let slotId = slot.slotId;
            let scale = width / 420;

            let elements = '<g id="' + slot.slotId + '">';
            fragments.forEach(function (fragment) {
                elements += fragment
            });
            elements += "</g>";
            let p = Snap.parse(elements);
            paper.append(p);

            let p1 = paper.select('#' + slotId);
            this.vars.slots[index].elementRef = p1

            x -= 40 * scale;
            y -= 40 * scale;

            p1.attr({ transform: "matrix(" + scale + " 0 0 " + scale + " " + x + " " + y + ")" });

            var defns = paper.select('defs');
            let frag = '';
            defs.forEach(function (thisDef) {
                frag = Snap.parse(thisDef);
                defns.append(frag);
            });

            this.makeDraggable(p1, slot.slotId)

            p1.click(() => this.clickOnCounter(this.vars.slots[index]));
        }

        // update the 'current' sheet since we added a counter
        if (deferDbUpdate === false) {
            this.saveSheetToDb()
        }

    }

    clickOnCounter = (slot) => {
        this.setCounterMouseActivity(slot.slotId, 'clicked')

        let paper = this.state.paper;
        let strokeSize = slot.width / 70;

        this.vars.selectedCounterElements.push(paper.rect(slot.x, slot.y, slot.width, slot.height).attr({ fill: "none", stroke: "red", strokeWidth: strokeSize }))
        this.vars.selectedCounterElements.push(paper.rect(slot.x - strokeSize, slot.y - strokeSize, slot.width + (strokeSize * 2), slot.height + (strokeSize * 2)).attr({ fill: "none", stroke: "#dddddd", strokeWidth: strokeSize }))
    }

    elementFinishedDragging = (slotId, moveX, moveY) => {
        let slot = this.vars.slots.find(slot => slot.slotId === slotId);
        if (!slot) {
            console.log('something went wrong, could not get slot for dragged counter')
            return
        }
        if (slot.counterLayers === null || slot.counterFragments === null) {
            console.log('something went wrong, dragged slot has no counterLayers or counterFragments')
            return
        }
        // copy/clone the data
        let counterLayers = JSON.parse(JSON.stringify(slot.counterLayers))
        let counterFragments = JSON.parse(JSON.stringify(slot.counterFragments))
        let counterDefs = JSON.parse(JSON.stringify(slot.counterDefs))

        slot.counterLayers = null
        slot.counterFragments = null;
        slot.counterDefs = null;

        let origX = slot.x;
        let origY = slot.y;

        let newX = origX + (moveX * 2.1);
        let newY = origY + (moveY * 2.1);
        // move x and y to the center of the counter
        newX += slot.width / 2;
        newY += slot.height / 2;

        let droppedOnSlot = this.findSlotContainingXY(newX, newY);
        if (!droppedOnSlot) {
            // move counter back to where it came.
            this.drawCounter(counterLayers, counterFragments, counterDefs, slot.number);
            return
        }
        if (droppedOnSlot.counterFragments !== null) {
            // move counter back to where it came.
            this.drawCounter(counterLayers, counterFragments, counterDefs, slot.number);
            return
        }
        if (slot.elementRef) {
            slot.elementRef.remove();
        }
        this.drawCounter(counterLayers, counterFragments, counterDefs, droppedOnSlot.number);

        this.setState({ counterBeingMoved: '' });
        this.props.logMessage('counter moved on sheet')
    }

    resetCounterToBeDrawnOnTop = (slotId) => {
        let slot = this.vars.slots.find(slot => slot.slotId === slotId);

        if (!slot) {
            console.log('something went wrong, could not get slot for dragged counter')
            return
        }
        if (slot.counterLayers === null || slot.counterFragments === null) {
            console.log('something went wrong, dragged slot has no counterLayers or counterFragments')
            return
        }
        let elementRef = slot.elementRef
        if (elementRef) {
            elementRef.remove();
            let paper = this.state.paper;
            paper.append(elementRef);
            let newElementRef = paper.select('#' + slotId);
            slot.elementRef = newElementRef
        }
    }

    makeDraggable(g, slotId) {

        var thisRef = this;
        var startedDrag = false;
        var actuallyMoved = false;
        var timeout1 = 0;
        var moveX = 0;
        var moveY = 0;
        var drawnOnTop = false
        var multiplyBy = 3.07



        var move = function (dx, dy) {
            dx *= multiplyBy;
            dy *= multiplyBy;
            if (startedDrag === false) {
                return;
            }
            actuallyMoved = true;
            if (drawnOnTop === false) {
                thisRef.resetCounterToBeDrawnOnTop(slotId)
                drawnOnTop = true
            }



            dragTracker(true, dx, dy);

            this.attr({
                transform: this.data('origTransform') + (this.data('origTransform') ? "T" : "t") + [dx, dy]
            });
        }
        var start = function () {
            let element = document.getElementsByClassName("page-control")[0];
            let domRect = element.getBoundingClientRect();
            let width = domRect.width
            multiplyBy = 3.07 * (808 / width)
            timeout1 = setTimeout(function () {
                startedDrag = true;
                thisRef.setCounterMouseActivity(slotId, 'dragging')
                this.data('origTransform', this.transform().local);
            }.bind(this), 10);
        }
        var stop = function () {


            if (startedDrag === false) {
                clearTimeout(timeout1);
                timeout1 = 0
                return;
            }
            else {
                startedDrag = false;
            }
            if (actuallyMoved === false) {
                return;
            }
            dragTracker(false);

        }

        var dragTracker = function (moving, x, y) {
            if (moving) {
                let mod = 0.476;
                moveX = x * mod;
                moveY = y * mod;
            }
            else {
                this.elementFinishedDragging(slotId, moveX, moveY);
                startedDrag = false;
                actuallyMoved = false;
                timeout1 = 0;
                moveX = 0;
                moveY = 0;
                drawnOnTop = false
            }
        }.bind(this)

        g.drag(move, start, stop);

    }

    numCountersOnSheet = () => {
        let counterCount = 0;
        this.state.slots.forEach(function (slot) {
            if (slot.counterFragments && Array.isArray(slot.counterFragments) && slot.counterFragments.length !== 0 && slot.x > -1) {
                counterCount++;
            }
        });

        return counterCount;
    }


    getIdOfDef = (def, defIds) => {
        let retVal = null;
        defIds.forEach(function (did) {
            if (def.indexOf(did) > -1) {
                retVal = did;
            }
        });
        return retVal;
    }

    getOccupiedSlots = () => {
        let slots = this.vars.slots
        let occupiedSlots = []
        for (var i = 0; i < slots.length; i++) {
            let slot = slots[i]
            if (slot.counterFragments) {
                occupiedSlots.push({
                    columnNumber: slot.columnNumber,
                    counterLayers: slot.counterLayers.slice(0),
                    counterFragments: slot.counterFragments,
                    counterDefs: slot.counterDefs,
                    height: slot.height,
                    width: slot.width,
                    rowNumber: slot.rowNumber,
                    number: slot.number,
                    slotId: slot.slotId,
                    x: slot.x,
                    y: slot.y
                })
            }
        }
        return occupiedSlots
    }

    slotsInfo = () => {
        let slots = this.vars.slots
        let numSlots = 0
        let numOccupiedSlots = 0
        let numOccupiedSlotsOverflow = 0
        for (var i = 0; i < slots.length; i++) {
            let slot = slots[i]
            if (slot.counterFragments && slot.x > -1) {
                numOccupiedSlots++
            }
            if (slot.counterFragments && slot.x === -1) {
                numOccupiedSlotsOverflow++
            }
            if (slot.x > -1) {
                numSlots++
            }
        }
        let numAvailable = numSlots - numOccupiedSlots
        return { numSlots, numOccupiedSlots, numAvailable, numOccupiedSlotsOverflow }
    }

    populateSlots = (fullSlots, occupiedSlots) => {

        for (var i = 0; i < occupiedSlots.length; i++) {
            let occupiedSlot = occupiedSlots[i]

            let numberOfSlot = occupiedSlot.number

            fullSlots[numberOfSlot] = occupiedSlot
        }


        return fullSlots
    }

    saveSheetToDb = (sheetName = 'current') => {
        return new Promise((resolve, reject) => {
            var today = new Date();
            var date = today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate();
            var time = today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
            var dateTime = date + ' ' + time;

            let thisSheet = {
                name: sheetName,
                date: dateTime,
                counterSize: this.props.counterSize,
                marginLeft: this.props.marginLeft,
                marginRight: this.props.marginRight,
                marginTop: this.props.marginTop,
                marginBottom: this.props.marginBottom,
                spacingX: this.props.spacingX,
                spacingY: this.props.spacingY,
                slots: this.getOccupiedSlots()
            }

            IndexedDb.getKeyForName('sheet', sheetName).then(sheetKey => {
                if (sheetKey === -1) {
                    IndexedDb.put('sheet', thisSheet).then((key) => {
                        this.props.dbSheetUpdated(sheetName)
                        this.props.sheetWasSaved()
                        resolve(key)
                    })

                }
                else {
                    IndexedDb.put('sheet', thisSheet, sheetKey).then(() => {
                        this.props.dbSheetUpdated(sheetName)
                        this.props.sheetWasSaved()
                        resolve(sheetKey)
                    })
                }
            })
        })
    }

    clearSheet = () => {
        let slots = this.vars.slots;
        for (var i = 0; i < slots.length; i++) {
            slots[i].counterLayers = null
            slots[i].counterFragments = null
            slots[i].counterDefs = null
            slots[i].elementRef = null
        }
        this.saveSheetToDb()
        this.drawGrid()
    }


    trackMovingCounter = (dx, dy) => {
        this.setState({ counterBeingMovedXchange: dx, counterBeingMovedYchange: dy }, () => {
        });
    }

    xyInsideRect = (x, y, rect) => {
        return (x >= rect.x && x <= (rect.x + rect.width)) &&
            (y >= rect.y && y <= (rect.y + rect.height));
    }

    findSlotContainingXY = (x, y) => {
        return this.vars.slots.find(slot => this.xyInsideRect(x, y, { x: slot.x, y: slot.y, width: slot.width, height: slot.height }));
    }

    moveElementToTop = (element) => {
        if (element) {
            let paper = this.state.paper;
            element.remove();
            paper.append(element);
        }
    }

    slotClicked = (evt) => {
        let slotCurrentlySelected = this.state.slotCurrentlySelected;
        if (slotCurrentlySelected !== 0) {
            this.stopSelectedSlotAnimation();
        }
        let slotId = evt.target.parentNode.id;
        if (!slotId) {
            slotId = evt.target.parentNode.parentNode.id;
        }
        if (slotId === slotCurrentlySelected) {
            this.setState({ slotCurrentlySelected: 0 });
            this.props.pageCounterClicked(0);
            return;
        }
        if (slotId) {
            let slot = this.state.slots.find(slot => slot.slotId === slotId);
            this.props.pageCounterClicked(slotId);
            this.setState({ slotCurrentlySelected: slotId }, () => {
                this.startSelectedSlotAnimation(slot);
            });
        }
    }

    getSelectedCounter = () => {
        let slotId = this.state.slotCurrentlySelected;
        let slot = this.state.slots.find(slot => slot.slotId === slotId);
        if (slot) {
            return slot;
        }

        return []
    }

    cancelSelectedCounter = () => {
        this.stopSelectedSlotAnimation();
    }

    undoPrint = () => {
        // let paper = this.state.paper
        // let gridSetRef = this.state.gridSetRef;
        // gridSetRef.forEach(function (gr) {
        //     paper.append(gr);
        // });

        // let slots = this.state.slots;
        // slots.forEach(function (slot) {
        //     let elementRef = slot.elementRef;
        //     if (elementRef) {
        //         elementRef.remove();
        //         if (slot.counterState) {
        //             this.placeOnSheet(slot.counterState, slot.counterSvgData, slot.number, slot.defs, slot.defIds);
        //         }
        //     }
        // }.bind(this));

        // this.props.printVersionDeactive();
    }

    preparePrint = () => {
        let gridSetRef = this.vars.gridRefs;
        gridSetRef.forEach(function (gr) {
            gr.remove();
        });
        let paper = this.state.paper;

        var frag = Snap.parse('<style type="text/css">' +
            '@import url("https://fonts.googleapis.com/css?family=Audiowide:400,400i,700,700i");' +
            '@import url("https://fonts.googleapis.com/css?family=Roboto:400,400i,700,700i");' +
            '@import url("https://fonts.googleapis.com/css?family=Anton:400,400i,700,700i");' +
            '@import url("https://fonts.googleapis.com/css?family=Staatliches:400,400i,700,700i");' +
            '@import url("https://fonts.googleapis.com/css?family=Sansita:400,400i,700,700i");' +
            '@import url("https://fonts.googleapis.com/css?family=Racing+Sans+One:400,400i,700,700i");' +
            '@import url("https://fonts.googleapis.com/css?family=Squada+One:400,400i,700,700i");' +
            '@import url("https://fonts.googleapis.com/css?family=Francois+One:400,400i,700,700i");' +
            '@import url("https://fonts.googleapis.com/css?family=Fjalla+One:400,400i,700,700i");' +
            '@import url("https://fonts.googleapis.com/css?family=Rubik:400,400i,700,700i");' +
            '@import url("https://fonts.googleapis.com/css?family=Baloo+Bhai:400,400i,700,700i");' +
            '@import url("https://fonts.googleapis.com/css?family=Lobster:400,400i,700,700i");' +
            '@import url("https://fonts.googleapis.com/css?family=Germania+One:400,400i,700,700i");' +
            '@import url("https://fonts.googleapis.com/css?family=Goblin+One:400,400i,700,700i");' +
            '@import url("https://fonts.googleapis.com/css?family=Quantico:400,400i,700,700i");' +
            '@import url("https://fonts.googleapis.com/css?family=Ramabhadra:400,400i,700,700i");' +
            '@import url("https://fonts.googleapis.com/css?family=Kreon:400,400i,700,700i");' +
            '@import url("https://fonts.googleapis.com/css?family=Inconsolata:400,400i,700,700i");' +
            '@import url("https://fonts.googleapis.com/css?family=Sonsie+One");' +
            '@import url("https://fonts.googleapis.com/css?family=Overpass:400,400i,700,700i");' +
            '@import url("https://fonts.googleapis.com/css?family=Ubuntu+Condensed:400,400i,700,700i");' +
            '</style>');
        paper.append(frag);


    }

    print = () => {
        this.preparePrint()
        var container = document.getElementById('page_control_svg');
        var width = 2480;
        var height = 3508;
        var printWindow = window.open('', 'PrintMap',
            'width=' + width + ',height=' + height);
        printWindow.document.writeln(container.outerHTML);
        // printWindow.document.close();
        setTimeout(() =>
            printWindow.print()
            , 1000)
        //printWindow.close();
        this.drawGrid()
        // draw counters (if there is a current sheet)
        this.loadSheet('current')
    }

    drawGrid = () => {
        let m = (u) => 11.877 * u
        let paper = this.state.paper;
        let refs = []
        paper.clear();
        let canvasWidth = '100%';
        let canvasHeight = '100%';
        refs.push(paper.rect(0, 0, canvasWidth, canvasHeight).attr({
            fill: "#000000"
        }))

        let pageWidth = 2480;
        let pageHeight = 3508 //3508;

        let rowNumber = 1;
        let columnNumber = 1;
        let number = 1;
        let slots = [];
        let useFontSize = 12;
        // clear the previous refs
        this.vars.gridRefs.forEach(eref => eref.remove());
        let counterSize = Utility.roundFloat(parseFloat(this.props.counterSize), 3)

        useFontSize *= counterSize / 4;

        let marginLeftPercentage = parseFloat(this.props.marginLeft) / 100
        let marginRightPercentage = parseFloat(this.props.marginRight) / 100
        let spacingXpercentage = parseFloat(this.props.spacingX) / 100

        let marginTopPercentage = parseFloat(this.props.marginTop) / 100
        let marginBottomPercentage = parseFloat(this.props.marginBottom) / 100
        let spacingYpercentage = parseFloat(this.props.spacingY) / 100
        let spacingX = pageWidth * spacingXpercentage
        let spacingY = pageHeight * spacingYpercentage

        marginBottomPercentage = Utility.isNumeric(marginBottomPercentage) ? marginBottomPercentage : 0

        let mmWidth = 208.804 // mm in width of an A4
        let mmHeight = 295.356 //
        let numThatFitX = this.computeNumberThatFitsMM(mmWidth, pageWidth, counterSize, marginLeftPercentage, marginRightPercentage, spacingXpercentage)
        let numThatFitY = this.computeNumberThatFitsMM(mmHeight, pageHeight, counterSize, marginTopPercentage, marginBottomPercentage, spacingYpercentage)

        let startX = Utility.roundFloat(pageWidth * marginLeftPercentage, 2)
        let startY = Utility.roundFloat(pageHeight * marginTopPercentage, 2)

        // // //paper.rect(xPos - 0.5, yPos - 0.5, ((width + 0.5) * 13) + 0.5, ((height + 0.5) * 17) + 1).attr({ strokeWidth: 0, fill: "#000000" });
        let fontAttr = {
            "textAnchor": "middle",
            "dominant-baseline": "baseline",
            "fontSize": useFontSize,
            "fontFamily": 'sans-serif',
            stroke: "none",
            strokeWidth: 0,
            fill: "#aaa"
        };

        let xPos = startX;
        let yPos = startY;
        let width = m(counterSize)
        let height = width

        for (let y = 0; y < numThatFitY; y++) {
            //xPos = 37;
            xPos = startX;
            columnNumber = 1;
            rowNumber = y + 1;
            for (let x = 0; x < numThatFitX; x++) {
                //let yPos = startY;
                refs.push(paper.rect(xPos, yPos, width, height).attr({ stroke: "#000000", strokeWidth: 0, fill: "#dddddd" }));
                refs.push(paper.text(xPos + (width / 2), yPos + (width / 2) - useFontSize + (useFontSize * 0.35), 'C' + columnNumber).attr(fontAttr));
                refs.push(paper.text(xPos + (width / 2), yPos + (width / 2) + (useFontSize * 0.35), 'R' + rowNumber).attr(fontAttr));
                refs.push(paper.text(xPos + (width / 2), yPos + (width / 2) + useFontSize + (useFontSize * 0.35), 'N' + number).attr(fontAttr));
                slots.push({
                    slotId: 'slotId' + number,
                    x: xPos, y: yPos,
                    width: width, height: height,
                    columnNumber: columnNumber, rowNumber: rowNumber,
                    number: number,
                    counterLayers: null,
                    counterFragments: null,
                    counterDefs: null,
                    elementRef: null
                });
                xPos += width + spacingX;
                columnNumber++;
                number++;
            }

            yPos += height + spacingY;

        }

        let maxColumn = Math.max.apply(Math, slots.map(slot => slot.columnNumber))
        let maxRow = Math.max.apply(Math, slots.map(slot => slot.rowNumber))
        let crossPoints = []
        slots.forEach(slot => {
            let x = slot.x - (spacingX / 2)
            let y = slot.y - (spacingY / 2)
            crossPoints.push({ x, y })
            if (slot.columnNumber === maxColumn) {
                x = slot.x + (slot.width) + (spacingX / 2)
                y = slot.y - (spacingY / 2)
                crossPoints.push({ x, y })
            }
            if (slot.rowNumber === maxRow) {
                x = slot.x - (spacingX / 2)
                y = slot.y + (slot.height) + (spacingY / 2)
                crossPoints.push({ x, y })
            }
            if (slot.rowNumber === maxRow && slot.columnNumber === maxColumn) {
                x = slot.x + slot.width + (spacingX / 2)
                y = slot.y + (slot.height) + (spacingY / 2)
                crossPoints.push({ x, y })
            }
        })
        let crossWidth = width * 0.3
        crossWidth = crossWidth > 100 ? 100 : crossWidth
        let lineThicknessX = spacingX / 2
        let lineThicknessY = spacingY / 2
        lineThicknessX = lineThicknessX > 5 ? 5 : lineThicknessX
        lineThicknessY = lineThicknessY > 5 ? 5 : lineThicknessY
        crossPoints.forEach(point => this.drawXYcross(point, crossWidth, lineThicknessX, lineThicknessY, spacingX, spacingY))

        // // max slots for half inch is 247 slots. We keep it at 247 even if they choose a larger counter size, cause we don't want to be lopping off counters.
        for (let index = number; index < 247; index++) {
            slots.push({
                slotId: 'slotId' + number,
                x: -1, y: -1,
                width: width, height: height,
                columnNumber: -1, rowNumber: -1,
                number: number,
                counterLayers: null,
                counterFragments: null,
                counterDefs: null,
                elementRef: null
            });
            number++;
        }

        this.vars.gridRefs = refs
        this.vars.slots = slots
    }

    drawXYcross = (p, s, thicknessX, thicknessY, spacingX, spacingY) => {
        let paper = this.state.paper

        if (spacingY > 0) {
            paper.line(p.x - s / 2 + 1, p.y - (spacingY / 2) + 1, p.x + s / 2 - 1, p.y - (spacingY / 2) + 1).attr({ stroke: "#f44", strokeWidth: 3 })
            paper.line(p.x - s / 2 + 1, p.y + (spacingY / 2) - 1, p.x + s / 2 - 1, p.y + (spacingY / 2) - 1).attr({ stroke: "#f44", strokeWidth: 3 })
        }

        if (spacingX > 0) {
            paper.line(p.x - (spacingX / 2) + 1, p.y - s / 2 + 1, p.x - (spacingX / 2) + 1, p.y + s / 2 - 1).attr({ stroke: "#0ff", strokeWidth: 3 })
            paper.line(p.x + (spacingX / 2) - 1, p.y - s / 2 + 1, p.x + (spacingX / 2) - 1, p.y + s / 2 - 1).attr({ stroke: "#0ff", strokeWidth: 3 })
        }

        if (spacingX > 0 && spacingY > 0 && spacingX < 150 && spacingY < 150) {
            paper.line(p.x - s / 2 + 1, p.y, p.x + s / 2 - 1, p.y).attr({ stroke: "#aaa", strokeWidth: thicknessX })
            paper.line(p.x, p.y - s / 2 + 1, p.x, p.y + s / 2 - 1).attr({ stroke: "#aaa", strokeWidth: thicknessY })
        }

    }

    computeNumberThatFitsMM = (mm, totalAvailable, counterSize, percentage1, percentage2, inbetweenPercentage) => {
        let t = totalAvailable / mm
        let counterSizeUnits = counterSize * t
        let margin1 = Utility.roundFloat(totalAvailable * percentage1, 2)
        let margin2 = Utility.roundFloat(totalAvailable * percentage2, 2)
        let inbetween = totalAvailable * inbetweenPercentage
        let spaceAfterSubtractingMargins = totalAvailable - (margin1 + margin2)
        let numThatFitWithNoInbetweenSpacing = Math.floor(spaceAfterSubtractingMargins / counterSizeUnits)
        let spaceCurrentlyNeeded = (numThatFitWithNoInbetweenSpacing * counterSizeUnits) + ((numThatFitWithNoInbetweenSpacing - 1) * inbetween)
        if (spaceCurrentlyNeeded <= spaceAfterSubtractingMargins) {
            return numThatFitWithNoInbetweenSpacing
        }
        else {
            let numThatFit = numThatFitWithNoInbetweenSpacing - 1
            spaceCurrentlyNeeded = (numThatFit * counterSizeUnits) + ((numThatFit - 1) * inbetween)
            while (spaceCurrentlyNeeded > spaceAfterSubtractingMargins) {
                numThatFit = numThatFit - 1
                spaceCurrentlyNeeded = (numThatFit * counterSizeUnits) + ((numThatFit - 1) * inbetween)
            }
            return numThatFit
        }

    }

    render = () => {

        return (
            <div className="page-control">

                <svg id="page_control_svg" xmlns="http://www.w3.org/2000/svg" className="page-control-svg">
                </svg>
            </div>
        )
    }
}