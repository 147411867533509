import React, { Component } from 'react';
import './ExistingSetups.scss';

export default class ExistingSetups extends Component {

    state = {
        hexMapSetups: []
    }



    componentDidMount = () => {
        if (localStorage.getItem('hexMapSetups') !== null) {
            let hexMapSetups = JSON.parse(localStorage.getItem('hexMapSetups'));
            this.setState({ hexMapSetups: hexMapSetups });
        }
    }



    removeSaved = (index) => {
        let hexMapSetups = this.state.hexMapSetups;
        hexMapSetups.splice(index, 1);
        this.setState({ hexMapSetups: hexMapSetups });
    }

    removeAll = () => {
        this.setState({ hexMapSetups: [] });
        localStorage.removeItem('hexMapSetups');
    }

    render = () => {

        if (this.state.hexMapSetups.length === 0) {
            return <div />;
        }

        return (
            <div className="existing-setups">
                <div className="title">
                    <div>Saved hex map setup data:</div>
                    <div className="remove-all" onClick={this.removeAll}>remove all</div>
                </div>
                {this.state.hexMapSetups.map(function (hexMapSetup, index) {
                    return <div className="load-setup" key={index}>
                        <div>
                            <button  className={this.props.loadIndex === index ? 'opacity-zero' : ''} onClick={() => this.props.loadSetup(index, hexMapSetup)}>
                                LOAD
                            </button>
                            <span className={this.props.loadIndex === index ? 'display-loaded' : 'display-none'} onClick={() => this.props.loadSetup(index, hexMapSetup)}>LOADED</span>
                        </div>
                        <div className="setup-data">
                            map margin: <span className="avalue">{hexMapSetup.mapMargin}</span>,
                                hex diameter: <span className="avalue">{hexMapSetup.hexDiameter}</span>,
                                hexes across: <span className="avalue">{hexMapSetup.hexesAcross}</span>,
                                hexes down: <span className="avalue">{hexMapSetup.hexesDown}</span>,
                                hex line width: <span className="avalue">{hexMapSetup.hexLineWidth}</span>,
                                joggle: <span className="avalue">{hexMapSetup.joggle === 0 ? 'Left' : 'Right'}</span>,
                                hexNumberGrain: <span className="avalue">{hexMapSetup.hexNumberGrain === 0 ? 'horizontal' : 'vertical'}</span>,
                                hex number font size: <span className="avalue">{hexMapSetup.hexNumberFontSize}</span>,
                                hex number orientation: <span className="avalue">{hexMapSetup.hexNumberOrientation.replace('angle', '')}</span>,
                                hex number position: <span className="avalue">{hexMapSetup.hexNumberPosition}</span>,
                                hex center dot size: <span className="avalue">{hexMapSetup.hexCenterDotSize}</span>,
                                color margin: <span className="avalue">{hexMapSetup.mapMarginColor}</span>,
                                color hex: <span className="avalue">{hexMapSetup.mapHexColor}</span>,
                                color hex number: <span className="avalue">{hexMapSetup.hexNumberFontColor}</span>,
                                color hexline: <span className="avalue">{hexMapSetup.hexLineColor}</span>,
                                map margin opacity: <span className="avalue">{hexMapSetup.mapMarginOpacity}</span>,
                                map hex opacity: <span className="avalue">{hexMapSetup.mapHexOpacity}</span>,
                                hex number font opacity: <span className="avalue">{hexMapSetup.hexNumberFontOpacity}</span>,
                                hex line opacity: <span className="avalue">{hexMapSetup.hexLineOpacity}</span>
                        </div>
                        <div><button className="remove" onClick={() => this.removeSaved(index)}>REMOVE</button></div>
                    </div>;
                }.bind(this))}

            </div>
        )
    }
}