import React from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import Index from './Components/Index/Index';
import Setup from './Components/Setup/Setup';
import HexMap from './Components/HexMap/HexMap';
import SnapCounter from './Components/SnapCounter/SnapCounter';
import PathReducer from './Components/PathReducer/PathReducer';
import './App.css';

function App() {
    let existingMapEle = document.getElementById('thesvg')
    if (existingMapEle) {
        existingMapEle.parentNode.removeChild(existingMapEle);
    }
    return (
        <BrowserRouter>
            <div className="app">
                <Switch>
                    <Route exact path='/' component={Index} />
                    <Route exact path='/setup' component={Setup} />
                    <Route exact path='/hexmap' component={HexMap} />
                    <Route exact path='/snap_counter' component={SnapCounter} />
                    <Route exact path='/path_reducer' component={PathReducer} />
                </Switch>

            </div>
        </BrowserRouter>
    );
}

export default App;
