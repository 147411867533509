import React, { Component } from 'react';
import './Log.scss';

export default class Log extends Component {

    state = {
        messages: []
    }

    componentDidMount = () => {
    }

    componentDidUpdate = () => {
        if (this.props.message !== '') {
            let time = new Date().toLocaleTimeString('en-US', { hour: 'numeric', hour12: true, minute: 'numeric', second: 'numeric' });
            if (this.state.messages.length === 0) {
                let messages = []
                messages.push({
                    time: time,
                    message: this.props.message
                })
                this.setState({messages})
            }
            else {
                if(( this.props.message !== this.state.messages[ this.state.messages.length - 1 ].message) ) {
                    let messages = this.state.messages
                    messages.push({
                        time: time,
                        message: this.props.message
                    })
                    this.setState({messages})
                }
            }
        }
    }

    render = () => {
        return <div className="log">

            {this.state.messages.map((timeMessage, index) => {
                return (
                    <div key={index}>
                        <span>{timeMessage.time}</span>
                        <span>{timeMessage.message}</span>
                    </div>)
            })}

        </div>
    }
}    