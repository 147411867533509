import React, { Component } from 'react';
import Snap from 'snapsvg-cjs';
import Utility from '../../../Utility';
import './Counter.scss';

export default class Counters extends Component {

    state = {
        paper: null,
        counter: null
    }

    componentDidMount = () => {
        var slugName = 'counter_' + Utility.slugify(this.props.counter.name) + '__svg'
        var paper = Snap('#' + slugName);
        if( !paper ) {
            console.log('bad read on counter ', slugName)
            return
        }
        paper.node.id = slugName;
        paper.attr({ id: slugName, viewBox: "0, 0, 420, 420" });
        this.setState({ paper: paper, counter: this.props.counter }, () => {
            let fragments = this.props.counter.svg.fragments
            let defs = this.props.counter.svg.defs
            this.drawCounter(fragments, defs)
        });
    }

    componentDidUpdate = () => {
            let fragments = this.props.counter.svg.fragments
            let defs = this.props.counter.svg.defs
            this.drawCounter(fragments, defs)
    }

    componentWillUnmount = () => {
        var slugName = 'counter_' + Utility.slugify(this.props.counter.name) + '__svg'
        let existingMapEle = document.getElementById(slugName)
        if (existingMapEle) {
            existingMapEle.parentNode.removeChild(existingMapEle);
        }
    }

    drawCounter = (counterFragments, counterDefs) => {
        if (!counterFragments) {
            return
        }
        let paper = this.state.paper
        if( !paper ) {
            return
        }
        paper.clear()
        let fragments = JSON.parse(JSON.stringify(counterFragments))
        let defs = []
        if (counterDefs) {
            defs = JSON.parse(JSON.stringify(counterDefs))
        }

        let x = 0;
        let y = 0;

        let width = 200
        let scale = width / 420;

        let elements = '<g id="g' + Utility.slugify(this.props.counter.name) + '">';
        fragments.forEach(function (fragment) {
            elements += fragment
        });
        elements += "</g>";
        let p = Snap.parse(elements);
        paper.append(p);


        let p1 = paper.select('#g' + Utility.slugify(this.props.counter.name));

        x -= 40 * scale;
        y -= 40 * scale;

        p1.attr({ transform: "matrix(" + scale + " 0 0 " + scale + " " + x + " " + y + ")" });

        var defns = paper.select('defs');
        let frag = '';
        defs.forEach(function (thisDef) {
            frag = Snap.parse(thisDef);
            defns.append(frag);
        });

    }

    render = () => {
        return (
            <div className="counter-row">
                <div>
                    <svg id={'counter_' + Utility.slugify(this.props.counter.name) + '__svg'} xmlns="http://www.w3.org/2000/svg" width="180" height="180" className="counter-svg">
                    </svg>
                </div>
                <div>
                    <div>{this.props.counter.name}</div>
                    <div>
                        <button className="momentary-button" onClick={() => this.props.loadCounterFromDb(this.props.counter.key, this.props.counter.name)}>edit</button>
                        <button className="momentary-button negative" onClick={() => this.props.deleteCounterFromDb(this.props.counter.key, this.props.counter.name)}>delete</button>
                    </div>
                </div>

            </div>
        )
    }
}