// import { config } from '../config.js';
// import ApiConnector from './ApiConnector/ApiConnector';
// import { store } from "../Redux/store";
// import { setUser } from "../Redux/actions";
import JSZip from 'jszip'

let Utility = {

    unZip: (blob) => {
        return new Promise((resolve, reject) => {
            if (!blob) {
                console.log('reject')
                reject(null)
            }
            JSZip.loadAsync(blob).then(async function (zipped) {
                let jsonName = Object.keys(zipped.files)[0]
                var jsonFile = await zipped.file(jsonName).async("text");
                resolve(jsonFile)
            },
                error => {
                    reject(error)
                }
            )
        })
    },

    parseJSON: (str) => {
        try {
            return JSON.parse(str);
        } catch (e) {
            return false;
        }
    },

    isSet: (o) => {
        try {
            Set.prototype.has.call(o); // throws if o is not an object or has no [[SetData]]
            return true;
        } catch (e) {
            return false;
        }
    },

    isHexaColor: (sNum) => {
        if (typeof sNum === 'string' && sNum === 'none') {
            return true;
        }
        if (sNum.length !== 4 && sNum.length !== 7) {
            return false;
        }
        if (sNum.charAt(0) !== '#') {
            return false;
        }
        let r = /^#([a-fA-F0-9]){3}$|[a-fA-F0-9]{6}$/i.test(sNum)
        return r;
    },

    getIntFromString: (str) => {
        if (typeof str === 'number') {
            return Math.round(str);
        }
        if (str === null || typeof str !== 'string') {
            return -1;
        }
        var num = str.replace(/\D/g, '');
        return parseInt(num);
    },

    arrayMoveItem: (array, from, to) => {

        if (from > to) {
            to++;
        }

        if (to === from) return array;

        var target = array[from];
        var increment = to < from ? -1 : 1;

        for (var k = from; k !== to; k += increment) {
            array[k] = array[k + increment];
        }
        array[to] = target;
        return array;
    },

    rgbToHex: (r, g, b) => {
        r = parseInt(r);
        g = parseInt(g);
        b = parseInt(b)
        if (r > 255 || g > 255 || b > 255 ||
            r < 0 || g < 0 || b < 0)
            return false;
        return (((r << 16) | (g << 8) | b).toString(16)).toUpperCase();
    },

    getEventLocation: (element, event) => {
        return {
            x: event.offsetX,
            y: event.offsetY
        };
    },

    roundOffPathArrayNumbersWithScale: (pathArray, precision = 1, scale = 1) => {
        return pathArray.map(item => {
            if (Utility.isNumeric(item)) {
                return Utility.roundFloat(parseFloat(item * scale), precision)
            }
            else {
                return item
            }
        })
    },

    pathTranslateXY: (path, xAdjust, yAdjust) => {
        let modPath = Utility.replaceAll(path, 'M', ' M ')
        modPath = Utility.replaceAll(modPath, 'm', ' m ')
        modPath = Utility.replaceAll(modPath, 'L', ' L ')
        modPath = Utility.replaceAll(modPath, 'l', ' l ')

        modPath = Utility.replaceAll(modPath, 'C', ' C ')
        modPath = Utility.replaceAll(modPath, 'c', ' c ')
        modPath = Utility.replaceAll(modPath, 'S', ' S ')
        modPath = Utility.replaceAll(modPath, 's', ' s ')
        modPath = Utility.replaceAll(modPath, 'Q', ' Q ')
        modPath = Utility.replaceAll(modPath, 'q', ' q ')
        modPath = Utility.replaceAll(modPath, 'T', ' T ')
        modPath = Utility.replaceAll(modPath, 't', ' t ')
        modPath = Utility.replaceAll(modPath, 'A', ' A ')
        modPath = Utility.replaceAll(modPath, 'a', ' a ')

        modPath = Utility.replaceAll(modPath, 'Z', ' Z ')
        modPath = Utility.replaceAll(modPath, 'z', ' z ')

        modPath = modPath.replace(/\s\s+/g, ' ')
        modPath = modPath.trim()
        let oddEven = false
        let arr = modPath.split(' ')
        let mode = ''
        for (var i = 0; i < arr.length; i++) {
            let t = arr[i]
            if (t === 'M' || t === 'L' || t === 'C' || t === 'S' || t === 'Q' || t === 'A' ||
                (i === 0 && t === 'm')) {
                mode = 'absolute'
                oddEven = false
                continue
            }
            else {
                if (t === 'm' || t === 'l' || t === 'c' || t === 's' || t === 'q' || t === 'a') {
                    mode = 'relative'
                    continue
                }
                else {
                    if (t === 'A') {
                        i++
                        if (Utility.isNumeric(arr[i])) {
                            arr[i] = parseFloat(arr[i]) + xAdjust
                        }
                        i++
                        arr[i] = parseFloat(arr[i]) + yAdjust
                        i++
                        i++
                        i++
                        arr[i] = parseFloat(arr[i]) + xAdjust
                        i++
                        arr[i] = parseFloat(arr[i]) + yAdjust
                        mode = ''
                    }
                    else {
                        oddEven = !oddEven
                        if (mode === 'absolute') {
                            if (oddEven === true) {
                                arr[i] = parseFloat(arr[i]) + xAdjust
                            }
                            else {
                                arr[i] = parseFloat(arr[i]) + yAdjust
                            }
                        }
                    }
                }
            }
        }

        return arr.join(' ')
    },

    dr: (degrees) => {
        var pi = Math.PI;
        return degrees * (pi / 180);
    },

    roundFloat: (nbr, dec_places = 0) => {
        var mult = Math.pow(10, dec_places);
        return Math.round(nbr * mult) / mult;
    },

    isInt: (n) => !isNaN(parseInt(n, 10)) && isFinite(n),

    strHasIntOrDot: (str) => {
        let testStr = str + ''
        function validate(s) {
            var rgx = /^[0-9]*\.?[0-9]*$/;
            return s.match(rgx);
        }
        if (validate(testStr)) {
            return true
        }
        return false
    },

    isNumeric: (n) => !isNaN(parseFloat(n)) && isFinite(n),

    isObject: (obj) => {
        var type = typeof obj;
        var ret = false
        if (type === 'function') {
            ret = true
        }
        if (type === 'object' && !!obj) {
            ret = true
        }
        return ret
    },

    isEmpty: (obj) => {
        if (obj && Utility.isObject(obj)) {
            for (var prop in obj) {
                if (obj.hasOwnProperty(prop)) return false;
            }
        }
        return true;
    },

    requestId: () => Math.floor(Math.random() * ((99999 - 11111) + 1) + 11111),

    escapeRegExp(str) {
        return str.replace(/([.*+?^=!:${}()|[]\/\\])/g, "\\$1");
    },

    replaceAll: (str, find, replace) => {
        if (!str || !find || !replace) {
            return str;
        }
        return str.replace(new RegExp(Utility.escapeRegExp(find), 'g'), replace);
    },

    fontFamilies: [
        { value: "sans-serif", label: "sans-serif" },
        { value: "serif", label: "serif" },
        { value: "Anton", label: "Anton" },
        { value: "Audiowide", label: "Audiowide" },
        { value: "Baloo Bhai", label: "Baloo Bhai" },
        { value: "Francois One", label: "Francois" },
        { value: "Fjalla One", label: "Fjalla" },
        { value: "Germania One", label: "Germania" },
        { value: "Goblin One", label: "Goblin" },
        { value: "Kreon", label: "Kreon" },
        { value: "Lobster", label: "Lobster" },
        { value: "Overpass", label: "Overpass" },
        { value: "Quantico", label: "Quantico" },
        { value: "Racing Sans One", label: "Racing Sans" },
        { value: "Ramabhadra", label: "Ramabhadra" },
        { value: "Roboto", label: "Roboto" },
        { value: "Rubik", label: "Rubik" },
        { value: "Sansita", label: "Sansita" },
        { value: "Squada One", label: "Squada" },
        { value: "Staatliches", label: "Staatliches" },
        { value: "Ubuntu Condensed", label: "Ubuntu" },
    ],

    lineCaps: [
        { value: "butt", label: "butt" },
        { value: "round", label: "round" },
        { value: "square", label: "square" },
    ],


    detectBrowser: () => {
        var sBrowser, sUsrAg = navigator.userAgent;

        if (sUsrAg.indexOf("Firefox") > -1) {
            sBrowser = "Mozilla Firefox";
            // "Mozilla/5.0 (X11; Ubuntu; Linux x86_64; rv:61.0) Gecko/20100101 Firefox/61.0"
        } else if (sUsrAg.indexOf("Opera") > -1 || sUsrAg.indexOf("OPR") > -1) {
            sBrowser = "Opera";
            //"Mozilla/5.0 (Macintosh; Intel Mac OS X 10_14_0) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/70.0.3538.102 Safari/537.36 OPR/57.0.3098.106"
        } else if (sUsrAg.indexOf("Trident") > -1) {
            sBrowser = "Microsoft Internet Explorer";
            // "Mozilla/5.0 (Windows NT 10.0; WOW64; Trident/7.0; .NET4.0C; .NET4.0E; Zoom 3.6.0; wbx 1.0.0; rv:11.0) like Gecko"
        } else if (sUsrAg.indexOf("Edge") > -1) {
            sBrowser = "Microsoft Edge";
            // "Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/58.0.3029.110 Safari/537.36 Edge/16.16299"
        } else if (sUsrAg.indexOf("Chrome") > -1) {
            sBrowser = "Google Chrome or Chromium";
            // "Mozilla/5.0 (X11; Linux x86_64) AppleWebKit/537.36 (KHTML, like Gecko) Ubuntu Chromium/66.0.3359.181 Chrome/66.0.3359.181 Safari/537.36"
        } else if (sUsrAg.indexOf("Safari") > -1) {
            sBrowser = "Apple Safari";
            // "Mozilla/5.0 (iPhone; CPU iPhone OS 11_4 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/11.0 Mobile/15E148 Safari/604.1 980x1306"
        } else {
            sBrowser = "unknown";
        }
        return sBrowser;
    },

    shallowCopy(obj) {
        var result = {};
        for (var i in obj) {
            result[i] = obj[i];
        }
        return result;
    },

    stub: (name) => {
        let stub = name.toLowerCase();
        stub = stub.replace(new RegExp(' ', 'g'), '_');
        stub = stub.split('/')[0];
        stub = stub.replace(/\W/g, '');
        return stub;
    },

    objectHasData: (obj, ignoreBlankStrings) => {
        var arr = [];

        function diveIntoObj(obj) {
            Object.keys(obj).forEach(function (key) {
                if (obj.hasOwnProperty(key)) {
                    if (Array.isArray(obj[key])) {
                        diveIntoArray(obj[key]);
                    }
                    else {
                        if (typeof obj[key] === 'object') {
                            diveIntoObj(obj[key]);
                        }
                        else {
                            if (typeof obj[key] === 'string' || typeof obj[key] === 'number' || typeof obj[key] === 'boolean') {
                                arr.push(obj[key]);
                            }
                        }
                    }
                }
            });
        }

        function diveIntoArray(arrObj) {
            arrObj.forEach(function (item) {
                if (Array.isArray(item)) {
                    diveIntoArray(item);
                }
                else {
                    if (typeof item === 'object') {
                        diveIntoObj(item);
                    }
                    else {
                        if (typeof item === 'string' || typeof item === 'number' || typeof item === 'boolean') {
                            arr.push(item);
                        }
                    }
                }
            });
        }

        if (Array.isArray(obj)) {
            diveIntoArray(obj);
        }
        if (typeof obj === 'object') {
            diveIntoObj(obj);
        }

        if (arr.length === 0) {
            return false;
        }

        if (ignoreBlankStrings === true) {
            for (let i = 0; i < arr.length; i++) {
                if (typeof arr[i] === 'string') {
                    if (arr[i] !== '') {
                        return true;
                    }
                }
                else {
                    return true; // number or boolean present will always cause fn to return true.
                }
            }
            return false;
        }

        return true;
    },

    formatDate: (date) => {
        var d = new Date(date),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();

        if (month.length < 2) month = '0' + month;
        if (day.length < 2) day = '0' + day;

        return [year, month, day].join('-');
    },

    isValidDateFormat: (dateString) => {
        if (typeof dateString === "undefined" || typeof dateString !== 'string') {
            return false;
        }
        if (dateString === '') {
            return false;
        }
        let regEx = /^\d{4}-\d{2}-\d{2}$/;
        return dateString.match(regEx) != null;
    },

    cleanUrl: (cleanUrl, params) => {
        if (typeof params === 'string' || typeof params === 'number') {
            cleanUrl += '/' + params;
        }
        else {
            for (var key in params) {
                let paramValue = params[key];
                if ((typeof paramValue === 'string' || typeof paramValue === 'number') && paramValue !== '') {
                    cleanUrl += '/' + paramValue;
                }
                else {
                    break; // break as soon as we get something invalid. We can't build a url with missing params in front.
                }
            }
        }

        window.history.pushState({ urlPath: cleanUrl }, "", cleanUrl);
    },

    getKeyValue: (obj, ...keys) => {
        if (!keys) return false;
        if (Array.isArray(keys) === false) return false;
        if (keys.length === 0) return false;
        let currentObj = obj;
        while (keys.length > 0) {
            let key = keys.shift();
            if (currentObj.hasOwnProperty(key) === false) {
                return false;
            }
            if (keys.length === 0) {
                return currentObj[key];
            }
            currentObj = currentObj[key];
        }
        return false;
    },

    queryStringParse: (string) => {
        let parsed = {}
        if (string !== '') {
            string = string.substring(string.indexOf('?') + 1)
            let p1 = string.split('&')
            p1.forEach(function (value) {
                let params = value.split('=');
                parsed[params[0]] = params[1];
            });
        }
        return parsed;
    },

    setCookie: (name, value, days) => {
        var expires = "";
        if (days) {
            var date = new Date();
            date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
            expires = "; expires=" + date.toUTCString();
        }
        document.cookie = name + "=" + (value || "") + expires + "; path=/";
    },

    getCookie: (name) => {
        var nameEQ = name + "=";
        var ca = document.cookie.split(';');
        for (var i = 0; i < ca.length; i++) {
            var c = ca[i];
            while (c.charAt(0) === ' ') c = c.substring(1, c.length);
            if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length);
        }
        return null;
    },

    eraseCookie: (name) => {
        document.cookie = name + '=; Max-Age=-99999999;';
    },

    hashCode: (str) => {
        if (!str) {
            return 0;
        }
        return str.split('').reduce((prevHash, currVal) =>
            (((prevHash << 5) - prevHash) + currVal.charCodeAt(0)) | 0, 0);
    },

    slugify: (str) => {
        if (!str) {
            return 0
        }
        return Utility.replaceAll(str, ' ', '_')
    },


}

export default Utility;
