import React, { Component } from 'react';
import Utility from '../../Utility';
import './LayerDragManager.scss';

export default class LayerDragManager extends Component {

    state = {
    }

    vars = { slotsArray: [], dragCursorOffset: { x: 0, y: 0 }, activeSlotId: null }

    clickEye = (layerKey) => {
        this.props.layerVisibleToggle(layerKey)
    }

    layerVisible = (layerName) => {
        if (!layerName) {
            return;
        }
        let eyesOff = this.state.eyesOff;
        if (eyesOff.indexOf(layerName) > -1) {
            return false;
        }
        return true;
    }

    allowDrop = (evt) => {
        evt.preventDefault();
        evt = evt || window.event;
        var x = evt.pageX,
            y = evt.pageY;
        x -= this.vars.dragCursorOffset.x;
        y -= this.vars.dragCursorOffset.y;
        let activeSlot = this.checkActiveDropSlot(x, y);
        this.manageIsOverSlots(activeSlot);
    }

    manageIsOverSlots = (activeSlot) => {
        let dropSlots = this.vars.dropSlots;
        if (dropSlots) {
            //this.vars.activeSlotId = null;
            for (var i = 0; i < dropSlots.length; i++) {
                let dropSlot = dropSlots[i];
                if (activeSlot && dropSlot.id === activeSlot.id) {
                    this.vars.activeSlotId = activeSlot.id;
                    if (!dropSlot.element.classList.contains('is-over')) {
                        dropSlot.element.classList.add('is-over');
                    }
                }
                else {
                    if (dropSlot.element.classList.contains('is-over')) {
                        dropSlot.element.classList.remove('is-over');
                    }
                }
            }
        }
    }

    deactivateIsOverSlots = () => {
        let dropSlots = this.vars.dropSlots;
        for (var i = 0; i < dropSlots.length; i++) {
            let dropSlot = dropSlots[i];
            dropSlot.element.classList.remove('is-over');
        }
    }

    checkActiveDropSlot = (x, y) => {
        let dropSlots = this.vars.dropSlots;
        if (dropSlots && Array.isArray(dropSlots)) {
            for (var i = 0; i < dropSlots.length; i++) {
                let dropSlot = dropSlots[i];
                let positionY = dropSlot.position.top;
                if (y > positionY - 20 && y < positionY + 1) {
                    return dropSlot;
                }
            }
        }

        return null;
    }

    drag = (ev) => {
        this.vars.draggingId = ev.target.id
        ev.dataTransfer.setData("text", ev.target.id);
        this.getDropSlots();
        let findLayerId = ev.target.id;
        let ele = document.getElementById(findLayerId);
        if (ele) {
            var rect = ele.getBoundingClientRect();
            let offX = ev.pageX - rect.left
            let offY = ev.pageY - rect.top
            let cursorOffsetFromTopLeft = {
                x: offX,
                y: offY
            }
            this.vars.dragCursorOffset = cursorOffsetFromTopLeft;
        }
    }

    drop = (ev) => {
        this.deactivateIsOverSlots()
        ev.preventDefault();
        ev.stopPropagation();
        return false;
    }

    getDropSlots = () => {
        let dropSlots = [];
        let dropSlotElements = document.getElementsByClassName('drop-slot');
        for (var i = 0; i < dropSlotElements.length; i++) {
            var rect = dropSlotElements[i].getBoundingClientRect();
            var position = {
                top: rect.top + window.pageYOffset,
                left: rect.left + window.pageXOffset,
                bottom: rect.bottom + window.pageYOffset,
                right: rect.right + window.pageXOffset
            };
            dropSlots.push({ id: dropSlotElements[i].id, element: dropSlotElements[i], position: position });
        }
        this.vars.dropSlots = dropSlots;
    }

    dragEnd = (evt) => {
        let layersLength = this.props.layers.length
        let fromIndex = Utility.getIntFromString(this.vars.draggingId)
        let toIndex = Utility.getIntFromString(this.vars.activeSlotId)
        fromIndex = layersLength - fromIndex
        toIndex = layersLength - toIndex
        fromIndex--
        toIndex--
        this.props.layerReorder(fromIndex, toIndex);
        this.deactivateIsOverSlots()
    }

    layerClick = (layerKey) => {
        this.props.setIndicateKey(layerKey)
    }

    render = () => {
        if (!this.props.layers) {
            return (<div />)
        }
        if (this.props.layers && Array.isArray(this.props.layers) && this.props.layers.length > 0) {
            let reversedLayers = JSON.parse(JSON.stringify(this.props.layers)).reverse()
            return (
                <div className="layer-drag-manager">

                    <div className="layer-drag-manager-layers" onDrop={this.drop} onDragOver={this.allowDrop}>
                        <div className="header-names">
                            <div className="header-name-eye"></div>
                            <div className="header-name-name">name</div>
                        </div>
                        <div className="layer-drag-manager-layer">
                            <div id={'dropSlot0'} className="drop-slot">&nbsp;</div>
                            <div className="under-drop-slot">&nbsp;</div>
                        </div>
                        {reversedLayers.map((layer, index) => {
                            if (layer === null) {
                                return (<div key={index} />)
                            }
                            if (layer.name === 'Counter background color') {
                                return (<div key={index} className="display-none"></div>)
                            }

                            return (
                                <div key={index} className="layer-drag-manager-layer">
                                    <div className={layer.visible ? 'layer-eye' : 'layer-eye hidden'} onClick={() => this.clickEye(layer.key)}>
                                        <svg width="22" height="22" viewBox="0, 10, 64, 28">
                                            <path d="M2,20 C32,-20  62,20 62,20 C32,60 2,20 2,20" fill="none" stroke="#000" strokeWidth="4" strokeLinecap="round" />
                                            <circle cx="31.5" cy="20" r="10" stroke="black" strokeWidth="8" fill={layer.visible ? '#ffffff' : '#444'} />
                                        </svg>
                                    </div>
                                    <div id={'layer' + (index)} draggable="true" onDragStart={this.drag} onDragEnd={this.dragEnd} className="layer-drag-manager-layer-name" onClick={() => this.layerClick(layer.key)}>
                                        <span>{layer.name}</span>
                                        <span>{layer.layerType}</span>
                                    </div>
                                    <div id={'dropSlot' + (index + 1)} className="drop-slot"></div>
                                    <div className="under-drop-slot">&nbsp;</div>
                                </div>
                            )
                        }

                        )

                        }
                    </div>
                </div>
            )
        }
        else {
            return <div />
        }
    }

}