import React, { Component } from 'react';
import Counter from "./Counter/Counter";
import './Counters.scss';

export default class Counters extends Component {

    state = {
    }

    componentDidMount = () => {

    }

    componentDidUpdate = () => {

    }

    render = () => {
        return (
            <div className="counters">
                {this.props.savedCounters.length > 0 ? this.props.savedCounters.map((counter, index) => counter ?
                    <Counter
                    key={index}
                    index={index}
                    counter={counter}
                    loadCounterFromDb={this.props.loadCounterFromDb}
                    deleteCounterFromDb={this.props.deleteCounterFromDb}
                    /> : '') : ''}
            </div>
        )
    }
}