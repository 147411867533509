
import React, { Component } from 'react';
import Utility from '../../Utility';
import IndexedDb from '../../IndexedDb';
import colorPicker from "../../images/colorpicker2.png";
import CounterDesign from "../CounterDesign/CounterDesign";
import PageControl from "../PageControl/PageControl";
import TabManager from "../TabManager/TabManager";
import LayerDragManager from "../LayerDragManager/LayerDragManager";
import Layers from "../Layers/Layers";
import Counters from "../Counters/Counters";
import CounterLoadedSavedInfo from "../CounterLoadedSavedInfo/CounterLoadedSavedInfo";
import Log from "../Log/Log";
import JSZip from 'jszip'
import { saveAs } from 'file-saver';
import './SnapCounter.scss';

export default class SnapCounter extends Component {

    state = {
        colorPicked: '',

        s: null,
        colorPickerContext: null,
        focusOn: null,
        counterElements: {},
        layers: [],

        counterSize: "25.4mm",

        marginLeft: 4,
        marginRight: 4,
        marginTop: 3.3,
        marginBottom: 3.3,
        spacingX: 0.5,
        spacingY: 0.5,

        showOverlay: false,
        getCounterLayers: false,
        getCounterElements: false,
        counterLayers: null,
        defs: [],
        defIds: [],
        printVersionActive: false,
        savedCounterAvailable: false,
        counterNamesWide: false,
        storageUsed: 0,
        pageCounterSlotIdSelected: 0,
        sheetHasSomething: false,
        saveSheetName: 'sheet1',
        savedSheetsAvailable: [],
        savedSheetNames: [],
        loadSheetMode: false,
        loadSheetObj: null,
        sheetIsFull: false,
        saveSheetMode: false,

        counterHasSomething: false,
        saveCounterName: 'counter1',
        savedCountersAvailable: 0,
        savedCounterKeysAndNames: [],
        saveLoadCounterMode: 'none',
        selectedCounterName: '',
        loadCounterObj: null,

        tabIndex: 0,

        pendingAddLayerType: false,
        newLayerName: '',
        newLayerNameAlreadyExists: false,
        overlayOn: false,
        pageControlCounterMouseActivity: { slotId: '', clicked: false, dragging: false, dropped: false },

        counterOptionsMode: false,
        slotsInfo: { numSlots: 0, numOccupiedSlots: 0, numAvailable: 0, numOccupiedSlotsOverflow: 0 },
        errorSettingUpDatabase: false,
        clearIndicate: false,

        exportCounterToFile: false,
        exportSheetToFile: false,
        counterLoadedSavedInfo: { type: 'none', name: '', date: '' },
        sheetLoadedSavedInfo: { type: 'none', name: '', date: '' },
        indexedDbOpened: false,
        savedCounters: [],
        logMessage: '',
        loadFromFileError: false,
    }

    vars = { isMounted: false, timeout1: 0, timeout2: 0, timeout3: 0, timeout4: 0, eventsHistory: [] }

    eventsHistoryPush = (event) => {
        this.vars.eventsHistory.unshift(event)
        while (this.vars.eventsHistory.length > 10) {
            this.vars.eventsHistory.pop()
        }
        if (event !== 'focusColor') {
            let inputs = document.getElementsByTagName('input');
            for (let i = 0; i < inputs.length; ++i) {
                // deal with inputs[index] element.
                inputs[i].classList.remove('color-focused');
            }
        }
    }

    eventsHistoryGet = () => {
        return this.vars.eventsHistory
    }

    componentDidMount = async () => {

        this.vars.isMounted = true;
        this.setupColorPicker();
        this.pageControl = React.createRef();
        this.counterDesign = React.createRef();
        this.refLayers = React.createRef();
        this.refSaveSheetAs = React.createRef();
        this.refSaveCounterName = React.createRef();

        // get rid of any map from snapmap
        let existingMapEle = document.getElementById('thesvg')
        if (existingMapEle) {
            existingMapEle.parentNode.removeChild(existingMapEle);
        }

        this.logMessage('trying to open database')
        IndexedDb.setLogRef(this.logMessage)
        await IndexedDb.open()
        this.logMessage('database opened')
        this.setState({ indexedDbOpened: true })

        this.restorePageParamsForCurrentSheet()
        let successGettingData = false
        this.vars.timeout4 = setTimeout(function () {
            if (successGettingData === false) {
                this.setState({ errorSettingUpDatabase: true })
                this.logMessage('there was an error getting data from the database')
            }
        }.bind(this), 5000);

        // see if we have layers in the db. If so, just load them into state.
        let validLayers = []
        let that = this
        IndexedDb.getAllData('layer').then(layers => {
            successGettingData = true
            that.logMessage('validating layers')
            
            layers.forEach(layer => {
                if (layer.hasOwnProperty('indicate') &&
                    layer.hasOwnProperty('key') &&
                    layer.hasOwnProperty('layerType') &&
                    layer.hasOwnProperty('minimized') &&
                    layer.hasOwnProperty('visible')) {
                    validLayers.push(layer)
                }
                else {
                    if (Utility.isObject(layer)) {
                        that.logMessage('invalid layer: ' + JSON.stringify(layer))
                    }
                    else {
                        that.logMessage('invalid layer: ' + layer)
                    }
                }
            })
            clearTimeout(this.vars.timeout4)
            if (validLayers.length > 0) {
                this.logMessage('loading ' + validLayers.length + ' layers')
                this.setState({ layers: validLayers }, () => this.storageUsed())
            }
            else {
                // otherwise, tell the Layers component to create the default layers
                this.logMessage('no layers found in the database')
                if (this.vars.timeout2 === 0) {
                    this.vars.timeout2 = setTimeout(function () {
                        this.logMessage('loading default layers')
                        if (this.refLayers.current) {
                            this.refLayers.current.loadDefaultLayers()
                        }
                        else {
                            console.log('cannot load layers, there is no current refLayers')
                        }
                    }.bind(this), 100);
                }
            }
        });
        this.validateSheets()
        this.savedSheetsAvailable()
        setTimeout(() => {
            this.savedCountersAvailable()
            if (this.pageControl.current.slotsInfo) {
                let slotsInfo = this.pageControl.current.slotsInfo()
                if (slotsInfo.numOccupiedSlots === 0) {
                    this.logMessage('loading slots, number of slots: ' + slotsInfo.numSlots + ' slots')
                }
                else {
                    this.logMessage('loading slots, number of slots: ' + slotsInfo.numSlots + ', ' +
                        'occupied slots: ' + slotsInfo.numOccupiedSlots + ', ' +
                        'available slots: ' + slotsInfo.numAvailable + ', ' +
                        'slots in overflow: ' + slotsInfo.numOccupiedSlotsOverflow
                    )
                }
                if (JSON.stringify(this.state.slotsInfo) !== JSON.stringify(slotsInfo)) {
                    this.setState({ slotsInfo })
                }
            }
        }, 500)

        var ele = document.getElementById("settingsDiv");
        if (ele) {
            this.logMessage('attaching event handlers on the settings')
            ele.addEventListener("focus", () => this.settingsDivFocus(true), true);
            ele.addEventListener("blur", () => this.settingsDivFocus(false), true);
        }

        // for detecting click on scrollbar
        window.addEventListener("mousedown", function (event) {
            if (event.target.clientWidth) {
                if (event.offsetX > event.target.clientWidth || event.offsetY > event.target.clientHeight) {
                    this.setState({ clearIndicate: true }, () => {
                        this.setState({ clearIndicate: false })
                    })
                }
            }
        }.bind(this))

    }

    validateSheets = async () => {
        this.logMessage('validating sheets')
        let success = true
        let sheets = await IndexedDb.getAllData('sheet')
        let validSheets = []
        sheets.forEach(sheet => {
            if (sheet.hasOwnProperty('counterSize') && sheet.counterSize !== '' &&
                sheet.hasOwnProperty('date') && sheet.date !== '' &&
                sheet.hasOwnProperty('marginBottom') && sheet.marginBottom >= 0 &&
                sheet.hasOwnProperty('marginLeft') && sheet.marginLeft >= 0 &&
                sheet.hasOwnProperty('marginRight') && sheet.marginRight >= 0 &&
                sheet.hasOwnProperty('marginTop') && sheet.marginTop >= 0 &&
                sheet.hasOwnProperty('name') && sheet.name !== '') {
                let slots = sheet.slots
                slots.forEach((slot, index) => {
                    let valid = true
                    if (slot.hasOwnProperty('counterFragments') === false ||
                        slot.hasOwnProperty('counterDefs') === false ||
                        slot.hasOwnProperty('counterLayers') === false ||
                        slot.hasOwnProperty('columnNumber') === false ||
                        slot.hasOwnProperty('height') === false ||
                        slot.hasOwnProperty('number') === false ||
                        slot.hasOwnProperty('rowNumber') === false ||
                        slot.hasOwnProperty('slotId') === false ||
                        slot.hasOwnProperty('width') === false ||
                        slot.hasOwnProperty('x') === false ||
                        slot.hasOwnProperty('y') === false) {
                        valid = false
                    }
                    if (valid === false) {
                        if (Utility.isObject(slot)) {
                            this.logMessage('slot is bad: ' + JSON.stringify(slot), 'warn')
                        }
                        else {
                            this.logMessage('slot is bad: ' + slot, 'warn')
                        }
                    }
                })

                validSheets.push(sheet)
            }
            else {
                success = false
                if (Utility.isObject(sheet)) {
                    this.logMessage('sheet is bad: ' + JSON.stringify(sheet), 'warn')
                }
                else {
                    this.logMessage('sheet is bad: ' + sheet)
                }
            }
        })

        if (success) {
            if (sheets.length === 0) {
                this.logMessage('there are no sheets')
            }
            else {
                this.logMessage(validSheets.length + ' sheets are ok')
            }
        }

        return validSheets
    }

    logMessage = (message, level) => {
        if (message) {
            this.setState({ logMessage: message })
        }
    }

    settingsDivFocus = (tf) => {
        if( this.counterDesign.current ) {
            this.counterDesign.current.counterDesignFocus(!tf)
        }
    }

    restorePageParamsForCurrentSheet = async () => {
        setTimeout(async () => {
            let key = await IndexedDb.getKeyForName('sheet', 'current')

            if (key === -1) {
                return
            }
            let sheetData = await IndexedDb.get('sheet', key)

            if (!sheetData) {
                return
            }

            let counterSize = sheetData.counterSize
            let marginLeft = sheetData.marginLeft
            let marginRight = sheetData.marginRight
            let marginTop = sheetData.marginTop
            let marginBottom = sheetData.marginBottom
            let spacingX = sheetData.spacingX
            let spacingY = sheetData.spacingY
            if (this.state.counterSize !== counterSize ||
                this.state.marginLeft !== sheetData.marginLeft ||
                this.state.marginRight !== sheetData.marginRight ||
                this.state.marginTop !== sheetData.marginTop ||
                this.state.marginBottom !== sheetData.marginBottom ||
                this.state.spacingX !== sheetData.spacingX ||
                this.state.spacingY !== sheetData.spacingY
            ) {
                this.setState({ counterSize, marginLeft, marginRight, marginTop, marginBottom, spacingX, spacingY })
            }
        }, 1200)
    }

    componentWillUnmount = () => {
        if (this.vars.timeout1 !== 0) {
            clearTimeout(this.vars.timeout1);
        }
        this.vars.timeout1 = 0;
        if (this.vars.timeout2 !== 0) {
            clearTimeout(this.vars.timeout2);
        }
        this.vars.timeout2 = 0;
        this.vars.isMounted = false;
    }

    onChange = (evt) => {
        let formName = evt.target.name;
        let formType = evt.target.type;
        let value = evt.target.value;
        //


        if (evt.target.hasOwnProperty('checked') && formType === 'checkbox') {
            value = evt.target.checked;
            this.setState({ [formName]: value })
            return
        }

        evt.preventDefault()
        if (formName === 'newLayerName') {
            this.setState({ newLayerName: value })
            return
        }
        if (formName === 'counterSize') {
            setTimeout(() => {
                this.setState({ counterSize: value })
            }, 10)
            return
        }

        // For some reason I had to be extremely specific, otherwise the states for these would not change. And I didn't want to spend an hour or two trying to figure out what is going on. Maybe I'm just having a brain fart and theres something I forgot about.
        if (formName === 'marginLeft') {
            this.setState({ marginLeft: value })
        }

        if (formName === 'marginRight') {
            this.setState({ marginRight: value })
        }

        if (formName === 'marginTop') {
            this.setState({ marginTop: value })
        }

        if (formName === 'marginBottom') {
            this.setState({ marginBottom: value })
        }

        if (formName === 'spacingX') {
            this.setState({ spacingX: value })
        }

        if (formName === 'spacingY') {
            this.setState({ spacingY: value })
        }

        if (formName === 'saveSheetName') {
            value = value.replace(/\//g, "-")
            value = value.replace(/\\/g, "-")
            value = value.replace(/</g, "-")
            value = value.replace(/>/g, "-")
            value = value.replace(/"/g, "-")
            value = value.replace(/\?/g, "-")
            value = value.replace(/\*/g, "-")
            if(value.indexOf('|') > -1 ) {
                value = value.replace('|','-')
            }
            this.setState({ saveSheetName: value })
        }

        if (formName === 'saveCounterName') {
            value = value.replace(/\//g, "-")
            value = value.replace(/\\/g, "-")
            value = value.replace(/</g, "-")
            value = value.replace(/>/g, "-")
            value = value.replace(/"/g, "-")
            value = value.replace(/\?/g, "-")
            value = value.replace(/\*/g, "-")
            if(value.indexOf('|') > -1 ) {
                value = value.replace('|','-')
            }
            this.setState({ saveCounterName: value })
        }

        this.storageUsed()
    }

    setupColorPicker = () => {
        this.logMessage('setting up color picker')
        var canvas = document.getElementById('colorPickerCanvas');
        const context = canvas.getContext('2d');
        var image = new Image();
        image.src = colorPicker;
        image.onload = function () { // 534 268
            context.drawImage(image, 0, 0, 550, 268);
        };
        this.setState({ colorPickerContext: context });


        canvas.addEventListener("click", function (event) {
            this.eventsHistoryPush('colorPicker')
            var eventLocation = Utility.getEventLocation(this, event);
            var context = this.state.colorPickerContext;
            var container = document.getElementById("colorPickerCanvas");
            let currentWidth = container.offsetWidth;
            let currentHeight = container.offsetHeight;
            var fx = 550 / (currentWidth);
            var fy = 268 / currentHeight;
            let posX = (eventLocation.x * fx);
            let posY = (eventLocation.y * fy);
            var pixelData = context.getImageData(posX, posY, 1, 1).data;
            var hex = "#" + ("000000" + Utility.rgbToHex(pixelData[0], pixelData[1], pixelData[2])).slice(-6);
            this.refLayers.current.colorPicked(hex);
        }.bind(this), false);
    }

    onFocus = (evt) => {
        // let t = (u) => (u * 3.8) + 40;
        let formName = evt.target.name;
        this.setState({ focusOn: formName });
    }

    toggleOverlay = () => {
        let overlayState = this.counterDesign.current.toggleOverlay()
        this.setState({ overlayOn: overlayState })
    }

    cancelCounterMouseActivity = () => {
        this.pageControl.current.cancelCounterMouseActivity()
    }

    pageControlCounterMouseActivity = (obj) => {
        if (obj) {
            if (obj.slotId && obj.clicked) {
                this.setState({ counterOptionsMode: true, pageControlCounterMouseActivity: obj })
            }
            else {
                this.setState({ counterOptionsMode: false, pageControlCounterMouseActivity: obj })
            }
        }
        else {
            this.setState({ counterOptionsMode: false, pageControlCounterMouseActivity: { slotId: '', clicked: false, dragging: false, dropped: false } })
        }
    }

    clickOnMoveToSheet = () => {
        let counterLayers = this.counterDesign.current.getCounterLayers()
        counterLayers = counterLayers.slice(0)
        for (var i = 0; i < counterLayers.length; i++) {
            counterLayers[i] = Object.assign({}, counterLayers[i]);
        }
        let counterSvgFragmentsAndDefs = this.counterDesign.current.getCounterSvgFragmentsAndDefs();
        this.logMessage('moving counter to sheet')
        this.pageControl.current.drawCounter(counterLayers, counterSvgFragmentsAndDefs.fragments, counterSvgFragmentsAndDefs.defs)
    }

    getCircularReplacer = () => {
        const seen = new WeakSet();
        return (key, value) => {
            if (typeof value === "object" && value !== null) {
                if (seen.has(value)) {
                    return;
                }
                seen.add(value);
            }
            return value;
        };
    }

    print = () => {
        this.pageControl.current.print();
    }

    pageCounterClicked = (slotId) => {
        this.setState({ pageCounterSlotIdSelected: slotId });
    }

    setIndicateKey = (layerKey) => {
        if (!layerKey) {
            return
        }
        let layer = this.state.layers.find(layer => layer.key === layerKey)
        layer.indicate = true
        IndexedDb.put('layer', layer, layer.key).then(() => {
            this.dbLayerUpdated()
        })
        if (this.vars.timeout3 > 0) {
            clearTimeout(this.vars.timeout3)
            this.vars.timeout3 = 0
        }
        this.vars.timeout3 = setTimeout(() => {
            if (this.vars.isMounted) {
                layer.indicate = false
                this.vars.timeout3 = 0
                IndexedDb.put('layer', layer, layer.key).then(() => {
                    this.dbLayerUpdated()
                })
            }
        }, 200)
    }

    editSelectedCounter = async () => {
        if (this.state.pageControlCounterMouseActivity.slotId) {
            let layers = await this.pageControl.current.getSelectedCounterLayers(this.state.pageControlCounterMouseActivity.slotId);
            if (layers) {
                this.logMessage('edit counter from sheet')
                let keys = await IndexedDb.getAllKeys('layer')
                if (keys && Array.isArray(keys) && keys.length > 0) {
                    for (let i = 0; i < keys.length; i++) {
                        let key = keys[i]
                        await IndexedDb.deleteKey('layer', key)
                    }
                }
                for (let i = 0; i < layers.length; i++) {
                    let layer = layers[i]

                    if (layer.name === 'Counter background color') {
                        if (layer.inputs.length === 1) {
                            layer.inputs.push({
                                className: "checkbox",
                                displayRow: 1,
                                label: "bleed outside counter",
                                name: "bleed",
                                type: "checkbox",
                                value: false
                            })
                            layer.inputs.push({
                                className: "input-text-4",
                                displayRow: 1,
                                label: "bleed amount (1%-10%)",
                                name: "bleedAmount",
                                type: "text",
                                value: "0"
                            })
                        }
                    }

                    let newKey = await IndexedDb.put('layer', layer);
                    layer.key = newKey
                    await IndexedDb.put('layer', layer, newKey)
                }
                this.setState({ layers: layers }, () => {
                })

                //turn off the edit mode thing
                this.pageControl.current.setCounterMouseActivity()
            }
        }
    }

    deleteCounter = () => {
        if (this.state.pageControlCounterMouseActivity.slotId) {
            this.logMessage('deleting counter from sheet')
            this.pageControl.current.deleteCounter(this.state.pageControlCounterMouseActivity.slotId);
        }
    }

    cancelSelectedCounter = () => {
        this.pageControl.current.cancelSelectedCounter();
    }

    printSlots = () => {
        this.logMessage('printing sheet')
        this.pageControl.current.printSlots();
    }

    sheetHasSomething = (tf) => {
        this.setState({ sheetHasSomething: tf });
    }

    savedSheetsAvailable = () => {
        IndexedDb.getAllNames('sheet').then((savedSheetsAvailable) => {
            let savedSheetsWithoutCurrent = savedSheetsAvailable.filter((sheetName) => sheetName !== 'current')
            if (JSON.stringify(savedSheetsWithoutCurrent) !== JSON.stringify(this.state.savedSheetsAvailable)) {
                this.setState({ savedSheetsAvailable: savedSheetsWithoutCurrent })
            }
        })

    }

    savedCountersAvailable = () => {
        this.logMessage('loading counters from database')
        let savedCounterKeysAndNames = [];
        IndexedDb.getAllKeys('counter').then(async (counterKeys) => {
            if (counterKeys) {
                let maxLength = 0
                for (var i = 0; i < counterKeys.length; i++) {
                    let key = counterKeys[i];
                    if (key && key > 0) {
                        let data = await IndexedDb.get('counter', key)
                        if (data &&
                            data.hasOwnProperty('name') &&
                            data.hasOwnProperty('key') &&
                            data.hasOwnProperty('layers') &&
                            data.hasOwnProperty('svg') &&
                            data.layers.length > 0 &&
                            data.hasOwnProperty('date')) {
                            let counterName = data.name;
                            if (counterName.length > maxLength) {
                                maxLength = counterName.length
                            }
                            savedCounterKeysAndNames.push({ key: key, name: counterName });
                        }
                        else {
                            if (Utility.isObject(data)) {
                                this.logMessage('invalid counter: ' + JSON.stringify(data))
                            }
                            else {
                                this.logMessage('invalid counter: ')
                                this.logMessage(data)
                            }
                        }
                    }
                }
                let counterNamesWide = maxLength > 15 ? true : false  // for long counter names, lets make the select dropdown alot wider
                this.setState({ savedCountersAvailable: savedCounterKeysAndNames.length, savedCounterKeysAndNames: savedCounterKeysAndNames, countersNamesWide: counterNamesWide });
                IndexedDb.getAllData('counter').then(savedCounters => {
                    let validatedCounters = []
                    this.logMessage('validating counters')
                    savedCounters.forEach(counter => {
                        if (
                            counter.hasOwnProperty('name') &&
                            counter.name.indexOf("\\") === -1 &&
                            counter.name.indexOf("/") === -1 &&
                            counter.hasOwnProperty('key') &&
                            counter.hasOwnProperty('layers') &&
                            counter.layers.length > 0 &&
                            counter.hasOwnProperty('date') &&
                            counter.hasOwnProperty('svg')) {
                            this.logMessage('counter ' + counter.name + ' is valid')
                            validatedCounters.push(counter)
                        }
                        else {
                            this.logMessage('counter ' + counter.name + ' is not valid: ' + JSON.stringify(counter))
                        }
                    })
                    this.setState({ savedCounters: validatedCounters })
                    this.logMessage('Number of counters loaded: ' + savedCounters.length)
                })
            }
        });
    }

    storageUsed = () => {
        if (this.state.layers && Array.isArray(this.state.layers) && this.state.layers.length > 0) {
            IndexedDb.storageUsed(this.reportStorageUsed);
        }
    }

    reportStorageUsed = storageUsed => {
        let totalMB_used = 0;
        let totalB_used = 0;
        let reportSize = 'none';
        storageUsed.forEach(row => {
            totalMB_used += Utility.roundFloat(parseFloat(row['MSize'], 2));
            totalB_used += parseInt(row['BSize']);
        });
        if (totalMB_used >= 1) {
            reportSize = totalMB_used + 'MB';
        }
        else {
            reportSize = totalB_used + ' bytes';
        }
        if (this.state.storageUsed !== reportSize) {
            this.setState({ storageUsed: reportSize });
        }
    }

    getCounterKeyForName = (name) => {
        let savedCounterKeysAndNames = this.state.savedCounterKeysAndNames;
        for (var i = 0; i < savedCounterKeysAndNames.length; i++) {
            if (savedCounterKeysAndNames[i].name === name) {
                return savedCounterKeysAndNames[i].key;
            }
        }

        return null;
    }

    saveSheetMode = (tf) => {
        this.pageControl.current.setCounterMouseActivity()
        this.setState({ saveSheetMode: tf });
        if (tf) {
            setTimeout(() => this.refSaveSheetAs.current.focus())
            this.setState({ saveSheetMode: true })
        }
        else {
            if (this.state.loadFromFileError === true) {
                this.setState({ loadFromFileError: false })
            }
        }
    }

    saveSheet = async () => {
        let userSuppliedSheetName = this.state.saveSheetName;
        this.logMessage('saving sheet: ' + userSuppliedSheetName)
        if (userSuppliedSheetName.length > 0) {
            this.saveSheetMode(false)
            let sheetKey = await this.pageControl.current.saveSheetToDb(userSuppliedSheetName)
            setTimeout(() => this.savedSheetsAvailable(), 1000)

            if (this.state.exportSheetToFile) {
                this.logMessage('exporting sheet to file')
                let data = await IndexedDb.get('sheet', sheetKey)
                var zip = new JSZip();
                zip.file(userSuppliedSheetName + ".json", JSON.stringify(data));
                zip.generateAsync({ type: "blob", compression: "DEFLATE" })
                    .then(function (content) {
                        // see FileSaver.js
                        saveAs(content, userSuppliedSheetName + '.zip');
                    });


            }
        }
    }

    updatePageParams = (counterSize, marginLeft, marginRight, marginTop, marginBottom, spacingX, spacingY) => {
        this.setState({ counterSize, marginLeft, marginRight, marginTop, marginBottom, spacingX, spacingY })
    }

    sheetWasSaved = () => {
        this.savedSheetsAvailable()
        if (this.pageControl.current) {
            let slotsInfo = this.pageControl.current.slotsInfo()
            if (JSON.stringify(this.state.slotsInfo) !== JSON.stringify(slotsInfo)) {
                this.setState({ slotsInfo })
            }
        }
    }

    focusAddLayerInput() {
        document.getElementById('addLayerInput').focus()
    }

    saveCounterClick = () => {
        this.saveLoadCounterMode('save')
    }

    saveCounter = async () => {
        let saveLayersUnderName = this.state.saveCounterName
        saveLayersUnderName = saveLayersUnderName.replace(/\//g, "-")
        saveLayersUnderName = saveLayersUnderName.replace(/\\/g, "-")
        this.logMessage('saving counter ' + saveLayersUnderName)
        if (saveLayersUnderName.trim() === '') {
            return
        }
        let existingKey = await IndexedDb.getKeyForName('counter', saveLayersUnderName);
        var today = new Date();
        var date = today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate();
        var time = today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
        var dateTime = date + ' ' + time;
        let svg = this.counterDesign.current.getCounterSvgFragmentsAndDefs();
        if (svg && Utility.isObject(svg) && svg.hasOwnProperty('fragments') && svg.fragments.length > 0 &&
            this.state.layers && this.state.layers.length > 0) {
            let saveObj = {
                name: saveLayersUnderName,
                date: dateTime,
                layers: this.state.layers,
                svg: svg
            }
            if (existingKey > -1) {
                saveObj.key = existingKey
                await IndexedDb.put('counter', saveObj, existingKey);
            }
            else {
                let newKey = await IndexedDb.put('counter', saveObj);
                saveObj.key = newKey
                IndexedDb.put('counter', saveObj, newKey).then(() => {
                    setTimeout(() => {
                        IndexedDb.getAllData('counter').then(savedCounters => {
                            let validatedCounters = []
                            savedCounters.forEach(counter => {
                                if (
                                    counter.hasOwnProperty('name') &&
                                    counter.hasOwnProperty('key') &&
                                    counter.hasOwnProperty('layers') &&
                                    counter.layers.length > 0 &&
                                    counter.hasOwnProperty('date') &&
                                    counter.hasOwnProperty('svg')) {
                                    validatedCounters.push(counter)
                                }
                            })
                            this.setState({ savedCounters: validatedCounters })
                        })
                    }, 100)
                })

            }
        }
        else {
            this.logMessage('cannot save counter, bad format')
            if (!svg) {
                this.logMessage('no svg')
            }
            else {
                if (Utility.isObject(svg)) {
                    this.logMessage('svg: ' + JSON.stringify(svg))
                }
                else {
                    this.logMessage('svg: ' + svg)
                }
            }
        }

        this.saveLoadCounterMode('none');
        this.savedCountersAvailable()
        this.storageUsed()

        if (this.state.exportCounterToFile) {
            this.logMessage('exporting counter to file')
            let layersOrder = this.getLayersOrder()
            IndexedDb.getKeyForName('counter', saveLayersUnderName).then(key => {
                IndexedDb.get('counter', key).then(data => {
                    let dataLayers = data.layers.filter(layer => {
                        let returnLayer = false
                        switch (layer.layerType) {
                            case 'counterBackgroundColor':
                                returnLayer = true
                                break
                            case 'unitSymbol':
                            case 'symbol':
                            case 'vehicle':
                            case 'images':
                            case 'unitSize':
                                if (layer.checkedValue !== 'none') {
                                    returnLayer = true
                                }
                                break
                            case 'text':
                                if (layer.inputs[0].value !== '') {
                                    returnLayer = true
                                }
                                break;
                            case 'line':
                                returnLayer = true
                                break
                            case 'circle':
                                if (layer.inputs.find(input => input.name === 'radius').value > 0) {
                                    returnLayer = true
                                }
                                break;
                            case 'ellipse':
                                if (layer.inputs.find(input => input.name === 'horizontalRadius').value > 0 &&
                                    layer.inputs.find(input => input.name === 'verticalRadius').value > 0
                                ) {
                                    returnLayer = true
                                }
                                break;
                            case 'rectangle':
                                if (layer.inputs.find(input => input.name === 'width').value > 0 &&
                                    layer.inputs.find(input => input.name === 'height').value > 0
                                ) {
                                    returnLayer = true
                                }
                                break;
                            case 'scaleneTriangle':
                                if (layer.inputs.find(input => input.name === 'radius').value > 0) {
                                    returnLayer = true
                                }
                                break;
                            case 'isoTriangle':
                                if (layer.inputs.find(input => input.name === 'length').value > 0) {
                                    returnLayer = true
                                }
                                break;
                            default:
                                returnLayer = true
                        }
                        if (returnLayer) {
                            return layer
                        }
                        return false
                    })


                    data.layers = dataLayers.map(layer => {
                        if (layer.hasOwnProperty('key')) {
                            delete layer.key
                        }
                        if (layer.hasOwnProperty('minimized')) {
                            delete layer.minimized
                        }
                        if (layer.hasOwnProperty('visible')) {
                            delete layer.visible
                        }
                        if (layer.hasOwnProperty('indicate')) {
                            delete layer.indicate
                        }
                        if (layer.hasOwnProperty('checkedValue')) {
                            layer.inputs = layer.inputs.filter(function (input) {
                                return input.type !== 'ButtonRadioSvg';
                            });
                        }
                        if (layer.hasOwnProperty('inputs')) {
                            layer.inputs.forEach(input => {
                                if (input.hasOwnProperty('className')) {
                                    delete input.className
                                }
                                if (input.hasOwnProperty('displayRow')) {
                                    delete input.displayRow
                                }
                            })
                        }
                        return layer
                    })
                    let dataPackage = { counter: data, layersOrder: layersOrder }
                    let file = new File([JSON.stringify(dataPackage)], saveLayersUnderName + ".json", { type: "text/plain;charset=utf-8" });
                    saveAs(file);
                })
            })
        }

    }

    saveLoadCounterMode = (noneSaveLoad) => {
        if (this.state.saveLoadCounterMode !== noneSaveLoad) {
            this.setState({ saveLoadCounterMode: noneSaveLoad }, () => {
                if (noneSaveLoad === 'save') {
                    this.refSaveCounterName.current.focus()
                }
                if (noneSaveLoad === 'load') {
                    setTimeout(function () {
                        let ele = document.getElementById('loadCounterSelect');
                        ele.selectedIndex = "0";
                    });
                }
            });
        }

    }

    deleteCounterFromDb = (key, name) => {
        if (key) {
            this.logMessage('deleting counter from database, key: ' + key)
        }
        else {
            this.logMessage('deleting counter from database, name: ' + name)
        }
        if (key === -1 || !key) {
            key = IndexedDb.getKeyForName('counter', name)
        }
        if (!key) {
            return
        }
        IndexedDb.deleteKey('counter', key).then(() => {
            IndexedDb.getAllData('counter').then(savedCounters => {
                let validatedCounters = []
                savedCounters.forEach(counter => {
                    if (
                        counter.hasOwnProperty('name') &&
                        counter.hasOwnProperty('key') &&
                        counter.hasOwnProperty('layers') &&
                        counter.layers.length > 0 &&
                        counter.hasOwnProperty('date') &&
                        counter.hasOwnProperty('svg')) {
                        validatedCounters.push(counter)
                    }
                })
                this.setState({ savedCounters: validatedCounters })
            })
        })
    }

    loadCounterFromDb = async (key, name) => {
        if (key) {
            this.logMessage('loading counter from database, key: ' + key)
        }
        else {
            this.logMessage('loading counter from database, name: ' + name)
        }
        if (key === -1 || !key) {
            key = IndexedDb.getKeyForName('counter', name)
        }
        if (!key) {
            return
        }
        let data = await IndexedDb.get('counter', key);
        if (data && data.hasOwnProperty('layers')) {
            let counterLoadedSavedInfo = { type: 'loaded', name: data.name, date: data.date }
            this.setState({ counterLoadedSavedInfo, saveCounterName: data.name })
            this.loadCounterFromPackage({ counter: data })
        }
    }

    loadCounterFromPackage = async (dataPackage) => {
        this.logMessage('loading counter from package: ' + dataPackage.counter.name)
        let layers = dataPackage.counter.layers
        let layersOrder = dataPackage.hasOwnProperty('layersOrder') ? dataPackage.layersOrder : null
        let counterName = dataPackage.counter.name
        let counterDate = dataPackage.counter.date
        let existingLayers = this.state.layers

        for (var eLayer of existingLayers) {
            // eslint-disable-next-line
            let find = layers.find(layer => layer.name === eLayer.name && layer.layerType === eLayer.layerType)
            if (!find) {
                let defaultLayer = this.refLayers.current.createLayerObject(eLayer.name, eLayer.layerType)
                let settings = { inputs: defaultLayer.inputs }
                if (defaultLayer.hasOwnProperty('checkedValue')) {
                    settings.checkedValue = defaultLayer.checkedValue
                }
                // some manual intervention needed for the custom types, since they come into existance on screen when created.
                switch (eLayer.layerType) {
                    case 'rectangle': settings.inputs.find(input => input.name === 'width').value = '0'
                        settings.inputs.find(input => input.name === 'height').value = '0'
                        break
                    case 'circle': settings.inputs.find(input => input.name === 'radius').value = '0'
                        break
                    case 'ellipse': settings.inputs.find(input => input.name === 'horizontalRadius').value = '0'
                        settings.inputs.find(input => input.name === 'verticalRadius').value = '0'
                        break
                    case 'line': settings.inputs.find(input => input.name === 'startX').value = '0'
                        settings.inputs.find(input => input.name === 'startY').value = '0'
                        settings.inputs.find(input => input.name === 'endX').value = '0'
                        settings.inputs.find(input => input.name === 'endY').value = '0'
                        break
                    case 'scaleneTriangle': settings.inputs.find(input => input.name === 'radius').value = '0'
                        break
                    case 'isoTriangle': settings.inputs.find(input => input.name === 'length').value = '0'
                        break
                    default:
                }

                await this.refLayers.current.updateLayer(eLayer, settings);
            }
        }

        for (var layer of layers) {
            let settings = { inputs: layer.inputs }
            if (layer.hasOwnProperty('checkedValue')) {
                settings.checkedValue = layer.checkedValue
            }
            await this.refLayers.current.upsertLayer(layer.name, layer.layerType, settings);
        }

        let counterLoadedSavedInfo = { type: 'loaded', name: counterName, date: counterDate }
        this.setState({ counterLoadedSavedInfo, saveCounterName: counterName })
        this.saveLoadCounterMode('none')
        if (layersOrder) {
            setTimeout(() => this.setLayersOrder(layersOrder), 1000)
        }
    }

    setLayersOrder = async (layersOrder) => {
        this.logMessage('setting layers order')
        let layers = this.state.layers
        let preservedLayers = JSON.parse(JSON.stringify(layers))
        let keys = await IndexedDb.getAllKeys('layer')
        if (keys && Array.isArray(keys) && keys.length > 0) {
            for (let i = 0; i < keys.length; i++) {
                let key = keys[i]
                await IndexedDb.deleteKey('layer', key)
            }
        }

        let orderedLayers = []
        layersOrder.forEach(layerOrder => {
            let layer = layers.find(layer => layer.name === layerOrder.name && layer.layerType === layerOrder.layerType)
            if (layer) {
                orderedLayers.push(layer)
                preservedLayers = preservedLayers.filter(pLayer => pLayer.name !== layer.name &&
                    pLayer.layerType !== layer.layerType)
            }
        })


        for (let i = 0; i < orderedLayers.length; i++) {
            let layer = orderedLayers[i]
            if (layer) {
                let newKey = await IndexedDb.put('layer', layer);
                layer.key = newKey
                await IndexedDb.put('layer', layer, newKey)
            }
        }

        for (var i in preservedLayers) {
            let pLayer = preservedLayers[i]
            if (pLayer) {
                let newKey = await IndexedDb.put('layer', pLayer);
                pLayer.key = newKey
                await IndexedDb.put('layer', pLayer, newKey)
            }
        }
        let layersFromDb = await IndexedDb.getAllData('layer')
        this.setState({ layers: layersFromDb })
    }

    selectChangeCounter = (evt) => {
        this.loadCounterFromDb(evt.target.value);

    }

    clearCounter = () => {
        this.logMessage('clearing counter')
        let layers = this.state.layers;
        for (var i = 0; i < layers.length; i++) {
            if (layers[i].layerType === 'counterBackgroundColor') {
                layers[i].inputs[0].value = '#FFFFFF'
                layers[i].inputs[1].value = false
                layers[i].inputs[2].value = 0
            }
            if (layers[i].layerType === 'unitSymbol') {
                layers[i].checkedValue = 'none'
                let color = layers[i].inputs.find(input => input.name === 'color')
                color.value = '#000000'
                let symbolWidth = layers[i].inputs.find(input => input.name === 'symbolWidth')
                symbolWidth.value = '100'
                let symbolHeight = layers[i].inputs.find(input => input.name === 'symbolHeight')
                symbolHeight.value = '75'
            }
            if (layers[i].layerType === 'symbol') {
                layers[i].checkedValue = 'none'
            }
            if (layers[i].layerType === 'vehicle') {
                layers[i].checkedValue = 'none'
            }
            if (layers[i].layerType === 'aircraft') {
                layers[i].checkedValue = 'none'
            }
            if (layers[i].layerType === 'images') {
                layers[i].checkedValue = 'none'
            }
            if (layers[i].layerType === 'unitSize') {
                layers[i].checkedValue = 'none'
            }
            if (layers[i].layerType === 'text') {
                layers[i].inputs[0].value = ''
            }
            if (layers[i].layerType === 'circle') {
                let radiusInput = layers[i].inputs.find(input => input.name === 'radius')
                radiusInput.value = ''
            }
            if (layers[i].layerType === 'ellipse') {
                let horizontalRadiusInput = layers[i].inputs.find(input => input.name === 'horizontalRadius')
                horizontalRadiusInput.value = ''
                let verticalRadiusInput = layers[i].inputs.find(input => input.name === 'verticalRadius')
                verticalRadiusInput.value = ''
            }
            if (layers[i].layerType === 'rectangle') {
                let widthInput = layers[i].inputs.find(input => input.name === 'width')
                widthInput.value = '0'
                let heightInput = layers[i].inputs.find(input => input.name === 'height')
                heightInput.value = '0'
            }
            if (layers[i].layerType === 'scaleneTriangle') {
                let radiusInput = layers[i].inputs.find(input => input.name === 'radius')
                radiusInput.value = ''
            }
            if (layers[i].layerType === 'isoTriangle') {
                let radiusInput = layers[i].inputs.find(input => input.name === 'length')
                radiusInput.value = 0
            }
            if (layers[i].layerType === 'line') {
                layers[i].inputs.find(input => input.name === 'startX').value = ''
                layers[i].inputs.find(input => input.name === 'startY').value = ''
                layers[i].inputs.find(input => input.name === 'endX').value = ''
                layers[i].inputs.find(input => input.name === 'endY').value = ''
            }
        }
        this.saveLayersToDb()
        let counterLoadedSavedInfo = { type: 'none', name: '', date: '' }
        this.setState({ counterLoadedSavedInfo })
    }

    selectChangeSheet = async (evt) => {
        this.logMessage('loading sheet')
        let sheet = await this.pageControl.current.loadSheet(evt.target.value)
        if (sheet && Utility.isObject(sheet) && sheet.hasOwnProperty('date')) {
            let cdate = new Date(sheet.date)
            let dateString = cdate.toLocaleDateString([], { hour: '2-digit', minute: '2-digit' })
            let sheetLoadedSavedInfo = { type: 'loaded', name: sheet.name, date: dateString }
            this.setState({ loadSheetMode: false, sheetLoadedSavedInfo })
        }
    }

    loadSheetClick = () => {
        this.pageControl.current.setCounterMouseActivity()
        if (this.state.loadFromFileError === true) {
            this.setState({ loadFromFileError: false })
        }
        if (this.state.savedSheetsAvailable.length === 0) {
            return
        }

        this.setState({ loadSheetMode: true }, () => {
            setTimeout(function () {
                let ele = document.getElementById('loadSheetSelect');
                ele.selectedIndex = "0";
            });
        });
    }

    cancelLoadSheet = () => {
        this.setState({ loadSheetMode: false });
    }

    clearSheet = () => {
        this.pageControl.current.clearSheet()
        let sheetLoadedSavedInfo = { type: 'none', name: '', date: '' }
        this.setState({ sheetLoadedSavedInfo, loadFromFileError: false })
    }

    layerReorder = async (fromIndex, toIndex) => {
        this.logMessage('reordering layers')
        let layers = this.state.layers
        if (layers && Array.isArray(layers) && layers.length >= toIndex) {
            if (fromIndex > -1 && fromIndex <= layers.length &&
                toIndex > -1 && toIndex <= layers.length) {
                layers = Utility.arrayMoveItem(layers, fromIndex, toIndex);
            }
        }

        let keys = await IndexedDb.getAllKeys('layer')
        if (keys && Array.isArray(keys) && keys.length > 0) {
            for (let i = 0; i < keys.length; i++) {
                let key = keys[i]
                await IndexedDb.deleteKey('layer', key)
            }
        }

        let savedCounterBackgroundColorLayer = null
        // make sure background color stays on bottom. Somehow it was being moved.
        let counterBackgroundColorIndex = 0;
        for (let i = 0; i < layers.length; i++) {
            if (layers[i].name === 'Counter background color') {
                counterBackgroundColorIndex = i
                savedCounterBackgroundColorLayer = layers[i]
                break;
            }
        }

        if (savedCounterBackgroundColorLayer) {
            layers.splice(counterBackgroundColorIndex, 1);
            layers.unshift(savedCounterBackgroundColorLayer)
        }

        let avoidDupes = []
        for (let i = 0; i < layers.length; i++) {
            let nameLowerCase = layers[i].name.toLowerCase()
            if (nameLowerCase === '') {
                continue
            }
            if (avoidDupes.indexOf(nameLowerCase) > -1) {
                continue
            }
            avoidDupes.push(nameLowerCase)
            let layer = layers[i]
            if (layer) {
                let newKey = await IndexedDb.put('layer', layer);
                layer.key = newKey
                await IndexedDb.put('layer', layer, newKey)
            }
        }
        this.setState({ layers })
    }

    getLayersOrder = () => {
        return this.state.layers.map((layer) => {
            return { name: layer.name, layerType: layer.layerType };
        });
    }

    saveLayersToDb = async () => {
        this.logMessage('saving layers to database')
        let layers = this.state.layers
        let keys = await IndexedDb.getAllKeys('layer')
        if (keys && Array.isArray(keys) && keys.length > 0) {
            for (let i = 0; i < keys.length; i++) {
                let key = keys[i]
                await IndexedDb.deleteKey('layer', key)
            }
        }
        for (let i = 0; i < layers.length; i++) {
            let layer = layers[i]
            let newKey = await IndexedDb.put('layer', layer);
            layer.key = newKey
            await IndexedDb.put('layer', layer, newKey)
        }
        this.setState({ layers })
    }

    setPendingAddLayerType = (layerType) => {
        if (this.state.pendingAddLayerType === false) {
            this.setState({ pendingAddLayerType: layerType }, () => {
                this.focusAddLayerInput()
            })
        }
        else {
            if (this.state.pendingAddLayerType === layerType) {
                this.setState({ pendingAddLayerType: false })
            }
            else {
                this.setState({ pendingAddLayerType: layerType }, () => {
                    this.focusAddLayerInput()
                })
            }
        }
    }

    addLayer = async () => {
        let newLayerName = this.state.newLayerName
        this.logMessage('adding new layer: ' + newLayerName)
        newLayerName = newLayerName.trim()
        if (newLayerName === '') {
            return
        }
        let layerNames = await IndexedDb.getAllNames('layer')
        let arr = layerNames.filter(layerName => layerName.toLowerCase() === newLayerName.toLowerCase())
        if (arr.length > 0) {
            this.setState({ newLayerNameAlreadyExists: true })
        }
        else {
            if (this.state.newLayerNameAlreadyExists === true) {
                this.setState({ newLayerNameAlreadyExists: false })
            }
            this.refLayers.current.addLayer(newLayerName, this.state.pendingAddLayerType);
            this.setState({ pendingAddLayerType: false, newLayerName: '' });
        }
    }

    addLayerCancel = () => {
        this.setState({ pendingAddLayerType: false, newLayerName: '' });
    }

    onKeyUpNewLayerName = (evt) => {
        if (evt.keyCode === 13 && this.state.newLayerName.length > 0) {
            this.addLayer()
        }
    }

    onKeyUpSaveCounter = (evt) => {
        if (evt.keyCode === 13) {
            this.saveCounter()
        }
    }

    onKeyUpSaveSheet = (evt) => {
        if (evt.keyCode === 13) {
            this.saveSheet()
        }
    }


    dbLayerUpdated = () => {
        IndexedDb.getAllData('layer').then(layers => {
            this.setState({ layers }, () => {
                this.storageUsed()
                let slotsInfo = this.pageControl.current.slotsInfo()
                if (JSON.stringify(this.state.slotsInfo) !== JSON.stringify(slotsInfo)) {
                    this.setState({ slotsInfo })
                }
            })
        });
    }

    dbSheetUpdated = () => {
        if (this.pageControl.current) {
            let slots = this.pageControl.current.getOccupiedSlots()
            if (slots.length > 0) {
                this.setState({ sheetHasSomething: true })
            }
            else {
                this.setState({ sheetHasSomething: true })
            }
            this.storageUsed()
        }
    }

    layerVisibleToggle = (layerKey) => {
        this.logMessage('toggling layer visibility')
        let layers = this.state.layers
        let layer = layers.find(layer => layer.key === layerKey)
        layer.visible = layer.visible === 1 ? 0 : 1
        this.setState({ layers })
    }

    deleteLayerByName = async (layerName) => {
        let layerKey = await IndexedDb.getKeyForName('layer', layerName)
        IndexedDb.deleteKey('layer', layerKey).then(() => this.dbLayerUpdated())
    }

    reset = async () => {
        this.refLayers.current.resetLayers()
        let counterLoadedSavedInfo = { type: 'none', name: '', date: '' }
        this.setState({ counterLoadedSavedInfo })
    }

    slotsInfo = () => {
        let si = this.pageControl.current.slotsInfo()
        console.log('si: ', si)
    }

    handleCounterFileChosen = (file) => {
        let fileReader = null
        try {
            fileReader = new FileReader()
        }
        catch (e) {
            this.logMessage('no FileReader available for this browser')
        }
        const handleFileRead = (e) => {
            let content = null
            try {
                content = fileReader.result
            }
            catch (e) {
                if (Utility.isObject(e)) {
                    this.logMessage('failed to get result from FileReader ' + JSON.stringify(e))
                }
                else {
                    this.logMessage('failed to get result from FileReader ' + e)
                }
            }
            if (content) {
                let counterPackage = Utility.parseJSON(content)
                if (counterPackage) {
                    if (counterPackage.hasOwnProperty('counter') && counterPackage.counter.hasOwnProperty('layers')) {
                        this.loadCounterFromPackage(counterPackage)
                    }
                    else {
                        this.logMessage('invalid file for counter')
                    }
                }
            }
        }
        if (fileReader) {
            fileReader.onloadend = handleFileRead
            try {
                fileReader.readAsText(file)
            }
            catch (e) {
                this.logMessage('failed on reading file')
            }
        }
    }


    handleSheetFileChosen = (file) => {
        if (this.state.loadFromFileError === true) {
            this.setState({ loadFromFileError: false })
        }
        JSZip.loadAsync(file).then((zip) => {
            Object.keys(zip.files).forEach((filename) => {
                zip.files[filename].async('string').then(async (fileData) => {
                    let sheet = null
                    try {
                        sheet = JSON.parse(fileData)
                    }
                    catch (e) {
                        this.logMessage('error loading file')
                        this.setState({ loadFromFileError: true })
                        return
                    }
                    if (sheet.hasOwnProperty('counterSize') &&
                        sheet.hasOwnProperty('slots') &&
                        Array.isArray(sheet.slots)) {
                        let sheetName = sheet.name
                        this.logMessage('loading sheet ' + sheet.name)
                        let key = await IndexedDb.getKeyForName('sheet', sheetName)
                        if (key === -1) {
                            key = await IndexedDb.put('sheet', sheet)
                        }
                        IndexedDb.put('sheet', sheet, key).then(() => {
                            this.pageControl.current.loadSheet(sheetName)
                            let cdate = new Date(sheet.date)
                            let dateString = cdate.toLocaleDateString([], { hour: '2-digit', minute: '2-digit' })
                            let sheetLoadedSavedInfo = { type: 'loaded', name: sheetName, date: dateString }
                            this.setState({ sheetLoadedSavedInfo, loadSheetMode: false, loadFromFileError: false })
                        })
                    }
                    else {
                        this.logMessage('file is not a valid sheet')
                    }
                })
            })
        })

    }

    render = () => {

        if (this.state.errorSettingUpDatabase) {
            return (
                <div className="database-error">
                    <p><span>ERROR!</span></p>
                    <p>I'm sorry, but it seems your browser will not permit the setting up of an internal database.</p>
                    <p>This may be due to low system memory, or the browser is limiting the amount of memory it can use.</p>
                    <p>If you know how to use the developer tools in your browser, you can check the IndexedDb storage and see if<br />
                        there is alot of data already being stored.</p>
                    <p>You can try the Brave or Firefox browser. I've only run into this problem on the Chromium browser in Linux.</p>
                    <p>Firefox needs to be allowed to "Remember History" - about:preferences#privacy</p>
                    <p>This app won't work on any of the IE browsers.</p>
                </div>
            )
        }

        return (

            <div className="snap-counter">
                <div className="design-wrapper">
                    <div className="title">Counter Designer</div>
                    <div className={this.state.printVersionActive || this.state.loadSheetMode || this.state.saveSheetMode ? 'design-container disabled' : 'design-container'}>
                        <div tabIndex="1" id="settingsDiv" className="settings">

                            <Layers
                                ref={this.refLayers}
                                layers={this.state.layers}
                                dbLayerUpdated={this.dbLayerUpdated}
                                onFocus={this.onFocus}
                                onChange={this.onChange}
                                eventsHistoryPush={this.eventsHistoryPush}
                                eventsHistoryGet={this.eventsHistoryGet}
                                deleteLayerByName={this.deleteLayerByName}
                                layerReorder={this.layerReorder}
                                logMessage={this.logMessage}
                            />

                            <div className="setting add-new-layer">
                                <div className="settings-flexwrap-row">
                                    <div><span>Add custom layer: </span></div>
                                    <div><button className={this.state.pendingAddLayerType === 'text' ? 'two-state-button active' : 'two-state-button'} onClick={() => this.setPendingAddLayerType('text')}>text</button></div>
                                    <div><button className={this.state.pendingAddLayerType === 'line' ? 'two-state-button active' : 'two-state-button'} onClick={() => this.setPendingAddLayerType('line')}>line</button></div>
                                    <div><button className={this.state.pendingAddLayerType === 'circle' ? 'two-state-button active' : 'two-state-button'} onClick={() => this.setPendingAddLayerType('circle')}>circle</button></div>
                                    <div><button className={this.state.pendingAddLayerType === 'ellipse' ? 'two-state-button active' : 'two-state-button'} onClick={() => this.setPendingAddLayerType('ellipse')}>ellipse</button></div>
                                    <div><button className={this.state.pendingAddLayerType === 'rectangle' ? 'two-state-button active' : 'two-state-button'} onClick={() => this.setPendingAddLayerType('rectangle')}>rectangle</button></div>
                                    <div><button className={this.state.pendingAddLayerType === 'scaleneTriangle' ? 'two-state-button active' : 'two-state-button'} onClick={() => this.setPendingAddLayerType('scaleneTriangle')}>scalene triangle</button></div>
                                    <div><button className={this.state.pendingAddLayerType === 'isoTriangle' ? 'two-state-button active' : 'two-state-button'} onClick={() => this.setPendingAddLayerType('isoTriangle')}>iso triangle</button></div>

                                </div>
                                <div className={this.state.pendingAddLayerType ? 'settings-flexwrap-row margin-top-1rem' : 'display-none'}>
                                    <div>
                                        <span>layer name: </span>
                                        <span> <input id="addLayerInput" className="input-text-12" type="text" name="newLayerName" value={this.state.newLayerName} onFocus={this.onFocus} onChange={this.onChange} onKeyUp={this.onKeyUpNewLayerName} autoComplete="off" autoCorrect="off" autoCapitalize="off" spellCheck="false" /></span>
                                    </div>
                                    <div>
                                        <button className={this.state.newLayerName.trim() ? 'momentary-button' : 'momentary-button inactive'} onClick={this.addLayer}>add {this.state.pendingAddLayerType} layer</button>
                                        <button className="momentary-button cancel" onClick={this.addLayerCancel}>cancel</button>
                                        <span className={this.state.newLayerNameAlreadyExists ? 'already-exists' : 'display-none'}>Name already exists</span>
                                    </div>
                                </div>
                            </div>


                        </div>

                        <div className="right-side">
                            <CounterLoadedSavedInfo counterLoadedSavedInfo={this.state.counterLoadedSavedInfo} />
                            <div className="svg-container">
                                <div className="display-svg">
                                    <CounterDesign
                                        ref={this.counterDesign}
                                        layers={this.state.layers}
                                        setIndicateKey={this.setIndicateKey}
                                        dbLayerUpdated={this.dbLayerUpdated}
                                        clearIndicate={this.state.clearIndicate}
                                        logMessage={this.logMessage}
                                    />
                                </div>
                            </div>
                            <div className={this.state.saveLoadCounterMode === 'none' ? 'design-controls' : 'display-none'}>
                                <div><button className={this.state.overlayOn ? 'two-state-button active' : 'two-state-button'} onClick={this.toggleOverlay}>overlay</button></div>
                                <div><button className={this.state.slotsInfo.numAvailable === 0 ? 'momentary-button inactive' : 'momentary-button'} onClick={this.clickOnMoveToSheet} disabled={this.state.slotsInfo.numAvailable === 0} >move to sheet</button></div>
                                <div><button className="momentary-button" onClick={this.saveCounterClick} >save</button></div>
                                <div><button className="momentary-button" onClick={() => this.saveLoadCounterMode('load')}>load</button></div>
                                <div><button className="momentary-button" onClick={this.clearCounter}>clear</button></div>
                                <div><button className="momentary-button" onClick={this.reset}>reset</button></div>
                            </div>

                            <div className={this.state.saveLoadCounterMode === 'load' ? 'design-controls load-counter' : 'display-none'}>

                                <div>
                                    <select className={this.state.counterNamesWide ? 'select-css counter-wide' : 'select-css counter'} name="loadCounterSelect" id="loadCounterSelect" onFocus={this.onFocus} onChange={this.selectChangeCounter}>
                                        <option value="">select counter</option>
                                        {this.state.savedCounterKeysAndNames.map(function (namePair, index) {
                                            return <option key={index} value={namePair.key}>{namePair.name}</option>
                                        })}
                                    </select>
                                </div>

                                <div className="load-from-file-div">
                                    <input type="file" id="file" accept="application/JSON" onChange={e => this.handleCounterFileChosen(e.target.files[0])} />
                                    <button className="momentary-button" onClick={() => this.saveLoadCounterMode('none')} >load from json file</button>
                                </div>
                                <div>
                                    <button className="momentary-button cancel sheet" onClick={() => this.saveLoadCounterMode('none')} >cancel</button>
                                </div>
                            </div>

                            <div className={this.state.saveLoadCounterMode === 'save' ? 'design-controls' : 'display-none'}>
                                <div><span className="save-as">save as</span>
                                    <input ref={this.refSaveCounterName} className="input-text-12" name="saveCounterName" value={this.state.saveCounterName} onFocus={this.onFocus} onChange={this.onChange} onKeyUp={this.onKeyUpSaveCounter} type="text" autoComplete="off" autoCorrect="off" autoCapitalize="off" spellCheck="false" /></div>
                                <div>
                                    <button className={this.state.saveCounterName ? 'momentary-button sheet' : 'momentary-button inactive sheet'} onClick={this.saveCounter} disabled={!this.state.saveCounterName}>save</button>
                                </div>
                                <div className="export-to-file">
                                    <input
                                        type="checkbox"
                                        name="exportCounterToFile"
                                        checked={this.state.exportCounterToFile}
                                        onChange={this.onChange}
                                    />
                                    Export to file
                                </div>
                                <button className="momentary-button cancel sheet" onClick={() => this.saveLoadCounterMode('none')} >cancel</button>

                            </div>

                            <div className="storage-space">
                                storage used: <span>{this.state.storageUsed}</span>
                            </div>

                            <TabManager activeTab={this.state.tabIndex} setActiveTab={this.setActiveTab} tabs={['Color Picker', 'Layers', 'Counters', 'Help', 'About', 'Log']}>
                                <div>
                                    <div className="color-picker-container">
                                        <canvas id="colorPickerCanvas" width="550" height="268" style={{ cursor: "crosshair" }} />
                                    </div>
                                    <div className="printing-note">
                                        <p>
                                            To use the Color Picker, first select the text input so that its border is red, then click on a color.
                                        </p>
                                        <p>
                                            You can also directly input color values in hex format (ie #FF0000).
                                        </p>

                                    </div>
                                </div>

                                <div>
                                    <LayerDragManager
                                        layers={this.state.layers}
                                        layerVisibleToggle={this.layerVisibleToggle}
                                        layerReorder={this.layerReorder}
                                        setIndicateKey={this.setIndicateKey}
                                    />
                                </div>

                                <div>
                                    <Counters
                                        savedCounters={this.state.savedCounters}
                                        loadCounterFromDb={this.loadCounterFromDb}
                                        deleteCounterFromDb={this.deleteCounterFromDb}
                                    />
                                </div>

                                <div className="help">
                                    <p>
                                        The app is split into two sections, the 'Counter Designer', and the page layout. The page layout will appear to the
                                        right of the Counter Designer, or if there isn't enough room - underneath it.
                                        </p>
                                    <p>
                                        The left side of the Counter Designer is a scrolling list of layer selections. Layer in this sense means
                                        individual components that can be drawn on a counter, such as "background color", "unit symbol", "circle", etc.
                                    </p>
                                    <p>
                                        Some layers are settable by clicking on one of the buttons, such as the "iron cross" in the layer titled "Symbol".
                                        Other settings available are position in the x y space, the size of the layer, and color, and possibly other
                                        options such has outline color and width, rotation, etc. Not all options may be relevant to a specific selection
                                        in a layer, so if you change something and you see no change in whats drawn, it probably isn't an option relevant
                                        to what you're drawing.
                                    </p>
                                    <p>
                                        The drawing surface for the counter is the big box in the top right side of the Counter Designer.
                                    </p>
                                    <p>
                                        The coordinate system is zero based in the middle, and extending for 100 "units" to each side. You can see
                                        the coordinate system by pressing the red "overlay" button.
                                    </p>
                                    <p>
                                        If you click on a layer you have drawn on the counter design area, you'll notice a reddish box appears around the element momentarily.
                                        This indicates that the nudge feature is on for that layer. You can then move that layer around using the arrow keys on your keyboard.
                                        Each time it'll move 1 "unit" in the specified direction. For more fine control, hold down the control key while hitting the arrow keys -
                                        this will move the layer by tenths of a unit.
                                    </p>
                                    <p>
                                        You can "overdraw" items (bleed) beyond the 100/-100 limits, but they may be clipped when you move the counters to the
                                        sheet (on the page layout), depending on how close you have the spacing set.
                                    </p>
                                    <p>
                                        When you are done designing a counter, you can get it to the sheet by pressing the "move to sheet" button.
                                        The counter will be placed in the first available slot on the sheet (going from left to right and top to bottom).
                                        You can drag and reposition counters if you want more control.
                                    </p>
                                    <p>
                                        Since storing data in a browser is not the safest place to keep stuff, there is a "Export to file" option for counters and sheets in the save settings.
                                        Locations your browser may put them in is your home directory,
                                        or your Downloads directory.
                                        You can reload saved files by clicking "load" then "load from file".
                                    </p>
                                    <p>
                                        The "clear" button simply wipes the slate of the current counter, and gives you a blank white starting point.
                                        Remember to save any counter before you do that though! Currently there is no "undo" feature. So - deleting
                                        layers on the drawing surface means - deleted.
                                        </p>
                                    <p>
                                        The "reset" button restores the default layers to the layers section (the left side). This may also remove
                                        anything you had put on the counter design, so only do this if you've saved any work you have.
                                    </p>
                                    <p>
                                        Fonts are a bit tricky in svg. The same font at the same size may look a bit different and be positioned differently in Chrome
                                        than it does in Firefox.
                                        The fonts that are available now in the app are from Google Fonts (https://fonts.google.com). If you'd like
                                        to have a font added, let me know the name.
                                        </p>
                                </div>

                                <div className="about">
                                    <p>
                                        This web app uses SVG (Scalable Vector Graphics) to draw counters. The library used is Snap.svg
                                        (http://snapsvg.io).
                                    </p>
                                    <p>
                                        This web app should work on Chrome/Brave and Firefox desktop browsers. It will not work correctly on Microsoft Edge browser,
                                        nor with a mobile device. Firefox needs to be allowed to remember history (for some unfathomable reason) in order for the app to
                                        use the internal database - you can change the settings by going to this url: about:preferences#privacy
                                    </p>
                                    <p>
                                        All data you save in this app is stored in your browser's local database. There is no data being sent out to any server.
                                        Your browser may delete your data if it needs to make space.
                                    </p>
                                    <p>
                                        You can contact me for any questions or issues: cisco_s@yahoo.com
                                    </p>
                                </div>

                                <Log message={this.state.logMessage} />

                            </TabManager>

                        </div>
                    </div>
                </div>

                <div className="page-layout">
                    <div>
                        <div>
                            <div className="page-controls">


                                <div className="page-settings">
                                    <div className="page-settings-row">
                                        <div>counter size</div>
                                        <label className="label-wrapped-radio"><input type="radio" id="12.7mm" name="counterSize" value="12.7mm" checked={this.state.counterSize === '12.7mm'} onChange={this.onChange} />12.7mm<span>(1/2 inch)</span></label>
                                        <label className="label-wrapped-radio"><input type="radio" id="15.875mm" name="counterSize" value="15.875mm" checked={this.state.counterSize === '15.875mm'} onChange={this.onChange} />15.875mm<span>(5/8 inch)</span></label>
                                        <label className="label-wrapped-radio"><input type="radio" id="19.05mm" name="counterSize" value="19.05mm" checked={this.state.counterSize === '19.05mm'} onChange={this.onChange} />19.05mm<span>(3/4 inch)</span></label>
                                        <label className="label-wrapped-radio"><input type="radio" id="22.225mm" name="counterSize" value="22.225mm" checked={this.state.counterSize === '22.225mm'} onChange={this.onChange} />22.225mm<span>(7/8 inch)</span></label>
                                        <label className="label-wrapped-radio"><input type="radio" id="25.4mm" name="counterSize" value="25.4mm" checked={this.state.counterSize === '25.4mm'} onChange={this.onChange} />25.4mm<span>(1 inch)</span></label>
                                        <label className="label-wrapped-radio"><input type="radio" id="37.5mm" name="counterSize" value="37.5mm" checked={this.state.counterSize === '37.5mm'} onChange={this.onChange} />37.5mm<span>(1.5 inch)</span></label>
                                        <label className="label-wrapped-radio"><input type="radio" id="50mm" name="counterSize" value="50mm" checked={this.state.counterSize === '50mm'} onChange={this.onChange} />50mm<span>(2 inch)</span></label>
                                    </div>
                                    <div className="page-settings-row">

                                        <div className="marginp"><span>Margins</span>
                                            left:<input className="input-text-2" name="marginLeft" value={this.state.marginLeft} onFocus={this.onFocus} onChange={this.onChange} type="text" autoComplete="off" autoCorrect="off" autoCapitalize="off" spellCheck="false" />
                                            <span className="percent-symbol">% </span>
                                            right:<input className="input-text-2" name="marginRight" value={this.state.marginRight} onFocus={this.onFocus} onChange={this.onChange} type="text" autoComplete="off" autoCorrect="off" autoCapitalize="off" spellCheck="false" />
                                            <span className="percent-symbol">% </span>
                                            top:<input className="input-text-2" name="marginTop" value={this.state.marginTop} onFocus={this.onFocus} onChange={this.onChange} type="text" autoComplete="off" autoCorrect="off" autoCapitalize="off" spellCheck="false" />
                                            <span className="percent-symbol">% </span>
                                            bottom:<input className="input-text-2" name="marginBottom" value={this.state.marginBottom} onFocus={this.onFocus} onChange={this.onChange} type="text" autoComplete="off" autoCorrect="off" autoCapitalize="off" spellCheck="false" />
                                            <span className="percent-symbol">% </span>
                                        </div>

                                        <div className="marginp"><span>Spacing</span>
                                            x:<input className="input-text-2" name="spacingX" value={this.state.spacingX} onFocus={this.onFocus} onChange={this.onChange} type="text" autoComplete="off" autoCorrect="off" autoCapitalize="off" spellCheck="false" />
                                            <span className="percent-symbol">% </span>
                                            y:<input className="input-text-2" name="spacingY" value={this.state.spacingY} onFocus={this.onFocus} onChange={this.onChange} type="text" autoComplete="off" autoCorrect="off" autoCapitalize="off" spellCheck="false" />
                                            <span className="percent-symbol">% </span>

                                        </div>

                                    </div>
                                </div>

                                <div className={this.state.saveSheetMode || this.state.loadSheetMode || this.state.counterOptionsMode ? 'display-none' : 'page-actions'}>
                                    <div>
                                        <button className={this.state.sheetHasSomething ? 'momentary-button sheet' : 'display-none'} onClick={this.print} >print</button>
                                    </div>
                                    <div>
                                        <button className={this.state.slotsInfo.numOccupiedSlots > 0 ? 'momentary-button sheet' : 'momentary-button inactive sheet'} onClick={() => this.saveSheetMode(true)} disabled={this.state.slotsInfo.numOccupiedSlots === 0}>save</button>
                                    </div>
                                    <div>
                                        <button className="momentary-button sheet" disabled={!this.state.savedSheetsAvailable.length > 0} onClick={this.loadSheetClick}>load</button>
                                    </div>
                                    <div>
                                        <button className="momentary-button sheet" onClick={this.clearSheet}>clear</button>
                                    </div>
                                    <div className={this.state.sheetLoadedSavedInfo.type === 'loaded' ? 'loaded-sheet-from-file' : 'display-none'}>
                                        Loaded sheet <span>{this.state.sheetLoadedSavedInfo.name}</span><span>{this.state.sheetLoadedSavedInfo.date}</span>
                                    </div>
                                </div>



                                <div className={this.state.counterOptionsMode ? 'page-actions' : 'display-none'}>
                                    <div>
                                        <button className="momentary-button" onClick={this.editSelectedCounter} >edit</button>
                                    </div>
                                    <div>
                                        <button className={(this.state.pageControlCounterMouseActivity.slotId !== '' && this.state.pageControlCounterMouseActivity.clicked) ? 'momentary-button sheet' : 'momentary-button inactive sheet'}
                                            disabled={!this.state.pageControlCounterMouseActivity.clicked}
                                            onClick={this.deleteCounter}>delete</button>
                                    </div>
                                    <div>
                                        <button className="momentary-button cancel sheet" onClick={() => this.cancelCounterMouseActivity()} >cancel</button>
                                    </div>
                                </div>


                                <div className={this.state.saveSheetMode ? 'page-actions' : 'display-none'}>
                                    <div>
                                        save as
                                    </div>
                                    <div>
                                        <input ref={this.refSaveSheetAs} className="input-text-12" name="saveSheetName" value={this.state.saveSheetName} onFocus={this.onFocus} onChange={this.onChange} onKeyUp={this.onKeyUpSaveSheet} type="text" autoComplete="off" autoCorrect="off" autoCapitalize="off" spellCheck="false" />
                                    </div>
                                    <div>
                                        <button className={this.state.saveSheetName ? 'momentary-button sheet' : 'momentary-button inactive sheet'} onClick={this.saveSheet} disabled={!this.state.saveSheetName}>save</button>
                                    </div>
                                    <div className="export-to-file">
                                        <input
                                            type="checkbox"
                                            name="exportSheetToFile"
                                            checked={this.state.exportSheetToFile}
                                            onChange={this.onChange}
                                        />
                                        Export to file
                                    </div>
                                    <div>
                                        <button className="momentary-button cancel sheet" onClick={() => this.saveSheetMode(false)} >cancel</button>
                                    </div>
                                </div>

                                <div className={this.state.loadSheetMode ? 'page-actions' : 'display-none'}>
                                    <div>
                                        <select className="select-css sheets" name="loadSheetSelect" id="loadSheetSelect" onFocus={this.onFocus} onChange={this.selectChangeSheet}>
                                            <option value="">select sheet</option>
                                            {this.state.savedSheetsAvailable.map(function (name, index) {
                                                return <option key={index} value={name}>{name}</option>
                                            })}
                                        </select>
                                    </div>
                                    <div className="load-from-file-div">
                                        <input type="file" id="file" accept=".zip" onChange={e => this.handleSheetFileChosen(e.target.files[0])} />
                                        <button className="momentary-button"  >load from file</button>
                                    </div>
                                    <div className={this.state.loadFromFileError ? 'load-from-file-error' : 'display-none'}>
                                        Could not process file
                                    </div>
                                    <div>
                                        <button className="momentary-button cancel sheet" onClick={this.cancelLoadSheet} >cancel</button>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>


                    <PageControl ref={this.pageControl}
                        layers={this.state.layers}
                        dbSheetUpdated={this.dbSheetUpdated}
                        counterMouseActivity={this.pageControlCounterMouseActivity}
                        counterSize={this.state.counterSize}
                        marginLeft={this.state.marginLeft}
                        marginRight={this.state.marginRight}
                        marginTop={this.state.marginTop}
                        marginBottom={this.state.marginBottom}
                        spacingX={this.state.spacingX}
                        spacingY={this.state.spacingY}
                        placedOnSheet={this.placedOnSheet}
                        pageCounterClicked={this.pageCounterClicked}
                        printVersionActive={this.printVersionActive}
                        printVersionDeactive={this.printVersionDeactive}
                        sheetHasSomething={this.sheetHasSomething}
                        loadSheetObj={this.state.loadSheetObj}
                        sheetWasSaved={this.sheetWasSaved}
                        updatePageParams={this.updatePageParams}
                        logMessage={this.logMessage}
                    />
                </div>



            </div >
        )
    }
}
