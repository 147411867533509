import { createStore } from "redux";
import reducer from "../reducers";

const initialState = {
    hexMapSetup: {
        mapMargin: 0,
        hexDiameter: 0,
        hexesAcross: 0,
        hexesDown: 0,
        joggle: 0,
        hexNumberGrain: 0,
        hexNumberFontColor: '#000000',
        hexNumberFontSize: 0,
        hexNumberOrientation: 'angle0',
        hexNumberPosition: 10,
        hexCenterDotSize: 0,
        mapMarginColor: '#dddddd',
        mapHexColor: '#ffffff',
        hexLineColor: '#000000',
        hexLineWidth: 1,
        mapMarginOpacity: 100,
        mapHexOpacity: 100,
        hexNumberFontOpacity: 100,
        hexLineOpacity: 100,
    },
    hexMap: null
};
export const store = createStore(reducer, initialState);