export default (state, action) => {

    switch (action.type) {

        case "SET_HEXMAP_SETUP":
        return {
            ...state,
            hexMapSetup: action.hexMapSetup
        };

        case "SET_HEXMAP":
            return {
                ...state,
                hexMap: action.hexMap
            };

        default:
            return state;
    }
};