import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import './Index.scss';

export default class Index extends Component {
    componentDidMount = () => {

    }
    render = () => {
        return <div className="index">
            <div className="menu">
                <Link to="setup">SnapMap</Link>
                <Link to="snap_counter">SnapCounter</Link>
                
            </div>
        </div>
    }
}


// import React, { Component } from 'react';



// export default class Index extends Component {
//     componentDidMount = () => {
//         this.props.history.push("/setup");
//     }
//     render = () => {
//         return <div/>
//     }
// }