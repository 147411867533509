import React, { Component } from 'react';
import { Redirect } from 'react-router-dom'
import { connect } from 'react-redux';
import { setHexMapSetup } from "../../Redux/actions";
import Snap from 'snapsvg-cjs';
import Utility from '../../Utility';
import './HexMap.scss';

class HexMap extends Component {

    state = {
        s: null,
        invalidSetup: false,
        trigger: false,
        hexMapSetup: null
    }

    componentDidMount = () => {
        var elem = document.getElementById("thesvg");
        if (elem) {
            elem.remove();
        }
        setTimeout(function () {
            this.setState({ trigger: true });
        }.bind(this), 100);
    }

    componentDidUpdate = () => {

        // don't do nothing until we get the trigger (and we haven't drawn yet)
        if (this.state.hexMapSetup !== null && this.state.s === null) {
            this.drawPaper();
            return;
        }

        if (this.state.trigger === true && this.state.s === null) {
            if (this.props.hexMapSetup.mapMargin > 0) {
                // we have updated props, we are good to go
                localStorage.setItem('current', JSON.stringify(this.props.hexMapSetup));
                this.setState({ hexMapSetup: this.props.hexMapSetup }); // this will trigger another componentDidUpdate call
                return;
            }
            else {
                // recover from localStorage 
                let currentSetup = localStorage.getItem('current');
                if (!currentSetup) {
                    this.setState({ invalidSetup: true });
                }
                else {
                    this.setState({ hexMapSetup: JSON.parse(currentSetup) });
                }
                return;
            }
        }
    }

    toSvgFile = (canvasWidth, canvasHeight) => {
        let svgString = document.getElementById('thesvg').innerHTML;
        svgString = '<svg id="snapmap" version="1.1"  width="' + canvasWidth + '" height="' + canvasHeight + '" xmlns="http://www.w3.org/2000/svg">' + svgString + '</svg>';

        let a = document.createElement('a');
        a.setAttribute("style", "text-decoration: none;");
        a.download = 'snapmap.svg';
        a.type = 'image/svg+xml';
        let blob = new Blob([svgString], { "type": "image/svg+xml" });
        a.href = (window.URL).createObjectURL(blob);
        let svgEl = document.getElementById('thesvg');
        svgEl.parentNode.insertBefore(a, svgEl);
        a.appendChild(svgEl);
        // this.uploadFile(blob);
    }

    componentWillUnmount = () => {
        var elem = document.getElementById("thenode");
        if (elem) {
            elem.remove();
        }
    }

    uploadFile = (file) => {
        const upload = (file) => {
            fetch('api/catchFile', { // Your POST endpoint
                method: 'POST',
                headers: {
                    "Content-Type": "You will perhaps need to define a content-type here"
                },
                body: file // This is your file object
            }).then(
                response => response.json() // if the response is a JSON object
            ).then(
                success => console.log(success) // Handle the success response object
            ).catch(
                error => console.log(error) // Handle the error response object
            );
        };
        upload(file);
    }

    drawPaper = () => {

        let mapMargin = this.state.hexMapSetup.mapMargin;
        let hexDiameter = this.state.hexMapSetup.hexDiameter;
        let hexesAcross = this.state.hexMapSetup.hexesAcross;
        let hexesDown = this.state.hexMapSetup.hexesDown;
        let hexRadius = hexDiameter / 2;
        let distanceToSide = (hexRadius / 2) * Math.sqrt(3);
        let joggle = this.state.hexMapSetup.joggle;

        let canvasWidth = (hexesAcross * (
            hexRadius * 1.5
        )) + (hexRadius / 2) + (mapMargin * 2);



        let canvasHeight = ((2 * mapMargin) + ((distanceToSide * 2) * hexesDown) + (hexRadius)) - (hexRadius - distanceToSide);

        // weird settings
        if (hexesAcross === 1) {
            canvasHeight -= distanceToSide;
        }
        if (hexesAcross === 1 && joggle === 1) {
            canvasHeight += distanceToSide;
        }

        canvasWidth = Utility.roundFloat(canvasWidth, 0);
        canvasHeight = Utility.roundFloat(canvasHeight, 0);
        var s = Snap(canvasWidth, canvasHeight);
        s.node.id = 'thenode';
        s.attr({ id: "thesvg" });
        //s.rect(0, 0, canvasWidth, canvasHeight).attr({ stroke: '#aaa', fill: mapMarginColor, opacity: Utility.roundFloat(mapMarginOpacity / 100, 2) });
        this.setState({ s: s }, () => {
            this.drawHexes(canvasWidth, canvasHeight);
            setTimeout(function () {
                this.toSvgFile(canvasWidth, canvasHeight);
            }.bind(this), 200);
        });
    }

    drawHexes = (canvasWidth, canvasHeight) => {
        function roundFloat(n, f) {
            return Utility.roundFloat(n, f);
        }
        let mapMarginColor = this.state.hexMapSetup.mapMarginColor;
        let mapMarginOpacity = this.state.hexMapSetup.mapMarginOpacity;
        let hexesAcross = this.state.hexMapSetup.hexesAcross;
        let hexesDown = this.state.hexMapSetup.hexesDown;
        let hexRadius = this.state.hexMapSetup.hexDiameter / 2;
        let hexesRender = [];
        let distanceToSide = Utility.roundFloat(((hexRadius / 2) * Math.sqrt(3)), 3);
        let mapMargin = this.state.hexMapSetup.mapMargin;
        let hexNumberPosition = this.state.hexMapSetup.hexNumberPosition;
        let hexCenterDotSize = this.state.hexMapSetup.hexCenterDotSize;
        let hexNumberFontColor = this.state.hexMapSetup.hexNumberFontColor;
        let hexNumberFontSize = this.state.hexMapSetup.hexNumberFontSize;
        let hexNumberOrientation = this.state.hexMapSetup.hexNumberOrientation;
        hexNumberOrientation = hexNumberOrientation.replace('angle', '');
        let joggle = this.state.hexMapSetup.joggle;
        let hexNumberGrain = this.state.hexMapSetup.hexNumberGrain;
        let hexLineColor = this.state.hexMapSetup.hexLineColor;
        let mapHexColor = this.state.hexMapSetup.mapHexColor;
        let hexLineWidth = Utility.roundFloat(this.state.hexMapSetup.hexLineWidth, 2);
        let mapHexOpacity = this.state.hexMapSetup.mapHexOpacity;
        let hexNumberFontOpacity = this.state.hexMapSetup.hexNumberFontOpacity;
        let hexLineOpacity = this.state.hexMapSetup.hexLineOpacity;

        let x = 0;
        let y = 0;
        let hex = { center: { x: 0, y: 0 }, circle: '', points: [], lines: [] };
        let hexNumberAcross = 900;
        let hexNumberDown = 0;
        let topRightPoints = [];
        let leftBottomPoints = [];
        let actualTopRightPoints = [];
        let actualLeftBottomPoints = [];
        for (y = 0; y < hexesDown; y++) {
            hexNumberDown++;
            hexNumberAcross = 900;
            for (x = 0; x < hexesAcross; x++) {
                
                if ((x + 0) % 2 === joggle) {
                    hex.center.x = (x * (hexRadius * 1.5)) + hexRadius;
                    hex.center.y = hexRadius;
                    hex.center.y += (y * distanceToSide * 2);
                }
                else {
                    hex.center.x = (x * (hexRadius * 1.5)) + hexRadius;
                    hex.center.y = hexRadius + distanceToSide;
                    hex.center.y += (y * distanceToSide * 2);
                }
                hex.center.y = roundFloat(hex.center.y, 2);
                hex.center.x += mapMargin;
                hex.center.y += mapMargin;
                hex.center.y -= hexRadius - distanceToSide; // the top down margin is a bit less because we are not going to hex side - we are going to hex circle which is a bit negative y.

                //hex.number = <Text x={hex.center.x}  y={hex.center.y} attr={{ "fontSize": 12, "stroke": "#f50000", "stroke-width": 1 }}>Text</Text>


                let point1 = { x: 0, y: 0 };
                point1.x = (hexRadius * -0.5) + hex.center.x; // Math.sin(dr(330))
                point1.y = (hexRadius * 0.86602540378) + hex.center.y; // Math.cos(dr(330))
                point1.x = roundFloat(point1.x, 2);
                point1.y = roundFloat(point1.y, 2);
                hex.points.push(point1);
                let point2 = { x: 0, y: 0 };
                point2.x = (hexRadius * 0.5) + hex.center.x; // Math.sin(dr(30))
                point2.y = (hexRadius * 0.86602540378) + hex.center.y; // Math.cos(dr(30))
                point2.x = roundFloat(point2.x, 2);
                point2.y = roundFloat(point2.y, 2);
                hex.points.push(point2);
                let point3 = { x: 0, y: 0 };
                point3.x = (hexRadius * 1) + hex.center.x; // Math.sin(dr(90))
                point3.y = (hexRadius * 0) + hex.center.y; // 
                point3.x = roundFloat(point3.x, 2);
                point3.y = roundFloat(point3.y, 2);
                hex.points.push(point3);
                let point4 = { x: 0, y: 0 };
                point4.x = (hexRadius * 0.5) + hex.center.x; // Math.sin(dr(150))
                point4.y = (hexRadius * -0.86602540378) + hex.center.y; // Math.cos(dr(150))
                point4.x = roundFloat(point4.x, 2);
                point4.y = roundFloat(point4.y, 2);
                hex.points.push(point4);
                let point5 = { x: 0, y: 0 };
                point5.x = (hexRadius * -0.5) + hex.center.x; // Math.sin(dr(210))
                point5.y = (hexRadius * -0.86602540378) + hex.center.y; // Math.cos(dr(210))
                point5.x = roundFloat(point5.x, 2);
                point5.y = roundFloat(point5.y, 2);
                hex.points.push(point5);
                let point6 = { x: 0, y: 0 };
                point6.x = (hexRadius * -1) + hex.center.x; // Math.sin(dr(270))
                point6.y = (hexRadius * 0) + hex.center.y; // Math.cos(dr(270))
                point6.x = roundFloat(point6.x, 2);
                point6.y = roundFloat(point6.y, 2);
                hex.points.push(point6);

                hex.lines = [];
                hex.lines.push({ x1: point1.x, y1: point1.y, x2: point2.x, y2: point2.y });
                hex.lines.push({ x1: point2.x, y1: point2.y, x2: point3.x, y2: point3.y });
                hex.lines.push({ x1: point3.x, y1: point3.y, x2: point4.x, y2: point4.y });
                hex.lines.push({ x1: point4.x, y1: point4.y, x2: point5.x, y2: point5.y });
                hex.lines.push({ x1: point5.x, y1: point5.y, x2: point6.x, y2: point6.y });
                hex.lines.push({ x1: point6.x, y1: point6.y, x2: point1.x, y2: point1.y });

                this.state.s.polyline([point1.x + hexLineWidth / 4, point1.y - hexLineWidth / 2,
                point2.x - hexLineWidth / 4, point2.y - hexLineWidth / 2,
                point3.x - hexLineWidth / 2, point3.y,
                point4.x - hexLineWidth / 4, point4.y + hexLineWidth / 2,
                point5.x + hexLineWidth / 4, point5.y + hexLineWidth / 2,
                point6.x + hexLineWidth / 2, point6.y]).attr({ 'strokeWidth': hexLineWidth, stroke: "transparent", fill: mapHexColor, opacity: roundFloat(mapHexOpacity / 100, 2) });

                // this.state.s.polygon(point1.x, point1.y,
                //     point2.x, point2.y,
                //     point3.x, point3.y,
                //     point4.x, point4.y,
                //     point5.x, point5.y,
                //     point6.x, point6.y).attr({ stroke: hexLineColor, 'strokeWidth': hexLineWidth, stroke: hexLineColor, fill: "transparent", opacity: roundFloat(hexLineOpacity / 200, 2) });



                let useOpacity = roundFloat(parseInt(hexLineOpacity) / 150, 2);
                if (hexLineOpacity === "100") {
                    useOpacity = 1;
                }
                hex.lines.forEach(function (line) {
                    this.state.s.line(line.x1, line.y1, line.x2, line.y2).attr({ "strokeWidth": hexLineWidth, stroke: hexLineColor, opacity: useOpacity });
                }.bind(this));

                if (x === 0) {
                    actualLeftBottomPoints.push({ x: point5.x, y: point5.y });
                    actualLeftBottomPoints.push({ x: point6.x, y: point6.y });
                    if (y === 0) {
                        leftBottomPoints.push({ x: point5.x, y: point5.y - hexLineWidth / 2 });
                    }
                    else {
                        leftBottomPoints.push({ x: point5.x - hexLineWidth / 2, y: point5.y });
                    }
                    leftBottomPoints.push({ x: point6.x - hexLineWidth / 2, y: point6.y });
                    // this.state.s.circle(point5.x, point5.y, hexCenterDotSize);
                    // this.state.s.circle(point6.x, point6.y, hexCenterDotSize);
                }
                if (y === 0) {
                    actualTopRightPoints.push({ x: point5.x, y: point5.y });
                    actualTopRightPoints.push({ x: point4.x, y: point4.y });
                    topRightPoints.push({ x: point5.x, y: point5.y - hexLineWidth / 2 });
                    topRightPoints.push({ x: point4.x, y: point4.y - hexLineWidth / 2 });
                }
                if (x === hexesAcross - 1) {
                    actualTopRightPoints.push({ x: point4.x, y: point4.y });
                    actualTopRightPoints.push({ x: point3.x, y: point3.y });
                    topRightPoints.push({ x: point4.x + hexLineWidth / 2, y: point4.y });
                    topRightPoints.push({ x: point3.x + hexLineWidth / 2, y: point3.y });
                }
                if (y === hexesDown - 1) {
                    actualLeftBottomPoints.push({ x: point1.x, y: point1.y });
                    actualLeftBottomPoints.push({ x: point2.x, y: point2.y });
                    leftBottomPoints.push({ x: point1.x, y: point1.y + hexLineWidth / 2 });
                    leftBottomPoints.push({ x: point2.x, y: point2.y + hexLineWidth / 2 });
                }

                hexNumberAcross += 100;
                let hexNumberText = (hexNumberAcross + hexNumberDown).toString();
                if( hexNumberGrain === 1 ) {
                    // vertical, bottom left
                    hexNumberText = ((10 + (11 - y)) * 100) + (x + 1);
                }
                
                let yTop = hex.center.y - (
                    (distanceToSide
                        - (hexNumberFontSize * 0.65)));

                let yBottom = hex.center.y + (
                    (distanceToSide
                        - (hexNumberFontSize * 0)));

                let distanceTopToBottom = yBottom - yTop

                let yPos = yTop
                if (hexNumberPosition > 0) {
                    yPos = yTop + distanceTopToBottom * (hexNumberPosition / 100);
                }

                this.state.s.text(hex.center.x, yPos, hexNumberText)
                    .attr({ "text-decoration": "none", "fill": hexNumberFontColor, "textAnchor": "middle", "fontSize": hexNumberFontSize + "px", opacity: roundFloat(hexNumberFontOpacity / 100, 2) })
                    .transform('r' + hexNumberOrientation + ',' + hex.center.x + ',' + hex.center.y);

                if (hexCenterDotSize > 0) {
                    this.state.s.circle(hex.center.x, hex.center.y, hexCenterDotSize);
                }

                hexesRender.push({ ...hex });
            }
        }

        let marginPath = [];
        marginPath.push(-1 * hexLineWidth, topRightPoints[0].y);
        topRightPoints.forEach(function (point) {
            marginPath.push(point.x);
            marginPath.push(point.y);
        });

        let lbPoint = {};
        while (leftBottomPoints.length > 0) {
            lbPoint = leftBottomPoints.pop();
            marginPath.push(lbPoint.x, lbPoint.y);
        }
        marginPath.push(-1 * hexLineWidth, lbPoint.y);
        marginPath.push(-1 * hexLineWidth, canvasHeight + hexLineWidth);
        marginPath.push(canvasWidth + hexLineWidth, canvasHeight + hexLineWidth);
        marginPath.push(canvasWidth + hexLineWidth, 0);
        marginPath.push(-1 * hexLineWidth, -1 * hexLineWidth);
        marginPath.push(-1 * hexLineWidth, lbPoint.y);
        marginPath.push(lbPoint.x, lbPoint.y);

        this.state.s.polyline(marginPath).attr({ fill: mapMarginColor, "strokeWidth": "0", opacity: roundFloat(mapMarginOpacity / 100, 2) });
        //this.state.s.polyline(marginPath).attr({fill: "none", "strokeWidth": hexLineWidth, stroke: hexLineColor, opacity: roundFloat(hexLineOpacity / 200, 2)});

        //this.state.s.line(-1 * hexLineWidth,lbPoint.y, lbPoint.x - hexLineWidth, lbPoint.y).attr({ "strokeWidth": hexLineWidth, stroke: "#ff0000", opacity: 1 });
        for (var b = 0; b < actualTopRightPoints.length - 1; b++) {
            let bx1 = actualTopRightPoints[b].x;
            let by1 = actualTopRightPoints[b].y;
            let bx2 = actualTopRightPoints[b + 1].x;
            let by2 = actualTopRightPoints[b + 1].y;
            this.state.s.line(bx1, by1, bx2, by2).attr({ "strokeWidth": hexLineWidth, stroke: hexLineColor, opacity: roundFloat(hexLineOpacity / 200, 2) });
        }
        for (b = 0; b < actualLeftBottomPoints.length - 1; b++) {
            let bx1 = actualLeftBottomPoints[b].x;
            let by1 = actualLeftBottomPoints[b].y;
            let bx2 = actualLeftBottomPoints[b + 1].x;
            let by2 = actualLeftBottomPoints[b + 1].y;
            this.state.s.line(bx1, by1, bx2, by2).attr({ "strokeWidth": hexLineWidth, stroke: hexLineColor, opacity: roundFloat(hexLineOpacity / 200, 2) });
        }

        let fx1 = actualTopRightPoints[actualTopRightPoints.length - 1].x;
        let fy1 = actualTopRightPoints[actualTopRightPoints.length - 1].y;

        let fx2 = actualLeftBottomPoints[actualLeftBottomPoints.length - 1].x;
        let fy2 = actualLeftBottomPoints[actualLeftBottomPoints.length - 1].y;

        this.state.s.line(fx1, fy1, fx2, fy2).attr({ "strokeWidth": hexLineWidth, stroke: hexLineColor, opacity: roundFloat(hexLineOpacity / 200, 2) });

    }

    render = () => {
        if (this.state.invalidSetup) {
            return <Redirect to={'/'} />
        }
        return (
            <div />
        );
    }
}

const mapStateToProps = (state /*, ownProps*/) => {
    return {
        hexMapSetup: state.hexMapSetup
    }
}

const mapDispatchToProps = { setHexMapSetup }

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(HexMap)